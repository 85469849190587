import { hasKeys, isArray, isObject, isString } from "@dayforward/common/src/utils";
import { defineStore } from "pinia";
/**
 * Stores user props not associated to the auth user such as the user, agency, interviews, or other future needs.
 * $ is used on state prop and removed for the corresponding getter. This ensures context and relationship.
 */
export const useEstimateStore = defineStore("estimate", {
  state: () => ({
    $estimate: {}, // Props should always have an initial value if possible so the the data type is clear.
    $lead: {},
  }),
  getters: {
    /**
     * The current user and any assioated user data
     * @returns Object
     */
    estimate: (state) => {
      return { ...state.$estimate };
    },
    lead: (state) => {
      return {
        ...state.$lead,
        get estimate() {
          return state.$estimate;
        },
      };
    },
    /**
     * Generic prop checker returning a boolean if current data on the property. This generic getter reduce code and the need to
     * add additional prop checks if the state object changes
     * @param {string} prop - state property
     * @returns {boolean}
     */
    hasProp: (state) => {
      return (prop) => {
        if (!state[prop]) return false;
        if (isArray(state[prop]) || isString(state[prop])) return state[prop].length > 0;
        if (isObject(state[prop])) return hasKeys(state[prop]);
      };
    },
  },
  actions: {
    /**
     * Generic actions to set state props. This reduces code and need to add additional setters for each new state prop.
     * @param {string} prop - the state property to be set (example: $user)
     * @param {(string|array|object)} data - the data that is assigned to the state property
     */
    setState(prop, data) {
      this[prop] = data;
    },
  },
});
