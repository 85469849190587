import { useReporter } from "@common/composables";
/**
 * Returns the response from the apollo query
 * @param {object} apollo - The apollo object
 * @returns
 */
export function useQuery(/** Objecgt */ apollo) {
  const reporter = useReporter();
  /**
   * Need to find a solution better solution. Currently useApollo can only be used inside setup or componend.
   */
  async function query(type, variables = {}) {
    try {
      const response = await apollo.query({
        query: type,
        fetchPolicy: "no-cache",
        variables,
      });
      return response;
    } catch (error) {
      reporter.error(error);
    }
  }

  return {
    query,
  };
}
