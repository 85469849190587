/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  if (!path || !path.href) return;
  const { origin: pathOrigin } = new URL(path.href);
  const { origin } = window.location;
  return pathOrigin !== origin ? true : false;
}
