export function handleErrorOrData(data, error, callback) {
  if (error && !data) {
    if (error.networkError) {
      return callback(undefined, error.networkError);
    }
    if (error.graphQLErrors) {
      return callback(undefined, error.graphQLErrors);
    }
  }
  if (error) console.error(error);
  return callback(data, error);
}
