import { onBeforeUnmount, onMounted, ref } from "vue";

// Initial simple setup but needs to be optimized and extended
export function useClickOutside(handler) {
  const target = ref(null);
  const isOutside = ref(false);
  const mounted = ref(false);

  function targetRef(element) {
    target.value = element;
  }

  function clickEffect(e) {
    if (!mounted.value || !target.value) return;
    isOutside.value = !target.value.contains(e.target);
    handler(e);
  }

  onMounted(() => {
    document.addEventListener("click", clickEffect, { passive: true, capture: true });
    mounted.value = true;
  });

  onBeforeUnmount(() => {
    document.removeEventListener("click", clickEffect, { passive: true, capture: true });
    isOutside.value = false;
  });

  return {
    target,
    targetRef,
    isOutside,
  };
}
