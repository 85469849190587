export const KEYS = Object.freeze({
  Space: " ",
  Enter: "Enter",
  Escape: "Escape",
  Backspace: "Backspace",
  Delete: "Delete",

  ArrowLeft: "ArrowLeft",
  ArrowUp: "ArrowUp",
  ArrowRight: "ArrowRight",
  ArrowDown: "ArrowDown",

  Home: "Home",
  End: "End",

  Meta: "Meta",
  PageUp: "PageUp",
  PageDown: "PageDown",
  Shift: "Shift",
  Tab: "Tab",

  Dash: "-",
  Period: ".",
});

export const NUMBER_KEYS = Object.freeze({
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
});

export const CURRENCY_KEYS = Object.freeze({
  ".": ".",
  ...NUMBER_KEYS,
});

export const DATE_KEYS = Object.freeze({
  "/": "/",
  ...NUMBER_KEYS,
});
