import { COMPARISONS } from "../maps";

/**
 *
 * @param { array } data // collection of questions
 * @param { ref } formState // form state should be a vue object ref such as formData instead of formData.value
 */
export function useReflexives(data, formState) {
  // @DEPRICATED Remove after transition to satisfied method which is a more viable solution.
  function translateDecision(operator, operandLeft, operandRight) {
    return COMPARISONS[operator](operandLeft, operandRight);
  }

  // Generic method for transforming answer to proper type
  const intergerTypes = ["CURRENCY_US_DOLLAR"];
  function _(/* String */ type, /* String */ answer) {
    if (intergerTypes.includes(type)) return Number(answer);
    return answer;
  }

  /**
   * This is used to determine if a reflexive question is triggered based on the source question
   * @param {string} source // Source question key
   * @param {string} target // Target question key
   * @returns {boolean}
   */
  function satisfied(/* String */ source, /* String */ target) {
    let answers = formState.value[source];
    const sourceQuestion = data.find(({ key }) => key === source);

    if (sourceQuestion?.reflexives && sourceQuestion.reflexives?.length) {
      const { valueType, reflexives } = sourceQuestion;
      const { conditions } = reflexives.find(({ targetKey }) => targetKey === target);
      // @TODO Revisit every as not sure this logic is correct.
      const every = conditions.every(({ operator }) => operator === "NOT_EQUAL");
      const collection = conditions.reduce((values, { operator, value }) => {
        if (Array.isArray(answers)) {
          values.push(answers.some((answer) => COMPARISONS[operator](_(valueType, answer), _(valueType, value))));
        } else {
          values.push(COMPARISONS[operator](_(valueType, answers), _(valueType, value)));
        }
        return values;
      }, []);
      const comparisonValue = true;
      if (every) return collection.every((value) => value === comparisonValue);
      return collection.some((value) => value === comparisonValue);
    }
  }

  return {
    satisfied,
    translateDecision,
  };
}
