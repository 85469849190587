import CREATE_INTEGRATION from "@api/graphql/createIntegration.mutation.gql";
import GET_INTEGRATIONS from "@api/graphql/getIntegrations.query.gql";
import { ref } from "vue";

import { useMutation } from "./mutation";
import { useQuery } from "./query";

export function useIntegrations(apollo) {
  const integrations = ref(null);
  /**
   * Returns an object containing the payload, success, and message properties for getAddress query.
   * @param {string} query
   * * @param {string} preferState
   * @returns {object}
   */
  async function queryIntegrations(id) {
    const { query } = useQuery(apollo);
    try {
      const response = await query(GET_INTEGRATIONS, { accountId: id });
      const { integrationLinks } = response.data.integrationLinks;
      return { payload: integrationLinks };
    } catch (error) {
      console.log("Error", error);
    }
  }

  async function mutateIntegration({ id, code, state, provider, verifier }) {
    const { mutation } = useMutation(apollo);
    try {
      const { response } = await mutation(CREATE_INTEGRATION, { accountId: id, code, state, provider, verifier });
      const { success, message } = response;
      return { success, message };
    } catch (error) {
      console.log("Error", error);
    }
  }

  return {
    integrations,
    queryIntegrations,
    mutateIntegration,
  };
}
