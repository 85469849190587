export function camelize(value, char = "_") {
  if (!value || !value.length) return;
  return value
    .split(char)
    .map((word, i) => {
      if (i !== 0) return `${word.charAt(0).toUpperCase()}${word.toLowerCase().slice(1)}`;
      return word.toLowerCase();
    })
    .join("");
}
