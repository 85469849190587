import { useReporter } from "@common/composables";

export function useMutation(/** Objecgt */ apollo) {
  const reporter = useReporter();
  async function mutation(type, variables = {}) {
    try {
      const response = await apollo.mutate({
        mutation: type,
        variables,
      });
      return { response };
    } catch (error) {
      reporter.error(error.message);
      return {
        response: {
          success: false,
          message: error.message,
        },
      };
    }
  }

  return {
    mutation,
  };
}
