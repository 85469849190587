<template>
  <main id="main">
    <slot />
  </main>
</template>

<script setup></script>

<style lang="scss" module>
main {
  min-height: calc(100vh - 64px);
}
</style>
