export function capitalize(value) {
  if (!value) return;
  return value[0].toUpperCase() + value.slice(1);
}

export function titlecase(value) {
  if (!value) return;
  return value
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
