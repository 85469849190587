import { isTrue } from "@common";

import { questionKeys, SLUGS } from "../constants";

export function useDecision() {
  // Income decisions
  const incomeFloor = 24000;
  const incomeCeil = 500000;
  const incomeMax = 1000000;

  // Lower income evaluation
  const incomeOverFloor = (value, floor = incomeFloor) => Number(value) >= floor; // Boolean
  const incomeUnderFloor = (value, floor = incomeFloor) => Number(value) < floor; // Boolean
  // END

  // Upper income evaluation
  const incomeOverCeil = (value, ceil = incomeCeil) => Number(value) > ceil; // Boolean
  const incomeUnderCeil = (value, ceil = incomeCeil) => Number(value) <= ceil; // Boolean
  // END

  // Income threshold evaluation
  const incomeWithinThresholds = (value) => incomeOverFloor(value) && incomeUnderCeil(value); // Boolean
  const incomeWithinMaxThresholds = (value) => incomeOverFloor(value) && incomeUnderCeil(value, incomeMax); // Boolean
  // END
  // END Income decisions

  // Income type decisions
  const personalIncome = (formState) => !(SLUGS.supplemental in formState); // Boolean
  const householdIncome = (formState) => questionKeys.SPOUSE_ANNUAL_INCOME in formState; //Boolean
  // END

  // Children decision
  const hasChildren = (value) => isTrue(value);
  // END

  // Nicotine descision
  const nicotineUser = (formState) => {
    return SLUGS.nicotine in formState && isTrue(formState[SLUGS.nicotine].answers.USES_NICOTINE);
  };
  // END

  // Decide where to go from current route to previous route
  const decideFrom = (slug, formState) => {
    switch (slug) {
      case SLUGS.children:
        // eslint-disable-next-line no-case-declarations
        const { answers: income } = formState[SLUGS.income];
        return incomeUnderFloor(income.ANNUAL_PRE_TAX_INCOME);
      case SLUGS.estimate:
        // eslint-disable-next-line no-case-declarations
        const { answers: children } = formState[SLUGS.children];
        return hasChildren(children.HAVE_CHILDREN);
      default:
        return true;
    }
  };

  // Decide where to go from current route to next route
  const decideTo = (slug, formState) => {
    switch (slug) {
      case SLUGS.income:
        // eslint-disable-next-line no-case-declarations
        const { answers: income } = formState[SLUGS.income];
        if (!Object.keys(income).length || !income.ANNUAL_PRE_TAX_INCOME.length) {
          income.ANNUAL_PRE_TAX_INCOME = "0";
        }
        return incomeOverFloor(income.ANNUAL_PRE_TAX_INCOME);
      case SLUGS.children:
        // eslint-disable-next-line no-case-declarations
        const { answers: children } = formState[SLUGS.children];
        return hasChildren(children.HAVE_CHILDREN);
      default:
        return true;
    }
  };

  return {
    incomeFloor,
    incomeCeil,
    incomeMax,
    decideFrom,
    decideTo,
    hasChildren,
    incomeOverFloor,
    incomeUnderFloor,
    incomeOverCeil,
    incomeUnderCeil,
    incomeWithinThresholds,
    incomeWithinMaxThresholds,
    nicotineUser,
    personalIncome,
    householdIncome,
  };
}
