import axios from "axios";

import { log } from "../logging/logging.js";

export class HttpClient {
	constructor(baseURL, options = {}) {
		const headers = {
			"X-Client": "vue",
		};
		const { token, extraHeaders } = options;
		if (token) {
			headers["Authorization"] = token;
		}
		if (extraHeaders) {
			const headerKeys = Object.keys(extraHeaders);
			for (let i = 0; i < headerKeys.length; i++) {
				const key = headerKeys[i];
				headers[key] = extraHeaders[key];
			}
		}
		const clientUrl = baseURL || process.env.VUE_APP_API_URL;
		log(`Creating HTTP client: connecting to ${clientUrl}.`);
		this.httpClient = axios.create({
			baseURL: clientUrl,
			headers,
		});
	}
}

export default {
	install: (app) => {
		app.config.globalProperties.$apiClient = new HttpClient();
	},
};
