import { ref } from "vue";

const diagnoses = ref({});
const diagnosisItems = ref([]);
const otherDiagnosisItems = ref([]);
const titles = ref([]);

export function useHealthDiagnosis() {
  function setDiagnoses(values) {
    diagnoses.value = values;
  }

  function setDiagnosisItems(values) {
    diagnosisItems.value = values;
  }

  function setOtherDiagnosisItems(values) {
    otherDiagnosisItems.value = values;
  }

  function setTitles(values, options) {
    if (!values.length) return;
    for (const value of values) {
      const option = options.find((option) => option.value === value);
      if (titles.value.includes(option.text)) titles.value.push(option.text);
    }
  }
  return {
    setDiagnoses,
    setDiagnosisItems,
    setOtherDiagnosisItems,
    setTitles,
    diagnoses,
    diagnosisItems,
    otherDiagnosisItems,
    titles,
  };
}
