import { useStates } from "@common/composables";
import { hasKeys, isArray, isObject, isString } from "@common/utils";
import { STORAGE_KEYS, useSessionStorage } from "@composables";
import flagsmith from "flagsmith";
import { defineStore } from "pinia";

import { useQuoteStore } from "./modules/quote.module";

/**
 * Stores user props not associated to the auth user such as the user, agency, interviews, or other future needs.
 * $ is used on state prop and removed for the corresponding getter. This ensures context and relationship.
 */
export const useUserStore = defineStore("user", {
  state: () => ({
    $user: {}, // Props should always have an initial value if possible so the data type is clear.
    $agency: {},
    $interviews: [],
  }),
  getters: {
    /**
     * The current user and any associated user data
     * @returns Object
     */
    user: (state) => {
      return {
        ...state.$user,
        get interviews() {
          return state.$interviews;
        },
      };
    },
    id: (state) => {
      return state.$user.id;
    },
    interview: (state) => {
      return state.$interviews.length
        ? state.$interviews[0]
        : state.$user?.interviewSummaries
        ? state.$user?.interviewSummaries[0]
        : null;
    },
    quote: (state) => {
      const quoteStore = useQuoteStore();
      return state.$user.quote || quoteStore.quote;
    },
    variant: (state) => {
      return state?.interview ? state.interview.product.variant : null;
    },
    ctl: (state) => {
      return state?.interview ? state.interview.product.vendor === "CTL" : true;
    },
    ctlUser: (state) => {
      /**
       *  @ISSUES This is not reactive which is an issue. Might be better as a method or in composable
       * For instance if you log userStore.ctlUser in ModalAuthorization it will always be the intial value even if the state changes
       * on q/start
       **/
      // @TODO Refactor this after migration
      const { get } = useSessionStorage();
      const { isCTL } = useStates();
      const flag = "south_carolina_release";
      const lead = get(STORAGE_KEYS.DF_LEAD);

      if (state.interview) return state.interview.product.vendor === "CTL";
      if (lead) return isCTL(lead.HOME_STATE, { flag, value: flagsmith.hasFeature(flag) });
      return false;
    },
    /**
     * Generic prop checker returning a boolean if current data on the property. This generic getter reduce code and the need to
     * add additional prop checks if the state object changes
     * @param {string} prop - state property
     * @returns {boolean}
     */
    hasProp: (state) => {
      return (prop) => {
        if (!state[prop]) return false;
        if (isArray(state[prop]) || isString(state[prop])) return state[prop].length > 0;
        if (isObject(state[prop])) return hasKeys(state[prop]);
      };
    },
  },
  actions: {
    /**
     * Generic actions to set state props. This reduces code and need to add additional setters for each new state prop.
     * @param {string} prop - the state property to be set (example: $user)
     * @param {(string|array|object)} data - the data that is assigned to the state property
     */
    setState(prop, data) {
      this[prop] = data;
    },
  },
});
