export const SLIDER_LABELS = Object.freeze({
  ANNUAL_BENEFIT: "INCOME PROTECTED",
  CHILD_AGE: {
    LABEL: "COVERED UNTIL CHILD IS",
    FORMAT: "years old",
  },
  DEFAULT: {
    LABEL: "TERM LENGTH",
    FORMAT: "years",
  },
  INSURED_AGE: {
    LABEL: "COVERED UNTIL",
    FORMAT: "Age",
  },
  PREMIUM: "MONTHLY PRICE",
});
