import flagsmith from "flagsmith";

export class FlagsmithFlagger {
  constructor(options) {
    this.settings = options.settings;
    this.flagsmithReady = false;
  }

  async init() {
    if (this.settings?.key) {
      const config = {
        environmentID: this.settings.key,
        cacheFlags: this.settings?.cacheFlags ?? true,
        enableAnalytics: this.settings?.enableAnalytics ?? true,
        api: "https://flagsmithapi.dayforward.com/api/v1/"
      };
      await flagsmith.init(config);
      this.flagsmithReady = true;
    }
  }

  async prefetchFlags() {
    return await this.asyncSafeFlagsmith(async (f) => {
      return f.getAllFlags();
    });
  }

  hasFeature(key) {
    return this.safeFlagsmith((f) => {
      return f.hasFeature(key);
    });
  }

  isReady() {
    return this.flagsmithReady;
  }

  safeFlagsmith(callback) {
    if (this.flagsmithReady) {
      return callback(flagsmith);
    }
    return undefined;
  }

  async asyncSafeFlagsmith(callback) {
    if (this.flagsmithReady) {
      return await callback(flagsmith);
    }
    return undefined;
  }
}
