import { datadogRum } from "@datadog/browser-rum";

export class DatadogReporter {
  constructor() {}
  captureMessage(message) {
    // no/op
  }
  captureException(err) {
    datadogRum.addError(err);
  }
  captureTaggedException(tag, tagValue, err) {
    datadogRum.addError(err, { tag: tag, tagValue: tagValue });
  }
}
