import { datadogLogs } from "@datadog/browser-logs";
import {
  AnalyticsPlugin,
  DatadogPlugin,
  ErrorsPlugin,
  FeaturesPlugin,
  FirebaseConfig,
  FirebasePlugin,
  MaskDirective,
  ResponsivePlugin,
  ScrollDirective,
  vFocus,
} from "@dayforward/common";
import { Head } from "@dayforward/common/src/composables";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createPinia } from "pinia";
import { createApp } from "vue";

import App from "./App.vue";
import { STORAGE_KEYS, useSessionStorage } from "./composables";
import { ApolloPlugin, AxiosPlugin } from "./plugins";
import { createAppRouter } from "./router";
import { store } from "./store";
import { useAuthStore } from "./store/auth.store";
import { useUserDataStore } from "./store/modules/user-data.module.js";

// create app
const app = createApp(App);

// create vue router
const createRouter = () => {
  app.use(
    createAppRouter({
      currentApp: app,
      newInterviewEnabled: process.env.VUE_APP_NEW_INTERVIEW_ENABLED,
    }),
  );
};

// initialize firebase
const config = new FirebaseConfig();
const fbApp = initializeApp(config.configObject);
app.use(createPinia());

let isAppInitialized = false;
const checkAuth = async (firebaseApp, callback) => {
  // creating the router earlier so it has time to load before app is mounted
  // -- will probably need to refactor main.js and index.js to make these steps
  //    happen in a certain order (createApp, checkAuth, use(router), mount(#app))
  createRouter();
  const auth = getAuth();
  // this will fire when the firebase authentication module is first initialized OR when the state of the
  // authentication changes
  onAuthStateChanged(auth, async (user) => {
    const userDataStore = useUserDataStore();
    const authStore = useAuthStore(); // @TODO: replace userDataStore with useAuthStore
    // await currentUser.getIdToken();
    // TODO: this is the recommended way to  check logged in state but I am not sure it works for us
    if (user && user.isAnonymous === false) {
      await user.getIdToken(true);
      authStore.setState("$authenticated", true);
      userDataStore.updateLoggedInState(true);
    } else {
      authStore.setState("$authenticated", false);
      userDataStore.updateLoggedInState(false);
    }

    let currentUser = user;

    if (!isAppInitialized) {
      isAppInitialized = true;
      // execute the callback to run the post auth initialization stuff (aka,
      // start the app). NOTE: currentUser could be null at this point.
      callback(app, firebaseApp, currentUser);
    }
  });
};

// startApp does the application and support object (e.g. plugins, directives,
// etc) creation and then mounts the application. It is possible that we might
// want to split some of this up so that we can do some application
// initialization in parallel with the other pre-launch checks we have to do
// in order to reduce the overall start time.
const startApp = async (app, firebaseApp) => {
  let featuresOptions = { provider: "noop" };
  const areFeatureFlagsDisabled =
    process.env.VUE_APP_FEATURES_DISABLED && process.env.VUE_APP_FEATURES_DISABLED === "true";
  if (process.env.VUE_APP_FLAGSMITH_API_KEY && !areFeatureFlagsDisabled) {
    featuresOptions = {
      provider: "flagsmith",
      settings: {
        key: process.env.VUE_APP_FLAGSMITH_API_KEY,
      },
    };
  }
  app.use(FeaturesPlugin, featuresOptions);

  // try to refresh the token if needed. enabling the line below will mean
  // that users are signed in forever.
  // if (currentUser) {
  // 	await currentUser.getIdToken(true);
  // }
  // configure error reporting
  const authStore = useAuthStore();
  const { set: _set } = useSessionStorage();

  let errorOptions = { provider: "console" };

  let sUsrAg = navigator.userAgent;
  if (
    sUsrAg.indexOf("HeadlessChrome") === -1 &&
    process.env.VUE_APP_RELEASE_STAGE !== "local" &&
    process.env.VUE_APP_RELEASE_STAGE !== ""
  ) {
    datadogLogs.init({
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      env: process.env.VUE_APP_RELEASE_STAGE,
      service: process.env.VUE_APP_DATADOG_SERVICE,
      version: process.env.VUE_APP_DATADOG_APP_VERSION,
      forwardReports: "all",
      forwardErrorsToLogs: true,
      forwardConsoleLogs: "all",
    });
    DatadogPlugin.init({
      applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      service: process.env.VUE_APP_DATADOG_SERVICE,
      env: process.env.VUE_APP_RELEASE_STAGE,
      version: process.env.VUE_APP_DATADOG_APP_VERSION,
      trackFrustrations: true,
      trackInteractions: true,
    });
    errorOptions = {
      provider: "datadog",
      settings: {
        applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
        service: process.env.VUE_APP_DATADOG_SERVICE,
        env: process.env.VUE_APP_RELEASE_STAGE,
        version: process.env.DATADOG_APP_VERSION,
        trackFrustrations: true,
        trackInteractions: true,
      },
    };
  }
  app.use(ErrorsPlugin, errorOptions);

  // plugins
  app.use(store);

  // the user data store stores user session specific data
  const sessionId = authStore.id;
  _set(STORAGE_KEYS.DF_SESSION_ID, { id: sessionId });
  app.use(ApolloPlugin, {
    sessionId,
    connectToDevTools: process.env.NODE_ENV === "development",
  });
  app.use(AxiosPlugin);
  app.use(ResponsivePlugin);
  app.use(FirebasePlugin, { firebaseApp });

  // data
  //const language = navigator.language ?? "en";
  // const definitions = {
  //   all: (await import("./data/all")).default,
  // };
  // initialize english last as this is also the fallback language if no other
  // definition (other than all) has been loaded.
  // if (language.startsWith("en") || Object.keys(definitions).length === 1) {
  //   definitions["en"] = (await import("./data/en")).default;
  // }
  //app.use(DataPlugin, { definitions });

  // directives
  app.directive("scroll", ScrollDirective);
  app.directive("mask", MaskDirective);
  app.directive("focus", vFocus);

  // segment / analytics
  let analyticsOptions = { provider: "noop" };
  const isSegmentDisabled = process.env.VUE_APP_SEGMENT_DISABLED && process.env.VUE_APP_SEGMENT_DISABLED === "true";
  if (process.env.VUE_APP_SEGMENT_KEY && !isSegmentDisabled && sUsrAg.indexOf("HeadlessChrome") === -1) {
    analyticsOptions = {
      provider: "segment",
      apolloClient: app.config.globalProperties.$apollo,
      settings: {
        key: process.env.VUE_APP_SEGMENT_KEY,
      },
    };
  }
  app.use(AnalyticsPlugin, analyticsOptions);

  // add youtube script element
  // const videoScriptTag = document.createElement("script");
  // videoScriptTag.async = true;
  // videoScriptTag.src = "https://www.youtube.com/player_api";
  // const firstScriptTag = document.getElementsByTagName("script")[0];
  // firstScriptTag?.parentNode?.insertBefore(videoScriptTag, firstScriptTag);

  // mount app - let's go
  app.component("Head", Head);
  app.mount("#app");
};
checkAuth(fbApp, startApp);