import { ref } from "vue";

const errors = ref({});

export function useErrors() {
  // Input errors;
  function clearErrors() {
    errors.value = {};
  }

  function removeErrors(...keys) {
    keys.flatMap((key) => key).forEach(removeError);
  }

  function hasError(key) {
    return key in errors.value;
  }

  function removeError(key) {
    delete errors.value[key];
  }

  function setError(key, value) {
    errors.value[key] = value;
  }

  function hasErrors() {
    return Object.keys(errors.value).length > 0;
  }

  function error(key) {
    if (!(key in errors.value)) return null;
    return errors.value[key];
  }

  function ifHasErrorRemoveError(key) {
    if (hasError(key)) removeError(key);
  }

  return {
    clearErrors,
    errors,
    error,
    hasError,
    ifHasErrorRemoveError,
    removeError,
    removeErrors,
    setError,
    hasErrors,
  };
}
