import { readableList } from "@dayforward/common";
import { ref, toRaw } from "vue";

import { useSchema } from "../composables";
const members = ref([]);

const relationshipKey = "familyHistory0Relationship";
const relationshipValue = ref(null);

const severityKey = "familyHistory0Severity";
const severityValue = ref(null);

const diagnosesKey = "familyHistory0Diagnosis";
const diagnoseValues = ref([]);

export function useFamilyHealth() {
  const { SCHEMA } = useSchema();
  // Selections
  function displayEffect(key, value) {
    if (!value) return "";
    const option = SCHEMA.value[key].options.find((option) => option.value === value);
    if (!option) return "";
    return option.text;
  }

  // Family Relationship
  const displayRelationshipEffect = (value) => displayEffect(relationshipKey, value);

  // Select Severity
  const displaySeverityEffect = (value) => displayEffect(severityKey, value);

  const parseValues = () => {
    relationshipValue.value = SCHEMA.value[relationshipKey].options.find(
      (option) => option.value === relationshipValue.value,
    );
    severityValue.value = SCHEMA.value[severityKey].options.find((option) => option.value === severityValue.value);
    return {
      relationship: { text: relationshipValue.value.text, value: relationshipValue.value.value },
      diagnoses: { text: readableList(diagnoseValues.value, /_/g), value: toRaw(diagnoseValues.value) },
      severity: { text: severityValue.value.text, value: severityValue.value.value },
    };
  };

  const addEffect = () => {
    if (!relationshipValue.value || !diagnoseValues.value.length || !severityValue.value) {
      return;
    }
    members.value.push(parseValues());
  };

  const mutateEffect = (i) => {
    if (!diagnoseValues.value) return;
    members.value[i] = parseValues();
  };

  const editEffect = (i) => {
    const { relationship, diagnoses, severity } = members.value[i];
    relationshipValue.value = relationship.value;
    diagnoseValues.value = diagnoses.value;
    severityValue.value = severity.value;
  };

  const resetEffect = () => {
    relationshipValue.value = null;
    diagnoseValues.value = [];
    severityValue.value = null;
  };

  const resetCollection = () => {
    members.value = [];
  };

  const removeEffect = (i) => {
    members.value.splice(i, 1);
  };

  return {
    members,
    addEffect,
    mutateEffect,
    editEffect,
    relationshipKey,
    severityKey,
    diagnosesKey,
    displayRelationshipEffect,
    displaySeverityEffect,
    diagnoseValues,
    relationshipValue,
    severityValue,
    removeEffect,
    resetEffect,
    resetCollection,
  };
}
