import { toArray } from "../utils";

export const TEXT_INPUTS = Object.freeze({
  Email: "email",
  Number: "number",
  Password: "password",
  Text: "text",
  Url: "url",
});

export const DATE_INPUTS = Object.freeze({ Date: "date", Month: "month" });
export const TEXT_FIELDS = Object.freeze(toArray(TEXT_INPUTS));
export const PHONE_FIELD = "tel";
export const INPUT_FIELDS = Object.freeze(["checkbox", "radio"]);
