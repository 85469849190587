// Using Object.freeze to make constants immutable
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
export const ROLES = Object.freeze({
  UNSPECIFIED_ROLE: "UNSPECIFIED_ROLE",
  USER: "USER",
  UNDERWRITER: "UNDERWRITER",
  CUSTOMER_SERVICE: "CUSTOMER_SERVICE",
  AGENT: "AGENT",
  OWNER: "OWNER",
});

export const AGENT_ROLES = Object.freeze([ROLES.AGENT, ROLES.OWNER]);
