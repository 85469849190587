import { initializeApp } from "firebase/app";
import { inject } from "vue";

const FirebaseInjectKey = "FirebaseInjectKey";

export class FirebaseConfig {
  constructor(values) {
    const projectId = values?.projectId ?? process.env.VUE_APP_FBASE_PROJECT_ID;
    this.apiKey = values?.apiKey ?? process.env.VUE_APP_FBASE_API_KEY;
    this.projectId = projectId;
    this.authDomain = values?.authDomain ?? `${projectId}.firebaseapp.com`;
    this.storageBucket = values?.storageBucket ?? `${projectId}.appspot.com`;
    this.messagingSenderId = values?.messagingSenderId ?? process.env.VUE_APP_FBASE_MSG_SENDER_ID;
    this.appId = values?.appId ?? process.env.VUE_APP_FBASE_APP_ID;
    this.measurementId = values?.measurementId ?? process.env.VUE_APP_FBASE_MEASUREMENT_ID;
  }

  get configObject() {
    const config = {
      apiKey: this.apiKey,
      projectId: this.projectId,
      authDomain: this.authDomain,
      storageBucket: this.storageBucket,
      messagingSenderId: this.messagingSenderId,
      appId: this.appId,
    };
    if (this.measurementId) {
      config["measurementId"] = this.measurementId;
    }
    return config;
  }
}

export function useFirebase() {
  return inject(FirebaseInjectKey);
}

export const FirebasePlugin = {
  install: (app, options) => {
    let fbApp = options?.firebaseApp;
    if (!fbApp) {
      const config = new FirebaseConfig();
      fbApp = initializeApp(config.configObject);
    }
    app.provide(FirebaseInjectKey, fbApp);
    app.config.globalProperties.$firebase = fbApp;
  },
};
