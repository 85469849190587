export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
export const wraparound = (num, min, max) => {
  return num > max ? min : num < min ? max : num;
};

export const boolValue = (value) => {
  if (typeof value === "boolean") {
    return value;
  } else if (typeof value === "string") {
    const lvalue = value.toLowerCase();
    return lvalue === "true" || lvalue === "yes" || lvalue === "1";
  } else if (typeof value === "number") {
    return value === 1;
  }
  return false;
};
