import { Answer } from "@dayforward/api/src/models/answer.js";
import { QuoteInputs } from "@dayforward/api/src/models/quote.js";

import { SavedStateKeys } from "../../config";

export const quickPriceModule = {
	namespaced: true,
	state: () => ({
		answerSet: {},
		interviewID: undefined,
		lastAccessed: undefined,
	}),
	getters: {
		answers: (state) => state.answerSet,
		getAnswer: (state, key) => state.answerSet[key],
		quoteInputs: (state) => {
			return new QuoteInputs(state.answerSet);
		},
	},
	mutations: {
		SET_ANSWER(state, answer) {
			state.answerSet[answer.key] = answer;
		},
		SET_ANSWERS(state, answers) {
			state.answerSet = { ...state.answerSet, ...answers };
		},
	},
	actions: {
		syncWithServer({ dispatch }) {
			// make an api call
			// if success, persist local
			dispatch("persistLocalState");
		},
		restoreLocalState({ state, commit }) {
			state.interviewId = window.localStorage.getItem(SavedStateKeys.INTERVIEW_ID);
			// if lastAccess > 15 mins ago then don't use the interview id
			const cachedInputsJSON = window.sessionStorage.getItem(SavedStateKeys.INTERVIEW_QUESTIONS);
			const answers = cachedInputsJSON ? JSON.parse(cachedInputsJSON) : {};
			commit("SET_ANSWERS", answers);
		},
		persistLocalState({ state }) {
			window.localStorage.setItem(SavedStateKeys.INTERVIEW_ID, state.interviewID);
			window.sessionStorage.setItem(SavedStateKeys.INTERVIEW_QUESTIONS, JSON.stringify(state.answerSet));
		},
		setAnswer({ commit, dispatch }, answer) {
			if (!answer || typeof answer !== Answer) {
				throw new Error("answer value is invalid type or empty");
			}
			commit("SET_ANSWER", answer);
			dispatch("persistLocalState");
		},
		putInputs({ commit, dispatch }, inputs) {
			if (!inputs) {
				throw new Error("inputs value is invalid type or empty");
			}
			const answers = {};
			const keys = Object.keys(inputs);
			for (let ki = 0; ki < keys.length; ki++) {
				const k = keys[ki];
				answers[k] = new Answer(k, inputs[k]);
			}
			commit("SET_ANSWERS", answers);
			dispatch("persistLocalState");
		},
		putAnswers({ commit, dispatch }, answers) {
			if (!answers) {
				throw new Error("answers value is invalid type or empty");
			}
			commit("SET_ANSWERS", answers);
			dispatch("persistLocalState");
		},
	},
};
