import { isNull, isUndefined } from "@dayforward/common";
import { useErrors } from "@dayforward/common/src/composables";
import { ref } from "vue";

import { useRequired } from "./required";

const formData = ref({});
const _cache_ = ref({});
const submitted = ref(false);
const { clearErrors } = useErrors();
const { resetRequiredKeys } = useRequired();
const EMPTY = "";

export function useForm() {
  function sanitizeFormData() {
    for (const key in formData.value) {
      if (isUndefined(formData.value[key]) || isNull(formData.value[key])) delete formData.value[key];
    }
  }

  function resetFormData() {
    formData.value = {};
  }

  function resetForm() {
    resetFormData();
    resetRequiredKeys();
    clearErrors();
  }

  function storeCache(value) {
    _cache_.value = { ..._cache_.value, ...value };
  }

  function getCache() {
    return _cache_.value;
  }

  function resetCache() {
    _cache_.value = {};
  }

  function store(key, value) {
    formData.value[key] = value;
  }

  function remove(...keys) {
    keys.flatMap((key) => key).forEach((key) => delete formData.value[key])
  }

  return {
    EMPTY,
    sanitizeFormData,
    getCache,
    resetFormData,
    resetCache,
    formData,
    resetForm,
    storeCache,
    submitted,
    remove,
    store,
  };
}
