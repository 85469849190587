export function cookie(value) {
  try {
    const cookieArray = document.cookie.split(";");
    const c = cookieArray.find((u) => u.trim().startsWith(value));
    if (!c || c.length === 0) return null;
    // eslint-disable-next-line no-unused-vars
    const [_, data] = c.trim().split("=");
    return data;
  } catch (error) {
    console.error("Error decoding cookie:", error);
  }
  return null; // Return null if the cookie is not found or there's an error
}
