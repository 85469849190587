import { ref } from "vue";

import { addCommas, formatPhone, formatSSN, isArray } from "../utils";

const answers = ref({});

export function useAnswers() {
  const EMPTY = "";

  function findAnswer(key, questions = null) {
    if (key in answers.value) return answers[key];
    if (!questions && !(key in answers.value)) return null;
    return "";
  }

  function mapAnswers(questions) {
    const collection = questions.reduce((c, { key, answers: a, answer: aa, multipleChoice }) => {
      if (!multipleChoice) c[key] = a[0];
      if (multipleChoice) c[key] = a;
      if (key === "FAMILY_HISTORY" && aa.familyHistory) {
        const map = aa.familyHistory.map(({ diagnoses, relationship, severity }) => ({
          diagnoses,
          relationship,
          severity,
        }));
        c[key] = map;
      }
      return c;
    }, {});
    return collection;
  }

  function transformAnswer(value, override = null) {
    if (!value) return;
    if (value === "true" || (value.includes("U.S. Citizen,") && override === "CITIZENSHIP_RES")) return "Yes";
    if (value === "false") return "No";
    return value;
  }

  // Parse answers to submit to the backend
  function parseAnswers(questions, answers) {
    const map = questions.reduce((c, i) => {
      const { key, allowsNone, required } = i;
      let values = answers[key];
      if (!isArray(values)) {
        if (values === undefined || values === EMPTY) {
          // Allow empty answer for required questions that allows none (exp: SSN)
          if ((allowsNone && !required) || (allowsNone && required)) values = [EMPTY];
          else values = null;
        } else {
          values = [values.toString()];
        }
      } else if (key === "FAMILY_HISTORY") return [{ questionKey: key, familyHistory: values }];
      // If value array return values that aren't empty strings
      if (["FAMILY_HISTORY_0_DIAGNOSIS", "FAMILY_HISTORY_0_RELATIONSHIP", "FAMILY_HISTORY_0_SEVERITY"].includes(key))
        return c;
      if (values && values.length > 1 && !allowsNone) values = values.filter((v) => v !== "");
      if (values) c.push({ questionKey: key, values });
      return c;
    }, []);
    return map;
  }

  function resetAnswers(questions) {
    const mapped = mapAnswers(questions, answers.value);
    answers.value = { ...answers.value, ...mapped };
  }

  // Parse answer from question to display as text
  function parseAnswer(question, questions, overrides) {
    const { answers, answer, options, allowsNone, key } = question;
    const keys = ["FAMILY_HISTORY_0_RELATIONSHIP", "FAMILY_HISTORY_0_DIAGNOSIS", "FAMILY_HISTORY_0_SEVERITY"];
    if (keys.includes(key)) return;
    if (key === "OCCUPATION" && overrides.length && answers.length) {
      return overrides.find(({ key }) => key === answers.at(0)).name;
    }
    if (key.endsWith("_OTHER") && overrides.length && answers.length) {
      return overrides.find(({ code }) => code === answers.at(0))?.description;
    }
    if (options.length) {
      let values = "";
      answers.forEach((a) => {
        let option = options.find(({ value }) => value === a);
        if (!option && a === "") {
          if (allowsNone && key !== "HEALTH_CONDITIONS") option = { text: ["None"], values };
        }
        values += !values.length ? `${!option?.text ? `` : option.text}` : `, ${option.text}`;
      });
      if (key === "HEALTH_CONDITIONS" && !values.length) {
        const acknowledgedQuestion = questions.find(({ key }) => key === "HEALTH_CONDITIONS_ACKNOWLEDGED");
        const acknowledged = acknowledgedQuestion.answers.length; // Answers is either empty[] or ["true"];
        return acknowledged ? "None" : "--";
      }
      return values.length ? values : "--";
    }
    //
    const a = answers.join(" ");

    // @TODO This is a mess need to refactor asap
    if (key === "FAMILY_HISTORY" && answer.familyHistory) {
      const reflexives = questions.filter(({ key: k }) => keys.includes(k));
      const relationship = reflexives.at(0);
      const diagnosis = reflexives.at(1);
      const severity = reflexives.at(2);
      const parsedHistory = answer.familyHistory.reduce((a, i) => {
        const r = relationship.options.find(({ value }) => value === i.relationship);
        const d = diagnosis.options.filter(({ value }) => i.diagnoses.includes(value)).map(({ text }) => text);
        const s = severity.options.find(({ value }) => value === i.severity);
        a.push(`${r.text} - ${d.join(", ")} - ${s.text}`);
        return a;
      }, []);
      return parsedHistory.join(":");
    }
    if (key === "MOBILE_PHONE_NUMBER") return formatPhone(a);
    if (key === "HEIGHT_INCHES") return a.length ? `${Math.floor(a / 12)}' ${a % 12}"` : "";
    if (key === "WEIGHT_POUNDS" || key === "WEIGHT_CHANGED_POUNDS") return a.length ? `${a}lbs` : "";
    if (
      key === "ANNUAL_PRE_TAX_INCOME" ||
      key === "PREVIOUS_ANNUAL_PRE_TAX_INCOME" ||
      key === "SPOUSE_ANNUAL_INCOME" ||
      key === "EXISTING_COVERAGE_AMOUNT"
    ) {
      return a.length ? `$${addCommas(a)}` : "";
    }
    if (key === "SSN" && a.length) return formatSSN(a);
    if (key === "DRIVERS_LICENSE_NUMBER" && a.length) return "•".repeat(a.length);
    return answers.length ? a : ["MIDDLE_INITIAL", "HOME_ADDRESS_2"].includes(key) ? "" : "--";
  }

  function destroy() {
    answers.value = {};
  }

  return {
    answers,
    transformAnswer,
    mapAnswers,
    parseAnswers,
    findAnswer,
    resetAnswers,
    parseAnswer,
    destroy,
  };
}
