<template>
  <dt>
    <button :ref="api.triggerRef(index)" :class="[$style.toggle]" @click="api.toggle">
      <slot name="title" :active="active" />
      <!-- eslint-disable-next-line prettier/prettier -->
      <svg :class="[ $style.icon, { [$style.rotate]: active }]" xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="currrentColor" focusable="false"><path d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z"/></svg>
    </button>
  </dt>
  <dd :ref="api.panelRef(index)" :class="[$style.content]">
    <Transition name="show">
      <div :class="$style.copy" v-show="active">
        <slot name="content" :active="active" />
      </div>
    </Transition>
  </dd>
</template>

<script setup>
import { computed, inject } from "vue";

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  index: Number,
});
const api = inject("api");

const active = computed(() => api.isActive(props.index));
</script>

<style lang="scss" module>
.toggle {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: var(--space-default) 0 18px;
  background: transparent;
  border: 0;
  color: currentColor;
  cursor: pointer;
  outline: none;
  text-align: left;
  &:focus-visible {
    box-shadow: 0 0 0 4px rgb(var(--base-color-secondary-rgb), 0.2);
  }
}

.icon {
  transition: transform 230ms ease-in-out;
}

.rotate {
  transform: rotate(45deg);
}

.content {
  padding: 0;
  margin: 0;
  border-bottom: rgba(var(--base-color-secondary-rgb), 0.3) solid 1px;
  & a {
    position: relative;
    color: var(--base-color-primary);
    text-decoration: none;
    outline: none;
    &:after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 50%;
      width: 0;
      height: 1px;
      background: currentColor;
      transition: width 300ms ease-in-out, left 300ms ease-in-out;
    }
    &:hover:after,
    &:focus-visible:after {
      width: 100%;
      left: 0;
    }
  }
}

.copy {
  margin-top: -2px;
  padding-bottom: var(--space-default);
  max-width: 900px;
  overflow: hidden;
}
</style>

<style>
.show-enter-to,
.show-leave-from {
  max-height: 9999px;
  transition: max-height 3000ms ease-in-out, opacity 600ms ease-in-out;
}

.show-enter-from,
.show-leave-to {
  opacity: 0;
  max-height: 0;
  transition: max-height 200ms ease-in-out, opacity 200ms ease-in-out;
}
</style>
