import { ref } from "vue";

const conditions = ref({});
const condition = ref(null);
const titles = ref([]);

export function useHealthConditions() {
  function setCondition(value) {
    condition.value = value;
  }

  function setConditions(value) {
    conditions.value = value;
  }

  function setTitles(values, options) {
    if (!values.length) return;
    for (const value of values) {
      const option = options.find((option) => option.value === value);
      if (!titles.value.includes(option.text)) titles.value.push(option.text);
    }
  }

  return {
    setCondition,
    setConditions,
    setTitles,
    condition,
    conditions,
    titles,
  };
}
