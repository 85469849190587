import { ref } from "vue";

export function useTitle() {
  const title = ref("");

  const setTitle = (value) => title.value = value;
  const unsetTitle = () => title.value = "";
  
  return {
    title,
    setTitle,
    unsetTitle,
  }
}