import { ref } from "vue";

const notify = ref(false);
const callback = ref(null);
export function useNotifications() {
  const open = (cb) => {
    notify.value = true;
    callback.value = cb;
  };

  const confirmEffect = (confirm = false) => {
    notify.value = false;
    if (confirm && typeof callback.value === "function") {
      callback.value();
      callback.value = null;
    }
  };

  return {
    notify,
    open,
    confirmEffect,
  };
}
