export class ContentMap {
  constructor(language = "en", definitions) {
    this.definitions = {};
    if (definitions) {
      this.definitions = definitions;
    }
    this.language = language;
  }

  formatString(format, subs) {
    return format.replace(/{([0-9]+)}/g, function (match, index) {
      return typeof subs[index] == "undefined" ? match : subs[index];
    });
  }

  getStringValue(key, defaultValue = undefined, ...subs) {
    const langValues = this.definitions[this.language];
    const allValues = this.definitions["all"];
    let value = defaultValue;
    if (langValues && langValues[key]) {
      value = langValues[key];
    } else if (allValues && allValues[key]) {
      value = allValues[key];
    }
    if (!subs) {
      return value;
    }
    return this.formatString(value, subs);
  }

  getValue(key, defaultValue = undefined) {
    const langValues = this.definitions[this.language];
    const allValues = this.definitions["all"];
    if (langValues && langValues[key]) {
      return langValues[key];
    }
    if (!allValues) {
      return defaultValue;
    }
    return allValues[key] ?? defaultValue;
  }
}
