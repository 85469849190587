export class NoOpFlagger {
  prefetchFlags() {}

  hasFeature() {
    return false;
  }

  isReady() {
    return true;
  }
}
