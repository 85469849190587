export const webhooksRoutes = [
  {
    path: "/webhook/docusign",
    name: "docusignWebhook",
    component: () => import("../webhook/Docusign.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];
