import { inject } from "vue";

import { ConsoleReporter, DatadogReporter } from "../errors";

const ErrorReporterKey = "ErrorReporterKey";

export function useErrorReporter() {
  return inject(ErrorReporterKey);
}

export const ErrorsPlugin = {
  install: (app, options) => {
    let reporter = new ConsoleReporter();
    let provider = "console";
    if (options) {
      provider = options.provider || "console";
      if (provider === "datadog" && !!options.settings) {
        reporter = new DatadogReporter(options);
      }
    }
    if (!reporter) {
      throw new Error("error reporting was not configured correctly");
    }
    app.provide(ErrorReporterKey, reporter);
    app.config.globalProperties.$errors = reporter;
  },
};
