import { CARD_LAYOUTS } from "@common/types";
import { ref } from "vue";

const selectedTitle = ref("");
export function useLevels(quotes, display, name = null) {
  const expenseCoverage = "EXPENSE_COVERAGE";
  const { quote, quoteLow, quoteMedium } = quotes;
  const { benefitType, hasOverride, naarValue, naarThreshold } = quote || {};
  const overNaarThreshold = naarValue > naarThreshold;
  const altLevels = benefitType === expenseCoverage || overNaarThreshold;
  const levels = !altLevels ? ["Default", "Base", "Customize"] : ["Default", "Customize"];
  const titles = [
    ["Essentials plan", "Classic plan", "Custom plan"], // Standard 3 card display
    ["Expense Coverage plan", "Custom plan"], // Household income 2 card dsiplay
    ["High Income plan", "Personalized plan"], // High Income 2 card display
  ];
  const options = [];

  const selectTitle = () => {
    if (!quote) return "";
    const _ = !altLevels ? [quoteLow, quoteMedium] : [quoteMedium];
    const t = overNaarThreshold ? titles.at(2) : benefitType === expenseCoverage ? titles.at(1) : titles.at(0);
    const index = _.indexOf(
      _.find(({ premium, termRecommended }) => premium === quote.premium && termRecommended === quote.termRecommended),
    );
    if (display === CARD_LAYOUTS.SINGLE_VIEW) return !name ? titles.at(-1).at(-1) : `${name}'s plan`; // Returns "Personalize plan"
    return index === -1 ? t.at(!hasOverride ? t.at(0) : t.length - 1) : t.at(index);
  };

  selectedTitle.value = selectTitle();

  return {
    levels,
    titles,
    options,
    expenseCoverage,
    selectedTitle,
  };
}
