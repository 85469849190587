import { defineComponent, onBeforeUnmount, ref, watchEffect } from "vue";
//const acceptedFields = Object.freeze(["title", "meta", "link"]);
const DATA_KEY = "data-vue-head";
const IS_BROWSER = typeof window !== "undefined";
const head = document.head;

function toProp(collection) {
  return collection.reduce((a, i) => {
    a.push(i.props);
    return a;
  }, []);
}

function toHead(collection) {
  const title = collection.find((i) => i.type === "title");
  const meta = collection.filter((i) => i.type === "meta");
  const link = collection.filter((i) => i.type === "link");

  return {
    title: title ? title.children : null,
    meta: meta.length ? toProp(meta) : null,
    link: link.length ? toProp(link) : null,
  };
}

function addHead(meta) {
  const { title, meta: _meta_, link } = meta;
  const fragment = document.createDocumentFragment();
  if (title) document.title = title;

  if (_meta_ && _meta_.length) {
    for (const m of _meta_) {
      const props = Object.keys(m);
      // Query element by first attribute and attribute's content
      const element = head.querySelector(`[${props[0]}="${m[props[0]]}"]`);
      if (element) element.content = m.content;
      if (!element) fragment.append(createElement("meta", m));
    }
  }

  if (link && link.length) {
    link.map((l) => {
      const props = Object.keys(l);
      const element = head.querySelector(`[${props[0]}="${l[props[0]]}"]`);
      if (element) element[l[props[1]]] = l.href;
      if (!element) fragment.append(createElement("link", l));
    });
  }

  if (fragment.children.length) head.append(fragment);
}

function createElement(tag, obj) {
  const props = Object.keys(obj);
  const el = document.createElement(tag);
  // add data attribute so we can remove later
  el.setAttribute(DATA_KEY, "");

  for (const prop of props) {
    el.setAttribute(prop, obj[prop]);
  }

  return el;
}

function removeHead() {
  const tags = document.querySelectorAll(`[${DATA_KEY}]`);
  for (const tag of tags) {
    tag.remove();
  }
}

export function useHead(meta) {
  addHead(meta);

  onBeforeUnmount(() => {
    removeHead();
  });
}

export const Head = defineComponent({
  name: "Head",
  // Allow shorthand title assignment
  props: {
    title: String,
  },
  setup(props, { slots }) {
    let obj = null;
    onBeforeUnmount(() => {
      removeHead();
    });

    return () => {
      watchEffect(() => {
        if (!slots.default) return;
        //if (obj) console.log("Remove header", obj);
        if (props && props.title) document.title = props.title;
        obj = ref(toHead(slots.default()));
        if (IS_BROWSER) addHead(obj.value);
      });
      return null;
    };
  },
});
