import { onBeforeUnmount, onMounted } from "vue";

function keyevent(action, options) {
  function onkeyEvent(e) {
    let keycombo;
    if (Array.isArray(options)) keycombo = options.find((k) => k.key === e.key);
    else keycombo = options;

    if (keycombo && keycombo.key === e.key) {
      if (!keycombo.default) e.preventDefault();
      keycombo.callback(e);
    }
  }

  onMounted(() => {
    document.addEventListener(action, onkeyEvent);
  });

  onBeforeUnmount(() => {
    document.removeEventListener(action, onkeyEvent);
  });
}

export function useKeydown(options) {
  keyevent("keydown", options);
}

export function useKeyup(options) {
  keyevent("keyup", options);
}

export function useKeypress(options) {
  keyevent("keypress", options);
}
