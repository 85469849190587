import { computed, onBeforeUnmount, onMounted, ref } from "vue";

export const FOCUS = Object.freeze({
  Up: "up",
  Down: "down",
});

export function useFocus() {
  const focusableElement = ref(null);
  const focusableRef = (element) => (focusableElement.value = element);

  const focusableElements = `button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`;
  const firstFocusable = ref(null);
  const firstFocusableRef = (elements) => (firstFocusable.value = elements[0]);

  const lastFocusable = ref(null);
  const lastFocusableRef = (elements) => (lastFocusable.value = elements[elements.length - 1]);

  const focused = computed({
    get: () => false,
    set(/* Boolean */ value) {
      if (!value) focusableElement.value?.blur();
      else if (value) focusableElement.value?.focus();
    },
  });

  const focus = () => (focused.value = true);

  // Events
  const blurEffect = () => {
    if (focused.value) focused.value = false;
  };
  const focusEffect = () => {
    if (!focused.value) focused.value = true;
  };

  onMounted(() => {
    if (focusableElement.value) {
      focusableElement.value.addEventListener("blur", blurEffect);
      focusableElement.value.addEventListener("focus", focusEffect);
    }
  });
  onBeforeUnmount(() => {
    if (focusableElement.value) {
      focusableElement.value.removeEventListener("blur", blurEffect);
      focusableElement.value.removeEventListener("focus", focusEffect);
    }
  });
  // END

  return {
    focus,
    focused,
    focusableElement,
    focusableRef,
    focusableElements,
    firstFocusable,
    firstFocusableRef,
    lastFocusable,
    lastFocusableRef,
  };
}
