import { isEmpty, isFalse, isTrue } from "@common";
import { useReporter } from "@common/composables";
import { useMarker, usePolicy, useSchema, useUser } from "@composables";
import { computed, ref } from "vue";

import { interviewStatus as STATUS } from "../constants";
import { interviewInteraction } from "../constants";
import { useInterview } from "./api";

const interviewQuestions = ref();
const interview = ref(null);
const interviewId = ref(null);
const interviewStatus = ref();
const flags = ref();

export function useInterviewData() {
  const reporter = useReporter();
  const { marker, setMarker } = useMarker();
  const { user } = useUser();

  async function getInterviewQuestions(apollo) {
    const { queryQuestions } = useInterview(apollo);
    const { payload, success } = await queryQuestions(interviewId.value);
    if (!success.value) {
      reporter.warn("WARNING: Unable to query product questions.");
      return;
    }
    interviewQuestions.value = payload.value.interview.questions;
    const { generateSchema } = useSchema();
    generateSchema(interviewQuestions.value);
  }

  function hasInteractions() {
    return "interactions" in interview.value && interview.value.interactions.length;
  }

  function receivedFinalQuote() {
    if (hasInteractions()) {
      const { interactions } = interview.value;
      return interactions.some(({ type }) => type === interviewInteraction.RECEIVED_FINALIZED_QUOTE);
    }
    return false;
  }

  function enteredPaymentInfo() {
    if (hasInteractions()) {
      const { interactions } = interview.value;
      return interactions.some(({ type }) => type === interviewInteraction.PAYMENT_INFO_ENTERED);
    }
    return false;
  }

  function setInterviewData(data) {
    const { setPolicy } = usePolicy();
    if (!data) return;
    interview.value = data;
    user.value.interview = {
      ...user.value.interview,
      ...interview.value,
    };
    interviewId.value = interview.value.id;
    interviewStatus.value = interview.value.status;
    flags.value = interview.value.flags;
    if (!isEmpty(user.value.policyID)) setPolicy(user.value.interview.policy);
  }

  function quoteStatus() {
    const { status, quote } = interview.value;
    return (
      status === STATUS.READY_FOR_CHECKOUT &&
      marker.value === "quote" &&
      isFalse(quote?.isEstimate) &&
      isFalse(quote?.locked)
    );
  }

  function invalidPaymentStatus(store) {
    const { status, quote } = interview.value;
    const invalidStatus =
      status === STATUS.READY_FOR_CHECKOUT &&
      marker.value === "payment" &&
      isFalse(quote?.isEstimate) &&
      isFalse(quote?.locked) &&
      isFalse(receivedFinalQuote()) &&
      isFalse(enteredPaymentInfo());
    if (invalidStatus) {
      setMarker("quote");
      store.setState("$marker", marker.value);
    }
    return invalidStatus;
  }

  // This might not be needed anymore
  function paymentStatus() {
    return (
      interview.value.status === STATUS.READY_FOR_CHECKOUT &&
      marker.value === "payment" &&
      isTrue(receivedFinalQuote()) &&
      isFalse(enteredPaymentInfo())
    );
  }

  return {
    flags: computed(() => flags.value),
    getInterviewQuestions,
    interview: computed(() => interview.value),
    interviewId: computed(() => interviewId.value),
    interviewQuestions: computed(() => interviewQuestions.value),
    interviewStatus: computed(() => interviewStatus.value),
    invalidPaymentStatus,
    paymentStatus,
    quoteStatus,
    setInterviewData,
  };
}
