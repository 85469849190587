import { routeNames } from "../constants";
import BASICS from "../data/faqs-basics.json";
import MY_POLICY from "../data/faqs-my-policy.json";
import POLICY from "../data/faqs-policy.json";
import PROTECTION from "../data/faqs-protection.json";
import Home from "../pages/Home.vue";

export const marketingRoutes = [
  {
    path: "/",
    name: routeNames.HOME,
    component: Home,
  },
  {
    path: "/why-dayforward",
    name: routeNames.WHY_DAYFORWARD,
    component: () => import(/* webpackChunkName: "why" */ "../pages/WhyDayforward.vue"),
  },
  {
    path: "/about",
    name: routeNames.ABOUT,
    component: () => import(/* webpackChunkName: "about" */ "../pages/About.vue"),
  },
  {
    path: "/faq",
    name: routeNames.FAQ,
    component: () => import(/* webpackChunkName: "faqs" */ "../pages/FAQs.vue"),
    meta: {
      disableScroll: true,
    },
    beforeEnter: (to, from, next) => {
      // @TODO Remove DLIC maps, CTL maps and ctl feature flag after CTL release
      // Map faq slugs to check before enter
      const content = [...BASICS, ...PROTECTION, ...MY_POLICY, ...POLICY];
      const slugs = content.map((faq) => faq.slug);
      if (to.name === routeNames.FAQ_SLUG && !to.params.slug) next({ name: routeNames.FAQ });
      else if (to.name === routeNames.FAQ_SLUG && !slugs.includes(to.params.slug)) next({ name: routeNames.FAQ });
      else next();
    },
    children: [
      {
        path: ":topic/:slug",
        name: routeNames.FAQ_SLUG,
        component: () => import("../pages/v3/MarketingFAQsSlug.vue"),
        meta: {
          disableScroll: true,
        },
      },
    ],
  },
  {
    path: "/careers",
    name: routeNames.CAREERS,
    component: () => import("../pages/Careers.vue"),
  },
  {
    path: "/reviews",
    name: "reviews",
    component: () => import("../pages/Reviews.vue"),
  },
  {
    path: "/terms",
    name: routeNames.TERMS,
    component: () => import("../pages/Terms.vue"),
  },
  {
    path: "/privacy",
    name: routeNames.PRIVACY,
    component: () => import("../pages/Privacy.vue"),
  },
  {
    path: "/contact",
    name: routeNames.CONTACT,
    component: () => import("../pages/Contact.vue"),
  },
  {
    path: "/licenses",
    name: routeNames.LICENSES,
    component: () => import("../pages/Licenses.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    name: routeNames.NOT_FOUND,
    component: () => import("../pages/v3/Marketing404.vue"),
  },
];
