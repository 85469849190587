import { ref } from "vue";

const searching = ref(false);
const searchTerm = ref("");

export function useSearch() {
  return {
    searching,
    searchTerm,
  };
}
