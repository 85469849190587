import { numericMask } from "./numeric.masks.js";

export function fixedMask(value, opts) {
  const addComma = false;
  const maxLength = opts?.maxLen;

  return fixedMaskFormatting(value, opts, addComma, maxLength);
}

export function incomeMask(value, opts) {
  const addComma = true;
  const maxLength = opts?.maxLen;

  return fixedMaskFormatting(value, opts, addComma, maxLength);
}

export function phoneMask(value, opts) {
  const numberOfDigits = 10;
  const delimiters = /(\d{0,3})(\d{0,3})(\d{0,4})/g;

  return maskFormatting(value, opts, numberOfDigits, delimiters);
}

export function ssnMask(value, opts) {
  const numberOfDigits = 9;
  const delimiters = /(\d{0,3})(\d{0,2})(\d{0,4})/g;

  return maskFormatting(value, opts, numberOfDigits, delimiters);
}

export function fixedMaskFormatting(value, opts, addComma, maxLength) {
  let convertToNumber = false;
  if (typeof value === "number") {
    value = String(value);
    convertToNumber = true;
  }
  if (!value || value.trim().length === 0) {
    return { masked: "", clean: "", prioritized: "" };
  }
  let maskedValue = value,
    cleanValue = value;
  if (maxLength > 0 && maskedValue) {
    maskedValue = cleanValue = maskedValue.substring(0, maxLength);
  }

  // limit to two decimal places
  if (opts?.currency && maskedValue !== 0) {
    maskedValue = maskedValue.match(/^\d+\.?\d{0,2}/)[0];
  }

  if (opts?.numeric) {
    maskedValue = numericMask(maskedValue, { addCommas: addComma }).masked;
    if (maskedValue < 0) {
      maskedValue = maskedValue.slice(1);
    }
  }
  if (convertToNumber) {
    maskedValue = Number(maskedValue);
  }
  return { masked: maskedValue, clean: cleanValue, prioritized: cleanValue ?? maskedValue };
}

function maskFormatting(value, opts, numberOfDigits, delimiters) {
  if (!value || value.trim().length === 0) {
    return { masked: "", clean: "", prioritized: "" };
  }
  const numeric = numericMask(value, opts);
  const cleanValue = numeric.clean.substring(0, numberOfDigits);
  const matchResult = [...cleanValue.matchAll(delimiters)];
  let maskedValue = cleanValue;
  if (matchResult && matchResult.length > 1) {
    const filteredMatch = matchResult[0].slice(1).filter((p) => p?.length > 0);
    maskedValue = filteredMatch.join("-");
  }
  return { masked: maskedValue, clean: cleanValue, prioritized: cleanValue ?? maskedValue };
}
