import { computed, ref } from "vue";

const policy = ref(null);

export function usePolicy() {
  function setPolicy(data) {
    policy.value = data;
  }

  return {
    policy: computed(() => policy.value),
    setPolicy,
  };
}
