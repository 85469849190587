import { capitalize } from "./capitalize";
import { replace } from "./replace";
import { toString } from "./toString";
import { words } from "./words";

// /_/g
export function sentenceCase() {
  if (!arguments.length) return "";

  const [string, pattern] = arguments;
  let _words;

  if (!pattern) _words = words(toString(string).toLowerCase());
  else _words = words(replace(toString(string), pattern, " ").toLowerCase());

  if (!_words.length) return "";
  _words[0] = capitalize(_words[0]);
  return _words.join(" ");
}
