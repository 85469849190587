import { routeNames, SLUGS } from "../constants";

export const legacyRoutes = [
  // Legacy help page remove or reroute after launch
  {
    path: "/help",
    name: routeNames.HELP,
    redirect: { name: routeNames.FAQ },
  },
  // Legacy FAQ routes remove or reroute after launch
  {
    path: "/faq/:slug",
    redirect: { name: routeNames.FAQ },
    props: true,
  },
  {
    path: "/faq/the-basics",
    name: "the-basics",
    redirect: { name: routeNames.FAQ },
  },
  {
    path: "/faq/income-protection",
    name: "income-protection",
    redirect: { name: routeNames.FAQ },
  },
  {
    path: "/faq/getting-a-policy",
    name: "getting-a-policy",
    redirect: { name: routeNames.FAQ },
  },
  {
    path: "/faq/my-policy",
    name: "my-policy",
    redirect: { name: routeNames.FAQ },
  },
  {
    path: "/quote-com-landing",
    name: "360-landing",
    redirect: { name: routeNames.Q_SLUG, params: { slug: SLUGS.dob } },
    meta: {
      doNotTrack: true,
    },
  },
  {
    path: "/quote-com-confirmation",
    name: "quote-com-accepted",
    redirect: { name: routeNames.Q_SLUG, params: { slug: SLUGS.dob } },
    meta: {
      doNotTrack: true,
    },
  },
  {
    path: "/quote-com-next-steps",
    name: "quote-com-rejected",
    redirect: { name: routeNames.Q_SLUG, params: { slug: SLUGS.dob } },
    meta: {
      doNotTrack: true,
    },
  },
  {
    path: "/intro", // @TODO Is this still a needed route?
    name: "prequal",
    redirect: { name: routeNames.Q_SLUG, params: { slug: SLUGS.dob } },
    meta: {
      doNotTrack: true,
    },
  },
  {
    path: "/intro/next-steps", // @TODO Is this still a needed route?
    name: "prequal-denied",
    redirect: { name: routeNames.Q_SLUG, params: { slug: SLUGS.dob } },
    meta: {
      doNotTrack: true,
    },
  },
  {
    path: "/lead-capture",
    name: "lead-capture",
    component: () => import("../pages/legacy/LeadCapture.vue"),
  },
  {
    path: "/lead-capture-confirmation",
    name: "lead-capture-accepted",
    component: () => import("../pages/legacy/Page.vue"),
    props: {
      slug: "lead-capture-confirmation",
    },
  },
  {
    path: "/lead-capture-next-steps",
    name: "lead-capture-rejected",
    component: () => import("../pages/legacy/Page.vue"),
    props: {
      slug: "lead-capture-next-steps",
    },
  },
];
