<!-- eslint-disable prettier/prettier -->
<template>
 <ul ref="marqueeRef" :class="$style.marquee">
  <slot />
 </ul>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";

const marqueeRef = ref(null);
let frame;

function animate() {
  if (!marqueeRef.value) return;
  for (const child of marqueeRef.value.children) {
    const rects = child.getBoundingClientRect();
    if (rects.left < 0 || rects.left > window.innerWidth - rects.width / 2) child.setAttribute("aria-hidden", true);
    else child.removeAttribute("aria-hidden");
  }

  frame = requestAnimationFrame(animate);
}

onMounted(() => {
  frame = requestAnimationFrame(animate);
  //console.log("Width", marqueeRef.value.getBoundingClientRect().width);
});

onUnmounted(() => {
  cancelAnimationFrame(frame);
});
</script>
<style lang="scss" module>
.marquee {
  position: relative;
  display: flex;
  align-self: start;
  width: 5285px;
  margin-top: calc(-256px / 1.8);
  margin-bottom: calc(-256px / 2);
  transform: translate3d(0, 0, 0);
  animation: marquee 100000ms linear infinite none;
  animation-play-state: running;
  list-style: none;
  will-change: transform;
  &:hover {
    animation-play-state: paused;
  }
}

@media (min-width: 463px) {
  .marquee {
    margin-top: calc(-256px / 1.4);
    margin-bottom: calc(-256px / 3);
  }
}
@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
</style>
