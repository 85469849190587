<template>
  <component
    :is="as"
    :class="[
      $style.link,
      { [$style.content]: content },
      { [$style[size]]: size },
      { [$style.nav]: nav },
      { [$style.tab]: tab },
      { [$style.light]: light },
      { [$style[variant]]: variant },
      { [$style.underline]: underline },
    ]"
    :active-class="$style.active"
    :exact-active-class="$style.exact"
    v-bind="$attrs"
  >
    <slot />
  </component>
</template>

<script setup>
import { computed, useAttrs } from "vue";

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  variant: {
    type: String,
    validator: (prop) => ["secondary"].includes(prop),
  },
  size: {
    type: String,
    validator: (prop) => ["sm", "lg"].includes(prop),
  },
  content: Boolean,
  nav: Boolean,
  tab: Boolean,
  light: Boolean,
  underline: Boolean,
});

const attrs = useAttrs();
const as = computed(() => (attrs.to ? "router-link" : "a"));
</script>

<style lang="scss" module>
.link {
  width: fit-content;
  text-decoration: none;
}

.content {
  position: relative;
  color: var(--base-color-primary, #e33031);
  outline: none;
  border-bottom: none !important; // @TODO remove after refactor
  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 0;
    height: 1px;
    background: currentColor;
    transition: width 300ms ease-in-out, left 300ms ease-in-out;
  }
  &:hover:after,
  &:focus-visible:after {
    width: 100%;
    left: 0;
  }
}

.nav {
  height: 1.4em;
  font-size: 0.88em;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: -0.6336px;
}

.tab {
  color: var(--base-color-secondary, #1a113c);
  padding: 6px 12px;
  border-bottom: transparent solid 3px;
  &:hover {
    color: var(--base-color-primary, #e33031);
    border-bottom: transparent solid 3px;
  }
  &.exact {
    color: var(--base-color-primary, #e33031);
    border-bottom-color: var(--base-color-primary, #e33031);
  }
}

.light {
  color: var(--oat, #f2ede0);
  border: 0 !important; // Need to override default a tag styling Uggg...
  transition: opacity 230ms ease-in-out;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
    border-bottom: 0 !important; // Need to override default a tag styling Uggg...
  }
}

.sm {
  font-size: 0.778rem;
}

.secondary {
  color: var(--midnight, #1a113c);
}

.underline {
  text-decoration: underline;
  &:after {
    display: none;
  }
}
</style>
