import flagsmith from "flagsmith";
import { uuid } from "@common";
import { useFlagsmithStore } from "../store/flagsmith.store";
import { STORAGE_KEYS, useLocalStorage } from "./storage";
import { shallowRef } from "vue";
import { useRoute } from "vue-router";

const _uuid = shallowRef(null);

export function useFlagsmith() {
  const route = useRoute();
  const flagsmithStore = useFlagsmithStore();
  const { set, get } = useLocalStorage();

  function hasFeature(value) {
    return flagsmithStore.features?.hasFeature(value);
  }

  function setTraits() {
    const source = flagsmith.getTrait("source");
    const campaign = flagsmith.getTrait("campaign")
    const traits = {
      source: route.query.utm_source ? route.query.utm_source : source ? source : '',
      campaign: route.query.utm_campaign ? route.query.utm_campaign : campaign ? campaign : '',
    };
    flagsmith.setTraits(traits);
  }

  function identify() {
    _uuid.value = get(STORAGE_KEYS.FS_UUID);
    if (!_uuid.value) {
      _uuid.value = uuid();
      set(STORAGE_KEYS.FS_UUID, _uuid.value);
    }
    // route.query empty object without timeout
    const timer = setTimeout(() => {
      flagsmith.identify(`${_uuid.value}`);
      setTraits();
      clearTimeout(timer);
    }, 0)
  }

  return {
    hasFeature,
    identify
  };
}
