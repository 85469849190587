import { v4 as uuidv4 } from "uuid";

import { handleErrorOrData } from "./errors/error_result_handler.js";
import { AnalyticsError } from "./errors/errors.js";
import { identifytMutation, trackEventMutation, trackPageMutation } from "./graphql/analytics/track.mutations.js";

export class AnalyticsApi {
  constructor(client) {
    this.client = client;
  }

  get currentUserID() {
    let userID;
    const cookie = document.cookie;
    if (cookie) {
      const cookieSplit = cookie.split(`;`);
      const found = cookieSplit.filter((u) => u.trim().startsWith("analytics:userid"));
      if (found.length > 0) {
        const foundSplit = found[0]?.trim().split("=");
        if (foundSplit && foundSplit.length === 2) {
          userID = foundSplit[1];
        }
      }
    }
    if (!userID) {
      userID = uuidv4();
      let domain = "dayforward.com";
      let extraFlags = "; secure";
      if (process.env.VUE_APP_RELEASE_STAGE === "local") {
        domain = "localhost";
        extraFlags = "";
      }
      document.cookie = `analytics:userid=${userID}; expires=Fri, 31 Dec 9999 23:59:59 GMT; domain=${domain}; path=/; samesite=strict${extraFlags}`;
    }
    return userID;
  }

  resetUserID() {
    const userID = uuidv4();
    let domain = "dayforward.com";
    let extraFlags = "; secure";
    if (process.env.VUE_APP_RELEASE_STAGE === "local") {
      domain = "localhost";
      extraFlags = "";
    }
    document.cookie = `analytics:userid=${userID}; expires=Fri, 31 Dec 9999 23:59:59 GMT; domain=${domain}}; path=/; samesite=strict${extraFlags}`;
  }

  async track(event, data) {
    if (!event && !data) {
      throw new Error("payload is missing 'event' and/or 'data'");
    }
    const trackingEvent = {
      userID: this.currentUserID,
      event: event,
      source: "website",
      url: window.location.href,
      properties: data?.properties,
    };
    await this.client.mutate({
      mutation: trackEventMutation,
      variables: {
        trackingEvent,
      },
    });
  }

  async identify(traits) {
    const identity = {
      userID: this.currentUserID,
      traits: traits,
    };
    await this.client.mutate({
      mutation: identifytMutation,
      variables: {
        identity,
      },
    });
  }

  async page(data) {
    const pageView = {
      userID: this.currentUserID,
      source: "website",
      url: window.location.href,
      properties: data,
    };
    try {
      const { data, error } = await this.client.mutate({
        mutation: trackPageMutation,
        variables: {
          pageView,
        },
      });
      return handleErrorOrData(data, error, function (data, error) {
        return { data, error };
      });
    } catch (e) {
      return {
        data: undefined,
        error: new AnalyticsError("Error saving page view", e),
      };
    }
  }
}
