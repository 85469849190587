import { inject, reactive } from "vue";

const ResponsiveManagerKey = "ResponsiveManagerKey";

export class Breakpoint {
  constructor(name, minPixels, weight) {
    this.name = name;
    this.minPixels = minPixels;
    this.weight = weight;
  }

  get deviceName() {
    if (this.name === "xs" || this.name === "sm") return "phone";
    else if (this.name === "md") return "tablet";
    else return "desktop";
  }
}

export class ResponsiveManager {
  constructor() {
    this.currentBreakpoint = undefined;
    this.matchList = {};
  }

  init() {
    this._configureMediaQueryLists();
    this._updateCurrentBreakpoint();
  }

  _updateCurrentBreakpoint() {
    for (const matchEntry of Object.entries(this.matchList)) {
      if (matchEntry[1].matches) {
        this.currentBreakpoint = this.bp(matchEntry[0]);
      }
    }
  }

  _configureMediaQueryLists() {
    AllBreakpoints.forEach((bp) => {
      const match = window.matchMedia(`(min-width: ${bp.minPixels}px)`);
      match.addEventListener("change", () => {
        this._updateCurrentBreakpoint();
      });
      this.matchList[bp.name] = match;
    });
  }

  bp(name) {
    return AllBreakpoints.find((bp) => bp.name === name);
  }

  nextBreakpoint(bp) {
    return AllBreakpoints.find((nbp) => nbp.weight === bp.weight + 1) ?? undefined;
  }

  previousBreakpoint(bp) {
    return AllBreakpoints.find((nbp) => nbp.weight === bp.weight - 1) ?? undefined;
  }

  weight(name) {
    return AllBreakpoints.find((bp) => bp.name === name)?.weight ?? -1;
  }

  gt(name) {
    if (!this.currentBreakpoint) {
      return false;
    }
    return this.currentBreakpoint.weight > this.weight(name);
  }

  gte(name) {
    if (!this.currentBreakpoint) {
      return false;
    }
    return this.currentBreakpoint.weight >= this.weight(name);
  }

  lt(name) {
    if (!this.currentBreakpoint) {
      return false;
    }
    return this.currentBreakpoint.weight < this.weight(name);
  }

  lte(name) {
    if (!this.currentBreakpoint) {
      return false;
    }
    return this.currentBreakpoint.weight <= this.weight(name);
  }

  val(defaultVal, ...vals) {
    if (!this.currentBreakpoint || this.currentBreakpoint.weight === 0) {
      return defaultVal;
    }
    return vals[this.currentBreakpoint.weight] || vals[vals.length - 1] || defaultVal;
  }
}

export function useMq() {
  return inject(ResponsiveManagerKey);
}

export const ResponsivePlugin = {
  install: (app) => {
    const manager = reactive(new ResponsiveManager());
    manager.init();
    app.provide(ResponsiveManagerKey, manager);
    app.config.globalProperties.$mq = manager;
  },
};

export const AllBreakpoints = [
  new Breakpoint("xs", 0, 0),
  new Breakpoint("sm", 640, 1),
  new Breakpoint("md", 768, 2),
  new Breakpoint("lg", 1024, 3),
  new Breakpoint("xl", 1280, 4),
];
