import { onBeforeUnmount, onMounted, ref } from "vue";

const offCanvas = ref(false);

export function useDrawer() {
  const canvasClassEffect = () => {
    if (window.innerWidth > 991) offCanvas.value = false;
  };

  onMounted(() => {
    window.addEventListener("resize", canvasClassEffect);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", canvasClassEffect);
  });

  return {
    offCanvas,
  };
}
