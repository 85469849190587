import { findByKey } from "@common";
import { isNull } from "@common";
import { useReporter } from "@common/composables";
import { useInterview } from "@composables";
import { computed, ref } from "vue";

const marker = ref(null);

export function useMarker() {
  const reporter = useReporter();

  async function getMarker(apollo, id) {
    const { queryInterviewMarker } = useInterview(apollo);
    const { payload, success } = await queryInterviewMarker(id, "last-step-completed");
    if (!success.value || isNull(payload.value.getInterviewMarkerByName)) {
      reporter.warn(`Marker missing, set to initial pre-quote step`);
      marker.value = "quote";
      return;
    }
    const metadata = payload.value.getInterviewMarkerByName.metadata;
    marker.value = findByKey("key", "step", metadata).value;
    reporter.info(`Marker present: ${marker.value}`);
  }

  function setMarker(value) {
    marker.value = value;
  }

  // Temporary method - remove after conversion from stores to composables
  function setMarkerInInterviewStore(store) {
    store.setState("$marker", marker.value);
  }
  // END

  return {
    getMarker,
    marker: computed(() => marker.value),
    setMarker,
    setMarkerInInterviewStore,
  };
}
