import { SavedStateKeys } from "../../config";

export const leadModule = {
	namespaced: true,
	state: () => ({
		answerSet: {},
	}),
	getters: {
		userId() {
			const out = document.cookie
				.split("; ")
				.find((row) => row.startsWith("analytics:userid="))
				.split("=")[1];

			return out;
		},
	},
	mutations: {
		SET_ANSWERS(state, answers) {
			state.answerSet = { ...state.answerSet, ...answers };
		},
	},
	actions: {
		persistLocalState({ state }) {
			window.sessionStorage.setItem(SavedStateKeys.INTERVIEW_QUESTIONS, JSON.stringify(state.answerSet));
		},
		restoreLocalState({ commit }) {
			const cachedInputsJSON = window.sessionStorage.getItem(SavedStateKeys.INTERVIEW_QUESTIONS);
			const answers = cachedInputsJSON ? JSON.parse(cachedInputsJSON) : {};
			commit("SET_ANSWERS", answers);
		},
		putAnswers({ commit, dispatch }, answers) {
			if (!answers) {
				throw new Error("answers value is invalid type or empty");
			}
			commit("SET_ANSWERS", answers);
			dispatch("persistLocalState");
		},
	},
};
