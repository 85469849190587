/**
 * If binding value is true or undefined focus input
 * If binding value is false blur the input
 * @param {boolean} binding value is true, false or undefined
 */
export const vFocus = {
  mounted(element, binding) {
    const tag = element.tagName;
    if (tag !== "INPUT") element = element.querySelector("INPUT");
    if (binding.value || binding.value === undefined) element.focus();
    if (!binding.value) element.blur();
  },
};
