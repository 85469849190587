import flagsmith from "flagsmith";
import { DateTime } from "luxon";
import { ref } from "vue";

import { DATE_INPUTS, EVENTS, PHONE_FIELD, TEXT_INPUTS } from "../types";
import { parseDate, pluralize } from "../utils";
import { isEmail, isPhone } from "../validation";
import { useErrors } from "./errors";
import { useReporter } from "./useReporter";

export function useValidation() {
  const reporter = useReporter();
  const { errors, hasError, removeError, setError } = useErrors();

  let typing = null;
  const duration = 300;

  const internalProps = ref(null);
  const internalTrigger = ref(null);
  const internalValue = ref("");
  const isRequired = ref(false);
  const isDirty = ref(false);
  const target = ref(null);

  function _errorRequired(id) {
    if (errors.value[id]) return errors.value[id].includes("required");
    return null;
  }

  // Interval needs to be refactored to validateFullDate;
  function _validateFullDate() {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY format
    const id = target.value.id;
    if (isDirty.value && internalTrigger.value === EVENTS.Blur && !regex.test(internalValue.value)) {
      setError(id, "Date must be MM/DD/YYYY format.");
      reporter.error("Date must be MM/DD/YYYY format.");
    }
    if (regex.test(internalValue.value) && hasError(id)) {
      removeError(id);
    }
  }
  // Export in return
  function validateFullDate(key, value) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY format
    const valid = regex.test(value);
    if (!valid) {
      setError(key, "Date must be MM/DD/YYYY format.");
      reporter.error("Date must be MM/DD/YYYY format.");
    }
    if (valid && hasError(key)) removeError(key);
    return { valid };
  }

  // Interv
  function _validateShortDate() {
    const regex = /^\d{2}\/\d{4}$/; // MM/YYYY format
    const id = target.value.id;
    const valid = regex.test(internalValue.value);
    if (isDirty.value && internalTrigger.value === EVENTS.Blur && !valid) {
      setError(id, "Date must be MM/YYYY format.");
      reporter.error("Date must be MM/YYYY format.");
    }

    if (valid && hasError(id)) {
      removeError(id);
    }
  }

  function validateShortDate(key, value) {
    const regex = /^\d{2}\/\d{4}$/; // MM/YYYY format
    const valid = regex.test(value);
    if (!valid) {
      setError(key, "Date must be MM/YYYY format.");
      reporter.error("Date must be MM/YYYY format.");
    }
    if (valid && hasError(key)) removeError(key);
    return { valid };
  }

  function validateDOB(value, threshold = null) {
    const dob = DateTime.fromJSDate(new Date(value));
    const now = DateTime.now();
    const diff = now.diff(dob, "years").toObject();
    const upperThreshold = flagsmith.hasFeature("uw_age_expansion") ? 60 : 50;
    const defaultValue = { min: 18, max: upperThreshold };
    if (threshold === "upper" && diff.years > upperThreshold) return { ...defaultValue, valid: false };
    if (threshold === "lower" && diff.years < 18) return { ...defaultValue, valid: false };
    if (!threshold && (diff.years < 18 || diff.years > upperThreshold)) return { ...defaultValue, valid: false };
    return { ...defaultValue, valid: true };
  }

  const validateAge = (key, value) => {
    const validLength = value.length > 1;
    const upperThreshold = flagsmith.hasFeature("uw_age_expansion") ? 60 : 50;
    const belowThreshold = Number(value) < 18;
    const overThreshold = Number(value) > upperThreshold;
    if (validLength && belowThreshold) setError(key, "Must be 18 years or older.");
    if (validLength && overThreshold) setError(key, `Must be ${upperThreshold} years old or younger.`);
    if (hasError(key) && !belowThreshold && !overThreshold) removeError(key);
    if (belowThreshold || overThreshold) return { valid: false };
    return { valid: true };
  };

  const THRESHOLD_TYPES = Object.freeze({
    above: "ABOVE",
    below: "BELOW",
    within: "WITHIN",
  });

  // @TODO Refactor date of birth methods to use birth bounds
  // function withinBirthBounds(value) {
  // }

  // @TODO add min max threshold ability
  function validateDateThreshold(value, threshold) {
    const date = parseDate(value);
    const now = DateTime.now();
    const { years } = now.diff(date, "years").toObject();
    const { months } = date.diff(now, "months").toObject();
    const defaultDiffs = { years, months };

    if (years < -0.1) return { type: THRESHOLD_TYPES.above, valid: false, ...defaultDiffs };
    if (years > threshold) return { type: THRESHOLD_TYPES.below, valid: false, ...defaultDiffs };
    return { type: THRESHOLD_TYPES.within, valid: true, ...defaultDiffs };
  }

  function validateChildDOB(key, value, expecting = false) {
    if (value && value.length === 7) {
      const { valid, type, months } = validateDateThreshold(value, 21);
      if (!valid && type === THRESHOLD_TYPES.above && !expecting) {
        setError(key, "This date is in the future.");
        reporter.error(`${key}: This date is in the future.`);
      }
      if (!valid && type === THRESHOLD_TYPES.below) {
        setError(key, "Your youngest child must be less than 21 years old.");
        reporter.error(`${key}: Your youngest child must be less than 21 years old.`);
      }
      if (expecting) {
        const limit = 10;
        if (months > limit) {
          const diff = Math.ceil(months - limit);
          if (diff < 12) {
            setError(key, `This date is ${diff} ${pluralize("month", "months", diff)} too far in the future.`);
            reporter.error(`${key}: This date is ${diff} ${pluralize("month", "months", diff)} too far in the future.`);
          } else {
            setError(key, `This date is too far in the future.`);
            reporter.error(`${key}: This date is too far in the future.`);
          }
        }
        if (type !== THRESHOLD_TYPES.below && months < 9) removeError(key);
      }
      if (valid && hasError(key)) removeError(key);
      return { valid, max: 21 };
    }
  }

  const validateChildAge = (key, value) => {
    const upperThreshold = 21;
    const overThreshold = Number(value) > upperThreshold;
    if (overThreshold) setError(key, `Must be ${upperThreshold} years old or younger.`);
    if (hasError(key) && !overThreshold) removeError(key);
    if (overThreshold) return { valid: false };
    return { valid: true };
  };

  function validEmailMatch(value, match) {
    return value.toLowerCase() === match.toLowerCase();
  }

  function confirmEmail(value, match) {
    clearTimeout(typing);
    typing = setTimeout(() => {
      if (isEmail(value) && isEmail(match)) {
        const valid = validEmailMatch(value, match);
        if (!valid) setError("CONFIRM_EMAIL", "Emails do not match");
        if (valid) removeError("CONFIRM_EMAIL");
        clearTimeout(typing);
      }
    }, duration);
  }

  function validateRequired() {
    if (internalTrigger.value === EVENTS.Input && internalValue.value.length) {
      if (_errorRequired(target.value.id)) removeError(target.value.id);
      if (internalProps.value && internalProps.value.errorKey && _errorRequired(internalProps.value.errorKey))
        removeError(internalProps.value.errorKey);
    }
    if (internalTrigger.value === EVENTS.Blur && isDirty.value && !internalValue.value.length) {
      setError(target.value.id, `This field is required.`);
      reporter.error(`This field is required.`);
    }
  }

  function validateDate(mode) {
    const { value } = target.value;
    if (mode === DATE_INPUTS.Date && value.length) _validateFullDate();
    if (mode === DATE_INPUTS.Month && value.length) _validateShortDate();
  }

  function validateBefore(date1, date2) {
    const d1 = parseDate(date1);
    const d2 = parseDate(date2);
    const diff = d1.diff(d2).toObject();
    return diff.milliseconds >= 0;
  }

  function validateEmail() {
    if (internalTrigger.value === EVENTS.Blur && internalValue.value.length && !isEmail(internalValue.value)) {
      setError(target.value.id, `Please enter a valid email.`);
      reporter.error(`Please enter a valid email.`);
    }
    if (isEmail(internalValue.value)) removeError(target.value.id);
  }

  function validatePhone() {
    const valid = isPhone(internalValue.value);
    if (internalTrigger.value === EVENTS.Blur && internalValue.value.length && !valid) {
      setError(target.value.id, `Please provide a valid US phone number.`);
      reporter.error(`Please provide a valid US phone number.`);
    }
    if (valid) {
      if (hasError(target.value.id)) removeError(target.value.id);
      if (hasError(internalProps.value.errorKey)) removeError(internalProps.value.errorKey);
    }
  }

  function validate(event, dirty, props) {
    target.value = event.target;
    internalProps.value = props;
    isRequired.value = target.value.required;
    internalTrigger.value = event.type;
    isDirty.value = dirty.value;
    internalValue.value = target.value.value;

    const type = target.value.getAttribute("type");
    const mode = target.value.getAttribute("data-date");
    if (isRequired.value) validateRequired();
    if (type === TEXT_INPUTS.Email) validateEmail();
    if (type === PHONE_FIELD) validatePhone();
    if (mode) validateDate(mode); // Date format validation
  }

  return {
    validateBefore,
    confirmEmail,
    validate,
    validateChildDOB,
    validateChildAge,
    validateDOB,
    validateAge,
    validateDateThreshold,
    validateFullDate,
    validateShortDate,
  };
}
