<template>
  <BaseContent>
    <Head>
      <title>Life Insurance That Guarantees Your Income | Dayforward</title>
      <meta name="description" :content="metadescription" />
      <meta property="og:description" :content="metadescription" />
      <meta name="twitter:description" :content="metadescription" />
      <meta itemprop="description" :content="metadescription" />
      <meta name="robot" content="index follow" />
      <link rel="canonical" href="https://www.dayforward.com" />
    </Head>
    <component :is="Home" />
  </BaseContent>
</template>

<script setup>
import BaseContent from "@components/BaseContent.vue";

import Home from "./v3/MarketingHome.vue";

const metadescription =
  "Get life insurance that guarantees your income so your family has financial security after you're gone. Buy online and get covered as soon as today.";
</script>
