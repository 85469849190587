import { inject } from "vue";

import { NoOpAnalyticsReporter, SegmentReporter } from "../services/analytics";

export const AnalyticsReporterKey = "analyticsReporter";
export const AnalyticsProviderKey = "analyticsProvider";

export function useAnalytics() {
  return inject(AnalyticsReporterKey);
}

export const AnalyticsPlugin = {
  install: (app, options) => {
    let reporter = new NoOpAnalyticsReporter();
    let provider = "noop";
    if (options) {
      provider = options.provider || "noop";
      if (provider === "segment" && options.settings) {
        reporter = new SegmentReporter(options).init();
      }
    }
    if (!reporter) {
      throw new Error("Analytics was not configured correctly");
    }
    app.provide(AnalyticsReporterKey, reporter);
    app.provide(AnalyticsProviderKey, provider);
    app.config.globalProperties.$analytics = reporter;
  },
};
