import { ref } from "vue";

export function useLayouts(quote, display = null) {
  const layout = ref(null);

  const layouts = ["Standard", "Single", "Household", "High"];

  if (quote) {
    const { benefitType } = quote;
    const isHousehold = benefitType === "EXPENSE_COVERAGE";
    const naarTriggered = quote.naarValue > quote.naarThreshold;
    if (display === "SINGLE_VIEW") layout.value = layouts.at(1);
    else layout.value = isHousehold ? layouts.at(2) : naarTriggered ? layouts.at(3) : layouts.at(0);
  }
  return {
    layout,
  };
}
