import { ref, shallowRef } from "vue";

import Funnel from "../data/funnel.json"; // Local funnel
import Questions from "../data/questions.json";
import { useExperiment } from "./experiment";

const traversable = ref(null);

export function useTraversables() {
  const traversables = shallowRef([...Questions]);
  const graph = new Map();
  const { isVariant } = useExperiment();

  const traverse = (start, index, decision) => {
    const path = graph.get(start); // Returns [[...], [...]]
    const to = path.at(index); // Get array at index from path
    const traverseIndex = to.length === 1 || !decision ? 0 : 1;
    return to.at(traverseIndex); //
  };

  const traverseTo = (start, decision) => traverse(start, 0, decision); // to: [][0]
  const traverseFrom = (start, decision) => traverse(start, 1, decision); // from: [][1]

  const incoming = (node) => node.at(1); // incoming connections
  const indegree = (node) => incoming(node).length; // number of incoming commentions
  const outgoing = (node) => node.at(0); // outgoing connections
  const outdegree = (node) => outgoing(node).length; // number of outgoing connections

  const toNode = (source) => graph.set(source, [[], []]); // to: [][0] from: [][1]
  const toEdges = (origin, destination) => {
    // @TODO Remove: temporary logic to prevent building edges based on ab variant
    // Once this is removed remove the appropriate edges from funnel.json
    if (!isVariant() && origin === "build") return;
    if (origin === "children" || origin === "youngest") {
      if (isVariant() && destination === "estimate") return;
      if (!isVariant() && destination === "build") return;
    }
    //

    graph.get(origin).at(0).push(destination);
    if (!graph.get(destination)) return;
    graph.get(destination).at(1).push(origin);
  };

  // @TODO Clean up so that this initilizes once
  const [slugs, connections] = Funnel;
  slugs.split(" ").forEach((s) => toNode(s));
  connections.forEach((c) => toEdges(...c));

  return {
    graph,
    traversable,
    traversables,
    incoming,
    indegree,
    outdegree,
    outgoing,
    traverseFrom,
    traverseTo,
  };
}
