// import flagsmith from "flagsmith";
import { ref } from "vue";

const supportedStates = ref([]);

export function useStates() {
  const distinctiveState = ["ME", "VT"];
  const distinctive = (value) => distinctiveState.includes(value);

  // All states are now CTL excluding legacy interviews
  const isCTL = () => true;

  return {
    supportedStates,
    distinctive,
    isCTL,
  };
}
