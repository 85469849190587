import { DATE_INPUTS } from "../../types/inputs.js";
import { numericMask } from "./numeric.masks.js";
export function dateMask(value, options) {
  const mode = options?.mode ?? DATE_INPUTS.Date;
  const parts = [];

  const numericResult = numericMask(value);
  if (numericResult.masked === "") {
    return { masked: "", clean: "" };
  }

  const maxLen = mode === DATE_INPUTS.Date ? 8 : 6;
  const numVal = numericResult.masked.substring(0, maxLen);
  let masked, clean;
  // month
  const firstMonthDigit = parseInt(numVal[0]);
  parts[0] = firstMonthDigit;
  if (firstMonthDigit >= 2) {
    parts[0] = `0${firstMonthDigit}`;
  } else if (parseInt(numVal.substring(0, 2)) <= 12) {
    parts[0] = numVal.substring(0, 2);
  }
  // day
  if (mode === DATE_INPUTS.Date) {
    if (parts[0].length === 2 && numVal.length >= 3) {
      const monthNum = parseInt(parts[0]),
        maxMonthDays = maxDays(monthNum);
      const firstDayDigit = parseInt(numVal[2]);
      if (firstDayDigit > 3) {
        parts[1] = `0${firstDayDigit}`;
      } else {
        if (parseInt(parts[0]) === 2 && firstDayDigit > 2) {
          parts[1] = "";
        } else {
          const enteredDay = numVal.substring(2, 4);
          parts[1] = parseInt(enteredDay) <= maxMonthDays ? enteredDay : firstDayDigit;
        }
      }
    }
  }

  // year
  const yearIndex = mode === DATE_INPUTS.Date ? 4 : 2;
  parts[parts.length] = numVal.substring(yearIndex, yearIndex + 4);

  if (parts.length === 2) {
    clean = `${cleanPart(parts[0])}${cleanPart(parts[1])}`;
    masked = `${cleanPart(parts[0])}${parts[0]?.length === 2 ? "/" : ""}` + `${cleanPart(parts[1])}`;
  } else {
    clean = `${cleanPart(parts[0])}${cleanPart(parts[1])}${cleanPart(parts[2])}`;
    masked =
      `${cleanPart(parts[0])}${parts[0]?.length === 2 ? "/" : ""}` +
      `${cleanPart(parts[1])}${parts[1]?.length === 2 ? "/" : ""}` +
      `${cleanPart(parts[2])}`;
  }
  return { masked, clean };
}

function maxDays(month) {
  if (month === 2) {
    return 29;
  } else if ([9, 4, 6, 11].indexOf(month) !== -1) {
    return 30;
  } else {
    return 31;
  }
}

function cleanPart(part) {
  if (!part) {
    return "";
  }
  return part;
}
