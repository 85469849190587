<template>
  <BaseSection data-analytics-region="get_covered">
    <BaseContainer grid padding>
      <BaseBox span :class="benefits['benefit__header']">
        <BaseTitle as="h2" :margin="{ bottom: 'default' }">Get covered as soon as today!</BaseTitle>
      </BaseBox>
      <BaseList as="ol" unstyled :class="[$style.stretch, $style.ol]">
        <BaseListItem :class="[$style.li]">
          <BaseBox>
            <BaseTitle as="h3" :class="[benefits['header-h4']]">Apply in minutes</BaseTitle>
            <BaseText>See your personalized coverage and pricing options.</BaseText>
          </BaseBox>
        </BaseListItem>
        <BaseListItem :class="[$style.li]">
          <BaseBox>
            <BaseTitle as="h3" :class="[benefits['header-h4']]">Select coverage</BaseTitle>
            <BaseText>Choose the best coverage for your family and submit your app.</BaseText>
          </BaseBox>
        </BaseListItem>
        <BaseListItem :class="[$style.li]">
          <BaseBox>
            <BaseTitle as="h3" :class="[benefits['header-h4']]">Buy your policy</BaseTitle>
            <BaseText>If approved, we’ll issue your policy to make it official.</BaseText>
          </BaseBox>
        </BaseListItem>
      </BaseList>
      <BaseBox :class="[$style['md:show'], $style.center]" span>
        <BaseButton
          data-analytics="get_started"
          variant="primary"
          :to="{ name: routeNames.Q_SLUG, params: { slug: SLUGS.dob }}"
          @click="trackEvent"
        >
          Get Started
        </BaseButton>
      </BaseBox>
    </BaseContainer>
  </BaseSection>
</template>

<script setup>
import { useAnalytics } from "@common";
import BaseButton from "@common/components/BaseButton.vue";
import BaseList from "@common/components/BaseList.vue";
import BaseListItem from "@common/components/BaseListItem.vue";
import BaseText from "@common/components/BaseText.vue";
import BaseBox from "@components/BaseBox.vue";
import BaseContainer from "@components/BaseContainer.vue";
import BaseSection from "@components/BaseSection.vue";
import BaseTitle from "@components/BaseTitle.vue";
import { useExperiment } from "@composables";
import { useRoute, useRouter } from "vue-router";

import { routeNames, SLUGS } from "../constants";

// Analytics
const analytics = useAnalytics();
const route = useRoute();
const router = useRouter();
const { bucket } = useExperiment();
function trackEvent(e) {
  const target = e.target.closest("a") || e.target.closest("button");
  if (!target) return;
  const event = target.getAttribute("data-analytics");
  const parent = target.closest("[data-analytics-region]");
  const current = router.options.history.state.current;
  const page = current === "/" ? "homepage" : current.substring(1);
  const region = parent ? parent.getAttribute("data-analytics-region") : null;
  const key = `app_click_${event}`;
  analytics.trackEvent(key, {
    button_location: region,
    page,
    region,
    url: route.path,
    experiment: bucket.value,
  });
}
// END
</script>

<style lang="scss" module>
@media (max-width: 991px) {
  .md\:show {
    display: none;
  }
}

.stretch {
  grid-column: 1 / -1;
}

.faqs {
  max-width: 1024px;
}

.center {
  text-align: center;
}

.google {
  margin: 0 0 var(--space-sm) 0;
}

.panel,
.family {
  max-width: 1700px;
}

.panel {
  background: var(--geranium);
  color: var(--oat);
  min-height: 390px;
  padding: var(--space-lg);
}

.family {
  display: block;
  min-height: 300px;
}
.family__img {
  min-height: 300px;
}

.icon {
  display: block;
  margin: 0 auto;
  transform: translateY(50%);
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  opacity: 0;
}

.oncanvas {
  opacity: 1;
  transform: translateY(0);
}

.ol {
  counter-reset: list-number;
  display: grid;
  gap: var(--space-default);
}

.li {
  counter-increment: list-number;
  display: grid;
  grid-template-columns: 44px 1fr;
  &:before {
    content: counter(list-number);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--geranium);
    font-size: var(--base-text-xxl);
    font-weight: 600;
    border-right: rgba(var(--base-color-secondary-rgb), 0.3) solid 1px;
    padding-right: var(--space-sm);
    margin-right: var(--space-sm);
  }
}

.panel__title {
  margin-bottom: var(--space-xxs);
}

.copy {
  padding-bottom: var(--space-lg);
}

.top {
  margin-bottom: var(--space-xl);
  padding-bottom: var(--space-xl);
  border-bottom: rgba(var(--base-color-secondary-rgb), 0.3) solid 1px;
}

.img {
  object-fit: cover;
  height: 100%;
}

.scaled {
  transform: scale(1.3);
}

@media (min-width: 700px) {
  .panel__title {
    margin-bottom: 0;
  }
}

@media (min-width: 756px) {
  .icon {
    margin-bottom: var(--space-default);
  }

  .ol {
    gap: var(--space-48);
    max-width: 500px;
    margin: 0 auto;
  }

  .li {
    grid-template-columns: 100px 1fr;
    &:before {
      font-size: var(--base-text-mega);
      padding-right: var(--space-lg);
      margin-right: var(--space-lg);
    }
  }
}

@media (min-width: 992px) {
  .icon {
    width: 140px;
    height: auto;
  }

  .ol {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: unset;
    margin-top: var(--space-md);
    margin-bottom: var(--space-48);
  }

  .li {
    &:before {
      font-size: 60px;
      padding-right: var(--space-lg);
      margin-right: var(--space-lg);
    }
  }

  .panel,
  .family {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .panel {
    display: flex;
    justify-content: center;
    gap: 64px;
    min-height: 338px;
    border-radius: 6px 6px 0 0;
  }

  .google {
    margin-top: var(--space-xxs);
  }

  .panel__title {
    font-size: var(--base-text-xxl);
  }
}

@media (min-width: 1024px) {
  .family__img {
    min-height: 528px;
  }

  .faqs__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1300px) {
  .picture--short {
    height: calc(100% - 106px);
  }
}

.m\:xs {
  margin-bottom: var(--space-xxs) !important; // Ugg need to override custom styles
}

@media (min-width: 1500px) {
  .icon {
    width: 160px;
  }
}

@media (min-width: 1512px) {
  .ol {
    grid-column: 2 / span 10;
  }
}
</style>

<style lang="scss" module="benefits">
.list {
  display: grid;
  grid-column: 1 / -1;
  gap: var(--space-48);
  justify-content: center;
  width: 80%;
  margin: 0 auto var(--space-48);
  text-align: center;
}

.benefit__header {
  text-align: center;
}

@media (min-width: 580px) {
  .list {
    grid-column: 2 / span 2;
  }
}

@media (min-width: 656px) {
  .list {
    grid-column: 2 / span 4;
  }
}

@media (min-width: 756px) {
  .list {
    grid-column: 1 / -1;
    grid-template-columns: var(--grid-columns-sm);
    width: 100%;
  }

  .item {
    grid-column: span 2;
  }

  .benefit__copy {
    max-width: 800px;
    margin: 0 auto var(--space-lg) auto !important;
  }
}

@media (min-width: 992px) {
  .list {
    grid-template-columns: var(--grid-columns-lg);
  }

  .item {
    grid-column: span 4;
  }
}
@media (min-width: 1024px) {
  .item {
    position: relative;
    &:not(:nth-child(3)):after {
      content: "";
      position: absolute;
      top: 48px;
      right: -10%;
      width: 24px;
      height: 24px;
      background: url(https://storage.googleapis.com/df-prod-cdn/img/icons/arrow_right.svg) center center no-repeat;
    }
  }

  .header-h4 {
    font-size: var(--base-text-xl, 1.625rem);
  }
}

@media (min-width: 1354px) {
  .list {
    gap: 8%;
  }

  .item {
    &:not(:nth-child(3)):after {
      right: -20%;
      transform: translateX(-80%);
    }
  }

  .benefit__header {
    text-align: center;
  }

  .benefit__copy {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1500px) {
  .list {
    grid-column: 2 / span 10;
  }

  .item {
    &:not(:nth-child(3)):after {
      top: 72px;
    }
  }
}
</style>
