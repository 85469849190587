import { fixedMask, incomeMask, makeMask, numericMask, phoneMask, ssnMask } from "./index.js";

export const Mask = {
  phone: "phone",
  date: "date",
  month: "month",
  numeric: "numeric",
  ssn: "ssn",
  income: "income",
  fixed: "fixed", // fixed length
};

export const mask = (name, options = undefined) => {
  if (name === Mask.phone) {
    return makeMask("phone", phoneMask, options);
  } else if (name === Mask.numeric) {
    return makeMask("numeric", numericMask, {
      addCommas: true,
      ...options,
    });
  } else if (name === Mask.fixed) {
    return makeMask("fixed", fixedMask, options);
  } else if (name === Mask.ssn) {
    return makeMask("ssn", ssnMask, options);
  } else if (name === Mask.income) {
    return makeMask("income", incomeMask, options);
  }
  return undefined;
};
