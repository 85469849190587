import { ref } from "vue";

import { addCommas } from "../utils";

export function usePayout() {
  const defaultOptions = [
    { text: "if you die right away.", value: "0" },
    { text: "if you die in 1 year.", value: "1" },
  ];
  const options = ref([...defaultOptions]);
  const payout = ref(0);

  // Quote Calculations
  function payoutCalculation(quoteResult, selectedYears, returnNumber = false) {
    const threshold = quoteResult?.termRecommended / 12 - Number(selectedYears);
    if (threshold <= 3) selectedYears = quoteResult?.termRecommended / 12 - 3;
    let amount = (quoteResult?.termRecommended - Number(selectedYears) * 12) * 2 * quoteResult?.benefitAmount;
    payout.value = amount += 10000;
    return !returnNumber ? addCommas(payout.value) : payout.value;
  }

  /**
   *
   * @param {object} value // Object from quote or quote estimate
   * @returns array
   */
  function payoutOptions(/** Object */ value) {
    console.log(value);
    options.value = [...defaultOptions];
    const length = Math.floor((value.termRecommended - 1) / 12);
    for (let i = 2; i <= length; i++) {
      options.value.push({ text: `If you die in ${i} years.`, value: i.toString() });
    }

    return options.value;
  }

  return {
    payoutCalculation,
    payoutOptions,
    options,
    payout,
  };
}
