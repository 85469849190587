import { PATHS, routeNames } from "../constants";
import AccountLayout from "../layouts/AccountLayout";
import { useAuthStore } from "../store/auth.store";
// import { useFlagsmithStore } from "../store/flagsmith.store";

export const accountRoutes = [
  {
    path: "/me",
    name: "user-dashboard",
    component: () => import("../pages/Account.vue"),
    meta: {
      doNotTrack: true,
      requiresAuth: true,
    },
    beforeEnter: () => {
      const authStore = useAuthStore();
      if (!authStore.authenticated) {
        return { name: routeNames.AUTH_SIGN_IN };
      }
    },
  },
  {
    path: "/payment",
    name: "payment-dashboard",
    component: () => import("../pages/Payment.vue"),
  },
  {
    path: PATHS.ACCOUNT,
    name: routeNames.ACCOUNT,
    redirect: { name: routeNames.OVERVIEW },
    meta: {
      requiresAuth: true,
      layout: AccountLayout,
    },
    component: () => import(/* webpackChunkName: "acc-module" */ "../pages/v3/AccountModule.vue"),
    beforeEnter: () => {},
    children: [
      {
        path: PATHS.OVERVIEW,
        name: routeNames.OVERVIEW,
        component: () => import(/* webpackChunkName: "acc-overview" */ "../pages/v3/AccountOverview.vue"),
      },
    ],
  },
];
