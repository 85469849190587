import { ref } from "vue";

const selectedFilters = ref({});
const toggleFilter = ref(false);

export function useFilter() {
  function clearSelections() {
    Object.keys(selectedFilters.value).forEach((key) => {
      selectedFilters.value[key] = [];
    });
  }

  function selectedFiltersLength() {
    return Object.values(selectedFilters.value).flat(2).length;
  }

  return {
    clearSelections,
    selectedFilters,
    selectedFiltersLength,
    toggleFilter,
  };
}
