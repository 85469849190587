export function numericMask(value, opts) {
  if (typeof value === "number") {
    return { masked: value.toString(), clean: value.toString() };
  }
  if (!value || value.trim().length === 0) {
    return { masked: "", clean: "" };
  }
  const onlyNumbers = /(?!^-)[^0-9]/g;
  let cleanValue = value.toString().replace(onlyNumbers, "");
  let maskedValue = cleanValue;

  if (opts?.min) {
    if (parseInt(maskedValue) < opts?.min) {
      maskedValue = maskedValue.slice(0, -1);
    }
  }

  if (opts?.maxLen && maskedValue.length > opts.maxLen) {
    maskedValue = maskedValue.slice(0, -1);
  }

  if (opts?.max) {
    if (parseInt(maskedValue) > opts?.max) {
      maskedValue = maskedValue.slice(0, -1);
    }
  }

  if (opts?.addCommas) {
    const addDelimiters = /\B(?=(\d{3})+(?!\d))/g;
    maskedValue = cleanValue.replace(addDelimiters, ",");
  }

  return { masked: maskedValue, clean: cleanValue };
}
