const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MONTHS_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

export function useDate() {
  const now = new Date();
  function before(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    return d2 <= d1;
  }

  // Initial method needs to be updated to be more flexable >, <, ===,
  function compare(date1, date2) {
    return before(date1, date2);
  }
  return {
    MONTHS,
    MONTHS_SHORT,
    compare,
    now,
  };
}
