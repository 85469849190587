export function toString(value) {
  if (value == null) return "";
  if (typeof value === "string") return value;

  if (Array.isArray(value)) {
    return value.join(" ");
  }

  const result = value + "";
  return result === "0" && 1 / value === -Infinity ? "-0" : result;
}
