import { useErrors, useReporter } from "@common/composables";
import { isArray, isNull, isString, isUndefined, union } from "@common/utils";
import { ref } from "vue";

const requiredKeys = ref([]);

function _validateCollection(collection) {
  if (!isArray(collection)) throw new Error(`'collection' must be an array.`);
}

export function useRequired() {
  const reporter = useReporter();
  const { setError } = useErrors();

  function validateRequired(questions, answers) {
    if (!Object.keys(answers).length) {
      questions.forEach(({ required, key }) => {
        if (required) {
          setError(key, "This field is required");
          reporter.error(`${key}: This field is required`);
        }
      });
      return;
    }

    questions.forEach(({ required, key }) => {
      if (required && !(key in answers)) {
        setError(key, "This field is required");
        reporter.error(`${key}: This field is required`);
      }
      if (required && key in answers && !answers[key].toString().length) {
        setError(key, "This field is required");
        reporter.error(`${key}: This field is required`);
      }
    });
  }
  function validateRequiredKeys(collection, keys) {
    for (const key of keys) {
      if (!(key in collection)) {
        setError(key, "This field is required.");
        reporter.error(`${key}: This field is required`);
      }
      if (
        key in collection &&
        (isNull(collection[key]) ||
          isUndefined(collection[key]) ||
          ((isString(collection[key]) || isArray(collection[key])) && !collection[key].length))
      ) {
        setError(key, "This field is required.");
        reporter.error(`${key}: This field is required`);
      }
    }
  }

  function validateRequiredFields(form) {
    const inputs = form.querySelectorAll("input[required]");
    inputs.forEach((input) => {
      if (!input.value.length) {
        setError(input.id, "This field is required.");
        reporter.error(`This field is required`);
      }
    });
  }

  function hasRequiredKey(key) {
    return requiredKeys.value.includes(key);
  }

  function setRequiredKeys(collection) {
    _validateCollection(collection);
    requiredKeys.value = collection;
  }

  function addRequiredKeys(...collections) {
    requiredKeys.value = union(...requiredKeys.value, ...collections);
  }

  function removeRequiredKeys(...collections) {
    const collection = collections.length === 1 ? collections[0] : union(...collections);
    const set = new Set(collection);
    requiredKeys.value = requiredKeys.value.filter((key) => !set.has(key));
  }

  function resetRequiredKeys() {
    requiredKeys.value = [];
  }

  return {
    requiredKeys,
    addRequiredKeys,
    hasRequiredKey,
    removeRequiredKeys,
    setRequiredKeys,
    resetRequiredKeys,
    validateRequired,
    validateRequiredFields,
    validateRequiredKeys,
  };
}
