const servers = ["development", "local"];

export function useLog() {
  function log(key, ...values) {
    if (servers.includes(process.env.VUE_APP_RELEASE_STAGE)) console.log(key, ...values);

    // function time() {
    //   if (servers.includes(process.env.VUE_APP_RELEASE_STAGE)) console.info(key, ...values);
    // }
    // function end() {
    //   if (servers.includes(process.env.VUE_APP_RELEASE_STAGE)) console.info(key, ...values);
    // }
    // return {
    //   time,
    //   end,
    // };
    // function info(key, ...values) {
    //   if (servers.includes(process.env.VUE_APP_RELEASE_STAGE)) console.info(key, ...values);
    // }
  }

  return {
    log,
  };
}
