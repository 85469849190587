import { computed, ref } from "vue";

const currentStep = ref(0);

export function useInterviewStep() {
  function setCurrentStep(currentIndex) {
    currentStep.value = currentIndex;
  }

  return {
    currentStep: computed(() => currentStep.value),
    setCurrentStep,
  };
}
