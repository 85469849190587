<template>
  <component :is="as" :class="[...classes]">
    <slot />
  </component>
</template>

<script setup>
import { isBoolean, isObject, isString } from "@dayforward/common";
import { ref, useCssModule } from "vue";

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  as: {
    type: [HTMLElement, String],
    default: "div",
  },
  padding: {
    type: [Boolean, Object, String],
  },
  align: {
    type: [Object, String],
  },
  grid: Boolean,
  section: Boolean,
});

const styles = useCssModule();

const classes = ref([styles.container]);

if (props.grid) classes.value.push(styles.grid);

// top & bottom props only
if (props.padding) {
  if (props.padding === 0) classes.value.push(styles["padding-none"]);
  if (isBoolean(props.padding)) classes.value.push(styles.padded);
  if (isString(props.padding)) classes.value.push(styles[`padding-${props.padding}`]);
  if (isObject(props.padding)) {
    for (const prop in props.padding) {
      if (props.padding[prop] === 0) classes.value.push(`padding-${prop}-none`);
      else classes.value.push(`padding-${prop}-${props.padding[prop]}`);
    }
  }
}

if (props.align) {
  classes.value.push(styles[`align--${props.align}`]);
}
</script>

<style lang="scss" module>
.container {
  width: 90%;
  max-width: 1700px;
  margin-right: auto;
  margin-left: auto;
}

.grid {
  display: grid;
  grid-template-columns: var(--grid-columns-xs);
  gap: 0 var(--space-default);
}

.padded {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.padding-none,
.padding-top-none {
  padding-top: 0;
}

.padding-none,
.padding-bottom-none {
  padding-bottom: 0;
}

// Extra Small Padding
.padding-xs,
.padding-top-xs {
  padding-top: var(--space-xs);
}

.padding-xs,
.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}

// Small Padding
.padding-sm,
.padding-top-sm {
  padding-top: var(--space-sm);
}

.padding-sm,
.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}

// Medium Padding
.padding-md,
.padding-top-md {
  padding-top: var(--space-md);
}

.padding-md,
.padding-bottom-md {
  padding-bottom: var(--space-md);
}

// Large Padding
.padding-lg,
.padding-top-lg {
  padding-top: var(--space-lg);
}

.padding-lg,
.padding-bottom-lg {
  padding-bottom: var(--space-lg);
}

// Extra Large Padding
.padding-xl,
.padding-top-xl {
  padding-top: var(--space-xl);
}

.padding-xs,
.padding-bottom-sm {
  padding-bottom: var(--space-xl);
}

.align--start {
  align-items: flex-start;
}

@media (min-width: 564px) {
  .grid {
    grid-template-columns: var(--grid-columns-sm);
  }
}

// @media (min-width: 664px) {
//   .grid {
//     grid-template-columns: var(--grid-columns-md);
//   }
// }

@media (min-width: 756px) {
  .grid {
    grid-template-columns: var(--grid-columns-md);
  }

  .padded {
    padding-top: var(--space-mega);
    padding-bottom: var(--space-mega);
  }
}

@media (min-width: 992px) {
  .grid {
    grid-template-columns: var(--grid-columns-lg);
  }
}
</style>
