import { ref } from "vue";

//import { useId } from "./id";

// @TODO get reactive elments working correctly on import
export function useElement(kind = "element") {
  function findElement(element) {
    if (!element.nodeType) return element.$el;
    return element;
  }

  // Not currently working
  if (kind === "list") {
    const elements = ref([]);
    const getRef = (index) => (element) => {
      if (!element) return;
      elements.value[index] = findElement(element);
    };

    return {
      getRef,
      element,
    };
  }

  // Not currently working
  const element = ref(null);
  const elementRef = (element) => {
    if (!element) return;
    element.value = findElement(element);
    console.log("elementRef", element.value);
  };

  return {
    ref: elementRef,
    element,
    findElement,
  };
}
