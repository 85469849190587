<template>
  <div :class="[[$style.avatar], [$style[size]]]" role="img" aria-label="User's Avatar" v-if="loaded">
    <BaseText as="span" bold size="sm" v-if="!useImg">{{ initials }}</BaseText>
    <img :src="user.imageUrl" alt="" v-if="useImg" />
  </div>
</template>

<script setup>
import { ref, shallowRef, watchEffect } from "vue";

import BaseText from "./BaseText";

const props = defineProps({
  user: {
    type: Object,
  },
  size: {
    type: String,
    validator: (prop) => ["sm", "ss", "md", "lg", "xl"].includes(prop),
  },
});

const colors = [
  "#1A113C",
  "#D83B01",
  "#B50E0E",
  "#E81123",
  "#E33031",
  "#5C2D91",
  "#0078D7",
  "#2C6DF3",
  "#1F394D",
  "#D9AB51",
  "#8E85AF",
];

const calculateColor = (value) => {
  let sum = 0;
  for (const i in value) sum += value.charCodeAt(i);
  return sum % colors.length;
};

const backgroundColor = shallowRef("");
const initials = shallowRef("");

const setBackground = () => {
  if (name.value.length) return colors[calculateColor(name.value)];
  return colors[calculateColor(email.value.slice(0, email.value.indexOf("@")))];
};

const setInitials = () => {
  if (name.value.length) {
    const names = name.value.split(" ");
    return `${names[0].charAt(0)}${names[1].charAt(0)}`;
  }
  return `${email.value.charAt(1)}`;
};

const useImg = !!props.user?.imageUrl?.length; // returns true if imageUrl

const loaded = ref(false);
const name = shallowRef("");
const email = shallowRef("");
watchEffect(
  () => {
    if (props.user && Object.keys(props.user).length > 2) {
      loaded.value = true;
      name.value = props.user?.name ? props.user.name : `${props.user?.firstName} ${props.user?.lastName}`;
      email.value = props.user?.email;
    }
    if (loaded.value && props.user) backgroundColor.value = setBackground();

    if (loaded.value && props.user) initials.value = setInitials();
  },
  { flush: "post" },
);
</script>

<!--suppress CssInvalidFunction -->
<style lang="scss" module>
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  min-width: 35px;
  height: 35px;
  color: #fff;
  overflow: hidden;
  text-transform: uppercase;
  background: v-bind(backgroundColor);
  border-radius: 50%;
}

.sm {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.ss {
  width: 28px;
  min-width: 28px;
  height: 28px;
  & span {
    font-size: 12px;
  }
}

.md {
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.lg {
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.xl {
  width: 64px;
  min-width: 64px;
  height: 64px;
}
</style>
