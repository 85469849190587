// @TODO This probably would be better suited in the auth store as it deals with auth sessions
import { getAuth } from "firebase/auth";
import { DateTime } from "luxon";
import { defineStore } from "pinia";

import { GlobalSessionIdKey } from "../../constants";

export const useUserDataStore = defineStore("userData", {
  state: () => ({
    sessionId: sessionStorage.getItem(GlobalSessionIdKey),
    isLoggedIn: false,
    expiration: undefined,
  }),
  getters: {
    // Legacy currentUser - moved to auth.module
    currentUser: () => {
      return getAuth().currentUser;
    },
  },
  actions: {
    updateSessionId(id) {
      this.sessionId = id;
      sessionStorage.setItem(GlobalSessionIdKey, id);
    },
    updateLoggedInState(loggedIn) {
      this.isLoggedIn = loggedIn;
    },
    // TODO:AUTH - this should be called when someone logs out
    clear() {
      this.sessionId = undefined;
      this.isLoggedIn = false;
      this.expiration = undefined;
    },
    async refreshUserToken() {
      await getAuth().currentUser.getIdToken(true);
    },
    setExpiration() {
      this.expiration = DateTime.utc().plus({ hours: 1 });
    },
    isSessionExpired() {
      // Stores are reset on hard refreshes, this check makes sure that we have a token,
      // refresh it and the expiration state is set
      if (this.expiration === undefined) {
        // console.log("refreshing token");
        this.refreshUserToken();
        this.setExpiration();
      }
      // Check if the user is expired
      let minutes = this.expiration.diffNow("minutes");
      return minutes <= 0;
    },
  },
});
