import { readonly, ref } from "vue";

// @TODO Nedd to check if the navigator.clipboard exist else provide fallback to execCommand
// if (navigator.clipboard) {
// } else {
// }

export function useClipboard() {
  const success = ref(true);

  async function copy(value) {
    try {
      await navigator.clipboard.writeText(value);
    } catch (error) {
      success.value = false;
    }
  }

  return {
    copy,
    success: readonly(success),
  };
}
