<template>
  <component :is="as" :class="[...classes]" v-bind="{ ...$attrs }">
    <slot />
  </component>
</template>

<script setup>
import { isBoolean, isObject, isString } from "@dayforward/common";
import { ref, useCssModule } from "vue";

const props = defineProps({
  as: {
    type: [HTMLElement, Object, String],
    default: "div",
  },
  cols: {
    type: [String, Object],
  },
  span: {
    type: [Boolean, String, Object],
  },
  start: {
    type: [String, Object],
  },
  end: {
    type: [String, Object],
  },
  order: {
    type: [String, Object],
  },
  gap: {
    type: [Boolean, String, Object],
  },
  margin: {
    type: [String, Object],
  },
  text: {
    type: [Object, String],
  },
  flex: Boolean,
  grid: Boolean,
});

const styles = useCssModule(); // Default grid

//console.log("Styles", styles);
const SIGN = "-1";
const defaultGrid = styles["grid"];
const defaultCols = styles["grid-cols"];
const defaultSpan = styles["grid-span"];
const keys = Object.keys(styles);

const classes = ref([styles.box]);

function parseCssString(values, name, string, map) {
  const collection = [];
  const value = values.find((value) => value.includes(name) && value.includes(string));
  if (value) collection.push(map[value]);
  return collection;
}

function parseCssObject(values, name, obj, map) {
  const collection = [];
  for (const o in obj) {
    if (obj[o].toString() === SIGN) {
      collection.push(map[`${o}:grid-span`]);
      continue;
    }
    const value = values.find(
      (value) => value.includes(name) && value.includes(o) && value.includes(obj[o].toString()),
    );
    if (value) collection.push(map[value]);
  }
  return collection;
}

if (props.grid) classes.value.push(defaultGrid);
if (props.grid && !props.cols) classes.value.push(defaultCols);
if (props.flex) classes.value.push(styles.flex);

// Gap classes
if (props.gap) {
  if (isBoolean(props.gap)) classes.value.push(styles.gap);
  if (isString(props.gap)) {
    classes.value.push(styles[`gap-${props.gap}`]);
  }
  if (isObject(props.gap)) {
    for (const prop in props.gap) {
      if (props.gap[prop] === "default") {
        classes.value.push(styles[`gap-${prop}`]);
        continue;
      }
      classes.value.push(styles[`gap-${prop}-${props.gap[prop]}`]);
    }
  }
}
// Span classes
if (props.span) {
  if (isBoolean(props.span) || props.span === SIGN) classes.value.push(defaultSpan);
  if (isString(props.span) && props.span !== SIGN) {
    const collection = parseCssString(keys, "span", props.span, styles);
    classes.value = [...classes.value, ...collection];
  }

  if (isObject(props.span)) {
    const collection = parseCssObject(keys, "span", props.span, styles);
    classes.value = [...classes.value, ...collection];
  }
}

// Custom responsive grid columns by media query
if (props.cols) {
  if (isObject(props.cols)) {
    for (const prop in props.cols) {
      const p = keys.find((key) => key.includes(prop) && key.includes(props.cols[prop].toString()));
      if (p) classes.value.push(styles[p]);
    }
  }
  // Custom grid column
  if (isString(props.cols)) {
    const col = keys.find((key) => key.includes("cols") && key.includes(props.cols));
    if (col) classes.value.push(styles[col]);
  }
}

// Start Classes
if (props.start) {
  if (isObject(props.start)) {
    for (const prop in props.start) {
      if (props.start[prop].toString() === SIGN) {
        classes.value.push(styles[`${prop}:grid-start`]);
        continue;
      }
      const p = keys.find(
        (key) => key.includes("start") && key.includes(prop) && key.includes(props.start[prop].toString()),
      );
      if (p) classes.value.push(styles[p]);
    }
  }
  // Custom grid start
  if (isString(props.start)) {
    const s = keys.find((key) => key.includes("start") && key.includes(props.start));
    if (s) classes.value.push(styles[s]);
  }
}
//
// End Classes
if (props.end) {
  if (isObject(props.end)) {
    for (const prop in props.end) {
      if (props.end[prop].toString() === SIGN) {
        classes.value.push(styles[`${prop}:grid-end`]);
        continue;
      }
      const e = keys.find(
        (key) => key.includes("end") && key.includes(prop) && key.includes(props.end[prop].toString()),
      );
      if (e) classes.value.push(styles[e]);
    }
  }
  // Custom grid end
  if (isString(props.end)) {
    const e = keys.find((key) => key.includes("end") && key.includes(props.end));
    if (e) classes.value.push(styles[e]);
  }
}

// Order Classes
if (props.order) {
  if (isObject(props.order)) {
    for (const prop in props.order) {
      const p = keys.find(
        (key) => key.includes("order") && key.includes(prop) && key.includes(props.order[prop].toString()),
      );
      if (p) classes.value.push(styles[p]);
    }
  }
  // Custom grid start
  if (isString(props.order)) {
    const s = keys.find((key) => key.includes("order") && key.includes(props.order));
    if (s) classes.value.push(styles[s]);
  }
}
// Margin Classes
if (props.margin) {
  if (isObject(props.margin)) {
    for (const prop in props.margin) {
      classes.value.push(styles[`m-${prop}-${props.margin[prop]}`]);
    }
  }
}

// Text align center classes
if (props.text) {
  if (isString(props.text)) classes.value.push(styles[`text-${props.text}`]);
  if (isObject(props.text)) {
    for (const prop in props.text) {
      classes.value.push(styles[`${prop}:text-${props.text[prop]}`]);
    }
  }
}
</script>

<style lang="scss" module>
.box {
  list-style: none;
  padding: 0;
  margin: 0;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.gap {
  gap: 0 var(--space-default);
}

.grid-row {
  row-gap: var(--space-default);
}

.gap-row-xs {
  row-gap: var(--space-xs);
}

.gap-row-sm {
  row-gap: var(--space-sm);
}

.gap-row-md {
  row-gap: var(--space-md);
}

.gap-row-lg {
  row-gap: var(--space-lg);
}

.gap-row-xl {
  row-gap: var(--space-xl);
}

.grid-col {
  column-gap: var(--space-default);
}

.gap-column-xs {
  column-gap: var(--space-xs);
}

.gap-column-sm {
  column-gap: var(--space-sm);
}

.gap-column-md {
  column-gap: var(--space-md);
}

.gap-column-lg {
  column-gap: var(--space-lg);
}

.gap-column-xl {
  column-gap: var(--space-xl);
}


.gap-lg {
  gap: var(--space-lg);
}

.text-center {
  text-align: center;
}

.grid-cols,
.grid-cols-4,
.xs\:grid-cols-4 {
  grid-template-columns: var(--grid-columns-xs);
}

.grid-cols-6,
.xs\:grid-cols-6 {
  grid-template-columns: var(--grid-columns-sm);
}

.grid-cols-8,
.xs\:grid-cols-8 {
  grid-template-columns: var(--grid-columns-md);
}

.grid-cols-12,
.xs\:grid-cols-12 {
  grid-template-columns: var(--grid-columns-lg);
}

.grid-span,
.xs\:grid-span {
  grid-column: 1 / -1;
}

.grid-span-1,
.xs\:grid-span-1 {
  grid-column: span 1 / span 1;
}

.grid-span-2,
.xs\:grid-span-2 {
  grid-column: span 2 / span 2;
}

.grid-span-3,
.xs\:grid-span-3 {
  grid-column: span 3 / span 3;
}

.grid-span-4,
.xs\:grid-span-4 {
  grid-column: span 4 / span 4;
}

.grid-span-5,
.xs\:grid-span-5 {
  grid-column: span 5 / span 5;
}

.grid-span-6,
.xs\:grid-span-6 {
  grid-column: span 6 / span 6;
}

.grid-span-7,
.xs\:grid-span-7 {
  grid-column: span 7 / span 7;
}

.grid-span-8,
.xs\:grid-span-8 {
  grid-column: span 8 / span 8;
}

.grid-span-9,
.xs\:grid-span-9 {
  grid-column: span 9 / span 9;
}

.grid-span-10,
.xs\:grid-span-10 {
  grid-column: span 10 / span 10;
}

.grid-span-11,
.xs\:grid-span-11 {
  grid-column: span 11 / span 11;
}

.grid-span-12,
.xs\:grid-span-12 {
  grid-column: span 12 / span 12;
}

.order-1,
.xs\:order-1 {
  order: 1;
}

.order-2,
.xs\:order-2 {
  order: 2;
}

.order-unset,
.xs\:order-unset {
  order: unset;
}

@media (min-width: 564px) {
  .grid-cols,
  .sm\:grid-6 {
    grid-template-columns: var(--grid-columns-sm);
  }

  .sm\:grid-span {
    grid-column: 1 / -1;
  }
  .sm\:grid-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:grid-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:grid-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:grid-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:grid-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm\:grid-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:grid-span-7 {
    grid-column: span 7 / span 7;
  }

  .sm\:grid-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:grid-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:grid-span-10 {
    grid-column: span 10 / span 10;
  }

  .sm\:grid-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm\:grid-span-12 {
    grid-column: span 12 / span 12;
  }
  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-2 {
    order: 2;
  }

  .sm\:order-unset {
    order: unset;
  }
}

@media (min-width: 756px) {
  .grid-cols,
  .md\:grid-8 {
    grid-template-columns: var(--grid-columns-md);
  }

  .md\:grid-span {
    grid-column: 1 / -1;
  }
  .md\:grid-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:grid-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:grid-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:grid-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:grid-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:grid-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:grid-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:grid-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:grid-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:grid-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:grid-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:grid-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:order-unset {
    order: unset;
  }
}

@media (min-width: 992px) {
  .lg\:grid-span {
    grid-column: 1 / -1;
  }

  .lg\:grid-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:grid-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:grid-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:grid-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:grid-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:grid-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:grid-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:grid-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:grid-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:grid-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:grid-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:grid-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-unset {
    order: unset;
  }
}

@media (min-width: 1200px) {
  .xl\:grid-span {
    grid-column: 1 / -1;
  }
  .xl\:grid-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:grid-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:grid-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:grid-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:grid-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:grid-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:grid-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:grid-span-8 {
    grid-column: span 8 / span 8;
  }

  .xl\:grid-span-9 {
    grid-column: span 9 / span 9;
  }

  .xl\:grid-span-10 {
    grid-column: span 10 / span 10;
  }

  .xl\:grid-span-11 {
    grid-column: span 11 / span 11;
  }

  .xl\:grid-span-12 {
    grid-column: span 12 / span 12;
  }

  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-2 {
    order: 2;
  }

  .xl\:order-unset {
    order: unset;
  }
}

.grid-start-1,
.xs\:grid-start-1 {
  grid-column-start: 1;
}

.grid-start-2,
.xs\:grid-start-2 {
  grid-column-start: 2;
}

.grid-start-3,
.xs\:grid-start-3 {
  grid-column-start: 3;
}

.grid-start-4,
.xs\:grid-start-4 {
  grid-column-start: 4;
}

.grid-start-5,
.xs\:grid-start-5 {
  grid-column-start: 5;
}

.grid-start-6,
.xs\:grid-start-6 {
  grid-column-start: 6;
}

.grid-start-7,
.xs\:grid-start-7 {
  grid-column-start: 7;
}

.grid-start-8,
.xs\:grid-start-8 {
  grid-column-start: 8;
}

.grid-start-9,
.xs\:grid-start-9 {
  grid-column-start: 9;
}

.grid-start-10,
.xs\:grid-start-10 {
  grid-column-start: 10;
}

.grid-start-11,
.xs\:grid-start-11 {
  grid-column-start: 11;
}

.grid-start-12,
.xs\:grid-start-12 {
  grid-column-start: 12;
}

.xs\:grid-end,
.sm\:grid-end,
.md\:grid-end,
.lg\:grid-end,
.xl\:grid-end {
  grid-column-end: -1;
}

.grid-end-1,
.xs\:grid-end-1 {
  grid-column-end: 1;
}

.grid-end-2,
.xs\:grid-end-2 {
  grid-column-end: 2;
}

.grid-end-3,
.xs\:grid-end-3 {
  grid-column-end: 3;
}

.grid-end-4,
.xs\:grid-end-4 {
  grid-column-end: 4;
}

.grid-end-5,
.xs\:grid-end-5 {
  grid-column-end: 5;
}

.grid-end-6,
.xs\:grid-end-6 {
  grid-column-end: 6;
}

.grid-end-7,
.xs\:grid-end-7 {
  grid-column-end: 7;
}

.grid-end-8,
.xs\:grid-end-8 {
  grid-column-end: 8;
}

.grid-end-9,
.xs\:grid-end-9 {
  grid-column-end: 9;
}

.grid-end-10,
.xs\:grid-end-10 {
  grid-column-end: 10;
}

.grid-end-11,
.xs\:grid-end-11 {
  grid-column-end: 11;
}

.grid-end-12,
.xs\:grid-end-12 {
  grid-column-end: 12;
}

.text-center {
  text-align: center;
}

@media (max-width: 563) {
  .xs\:text-center {
    text-align: center;
  }
}

@media (min-width: 564px) {
  .sm\:text-center {
    text-align: center;
  }

  .grid-cols,
  .sm\:grid-6 {
    grid-template-columns: var(--grid-columns-sm);
  }

  .sm\:grid-start-1 {
    grid-column-start: 1;
  }

  .sm\:grid-start-2 {
    grid-column-start: 2;
  }

  .sm\:grid-start-3 {
    grid-column-start: 3;
  }

  .sm\:grid-start-4 {
    grid-column-start: 4;
  }

  .sm\:grid-start-5 {
    grid-column-start: 5;
  }

  .sm\:grid-start-6 {
    grid-column-start: 6;
  }

  .sm\:grid-start-7 {
    grid-column-start: 7;
  }

  .sm\:grid-start-8 {
    grid-column-start: 8;
  }

  .sm\:grid-start-9 {
    grid-column-start: 9;
  }

  .sm\:grid-start-10 {
    grid-column-start: 10;
  }

  .sm\:grid-start-11 {
    grid-column-start: 11;
  }

  .sm\:grid-start-12 {
    grid-column-start: 12;
  }

  .sm\:grid-end-1 {
    grid-column-end: 1;
  }

  .sm\:grid-end-2 {
    grid-column-end: 2;
  }

  .sm\:grid-end-3 {
    grid-column-end: 3;
  }

  .sm\:grid-end-4 {
    grid-column-end: 4;
  }

  .sm\:grid-end-5 {
    grid-column-end: 5;
  }

  .sm\:grid-end-6 {
    grid-column-end: 6;
  }

  .sm\:grid-end-7 {
    grid-column-end: 7;
  }

  .sm\:grid-end-8 {
    grid-column-end: 8;
  }

  .sm\:grid-end-9 {
    grid-column-end: 9;
  }

  .sm\:grid-end-10 {
    grid-column-end: 10;
  }

  .sm\:grid-end-11 {
    grid-column-end: 11;
  }

  .sm\:grid-end-12 {
    grid-column-end: 12;
  }
}

@media (min-width: 756px) {
  .md\:text-center {
    text-align: center;
  }

  .grid-cols,
  .md\:grid-8 {
    grid-template-columns: var(--grid-columns-md);
  }

  .md\:grid-start-1 {
    grid-column-start: 1;
  }

  .md\:grid-start-2 {
    grid-column-start: 2;
  }

  .md\:grid-start-3 {
    grid-column-start: 3;
  }

  .md\:grid-start-4 {
    grid-column-start: 4;
  }

  .md\:grid-start-5 {
    grid-column-start: 5;
  }

  .md\:grid-start-6 {
    grid-column-start: 6;
  }

  .md\:grid-start-7 {
    grid-column-start: 7;
  }

  .md\:grid-start-8 {
    grid-column-start: 8;
  }

  .md\:grid-start-9 {
    grid-column-start: 9;
  }

  .md\:grid-start-10 {
    grid-column-start: 10;
  }

  .md\:grid-start-11 {
    grid-column-start: 11;
  }

  .md\:grid-start-12 {
    grid-column-start: 12;
  }

  .md\:grid-end-1 {
    grid-column-end: 1;
  }

  .md\:grid-end-2 {
    grid-column-end: 2;
  }

  .md\:grid-end-3 {
    grid-column-end: 3;
  }

  .md\:grid-end-4 {
    grid-column-end: 4;
  }

  .md\:grid-end-5 {
    grid-column-end: 5;
  }

  .md\:grid-end-6 {
    grid-column-end: 6;
  }

  .md\:grid-end-7 {
    grid-column-end: 7;
  }

  .md\:grid-end-8 {
    grid-column-end: 8;
  }

  .md\:grid-end-9 {
    grid-column-end: 9;
  }

  .md\:grid-end-10 {
    grid-column-end: 10;
  }

  .md\:grid-end-11 {
    grid-column-end: 11;
  }

  .md\:grid-end-12 {
    grid-column-end: 12;
  }
}

@media (min-width: 992px) {
  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-unset {
    text-align: unset;
  }

  .grid-cols,
  .lg\:grid-12 {
    grid-template-columns: var(--grid-columns-lg);
  }

  .lg\:grid-start-1 {
    grid-column-start: 1;
  }

  .lg\:grid-start-2 {
    grid-column-start: 2;
  }

  .lg\:grid-start-3 {
    grid-column-start: 3;
  }

  .lg\:grid-start-4 {
    grid-column-start: 4;
  }

  .lg\:grid-start-5 {
    grid-column-start: 5;
  }

  .lg\:grid-start-6 {
    grid-column-start: 6;
  }

  .lg\:grid-start-7 {
    grid-column-start: 7;
  }

  .lg\:grid-start-8 {
    grid-column-start: 8;
  }

  .lg\:grid-start-9 {
    grid-column-start: 9;
  }

  .lg\:grid-start-10 {
    grid-column-start: 10;
  }

  .lg\:grid-start-11 {
    grid-column-start: 11;
  }

  .lg\:grid-start-12 {
    grid-column-start: 12;
  }

  .lg\:grid-end-1 {
    grid-column-end: 1;
  }

  .lg\:grid-end-2 {
    grid-column-end: 2;
  }

  .lg\:grid-end-3 {
    grid-column-end: 3;
  }

  .lg\:grid-end-4 {
    grid-column-end: 4;
  }

  .lg\:grid-end-5 {
    grid-column-end: 5;
  }

  .lg\:grid-end-6 {
    grid-column-end: 6;
  }

  .lg\:grid-end-7 {
    grid-column-end: 7;
  }

  .lg\:grid-end-8 {
    grid-column-end: 8;
  }

  .lg\:grid-end-9 {
    grid-column-end: 9;
  }

  .lg\:grid-end-10 {
    grid-column-end: 10;
  }

  .lg\:grid-end-11 {
    grid-column-end: 11;
  }

  .lg\:grid-end-12 {
    grid-column-end: 12;
  }
}

@media (min-width: 1200px) {
  .xl\:text-center {
    text-align: center;
  }

  .xl\:grid-start-1 {
    grid-column-start: 1;
  }

  .xl\:grid-start-2 {
    grid-column-start: 2;
  }

  .xl\:grid-start-3 {
    grid-column-start: 3;
  }

  .xl\:grid-start-4 {
    grid-column-start: 4;
  }

  .xl\:grid-start-5 {
    grid-column-start: 5;
  }

  .xl\:grid-start-6 {
    grid-column-start: 6;
  }

  .xl\:grid-start-7 {
    grid-column-start: 7;
  }

  .xl\:grid-start-8 {
    grid-column-start: 8;
  }

  .xl\:grid-start-9 {
    grid-column-start: 9;
  }

  .xl\:grid-start-10 {
    grid-column-start: 10;
  }

  .xl\:grid-start-11 {
    grid-column-start: 11;
  }

  .xl\:grid-start-12 {
    grid-column-start: 12;
  }

  .xl\:grid-end-1 {
    grid-column-end: 1;
  }

  .xl\:grid-end-2 {
    grid-column-end: 2;
  }

  .xl\:grid-end-3 {
    grid-column-end: 3;
  }

  .xl\:grid-end-4 {
    grid-column-end: 4;
  }

  .xl\:grid-end-5 {
    grid-column-end: 5;
  }

  .xl\:grid-end-6 {
    grid-column-end: 6;
  }

  .xl\:grid-end-7 {
    grid-column-end: 7;
  }

  .xl\:grid-end-8 {
    grid-column-end: 8;
  }

  .xl\:grid-end-9 {
    grid-column-end: 9;
  }

  .xl\:grid-end-10 {
    grid-column-end: 10;
  }

  .xl\:grid-end-11 {
    grid-column-end: 11;
  }

  .xl\:grid-end-12 {
    grid-column-end: 12;
  }
}

.m-top-xs {
  margin-top: var(--space-xs);
}

.m-top-md {
  margin-top: var(--space-md);
}

.m-top-default {
  margin-top: var(--space-default);
}

.m-top-lg {
  margin-top: var(--space-lg);
}

.m-top-xl {
  margin-top: var(--space-xl);
}

.m-bottom-xs {
  margin-bottom: var(--space-xs);
}

.m-bottom-md {
  margin-bottom: var(--space-md);
}

.m-bottom-default {
  margin-bottom: var(--space-default);
}

.m-bottom-lg {
  margin-bottom: var(--space-lg);
}

.m-bottom-xl {
  margin-bottom: var(--space-xl);
}
</style>
