import { deleteCookie, readCookie, storeCookie } from "../cookies";

export class Experiment {
  constructor(name, split) {
    this.name = name;
    this.split = split;
  }
}

export class Variant {
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
}

export class ABExperimentList {
  constructor(experiments) {
    this.experiments = experiments || [];
    this.variants = {};
    this.listHash = this._generateHash(experiments);
  }

  get variantMap() {
    if (!this.variants || this.variants.length === 0) {
      return {};
    }
    const map = {};
    const variants = Object.values(this.variants);
    for (let i = 0; i < variants.length; i++) {
      const variant = variants[i];
      map[variant.name] = variant.value;
    }
    return map;
  }

  experimentFor(name) {
    return this.experiments.find((ex) => ex.name === name);
  }

  variantFor(name) {
    return this.variants[name]?.value;
  }

  get keysHash() {
    const names = this.experiments.map((ex) => ex.name).sort((a, b) => a.localeCompare(b));
    return this._generateHash(names.join(","));
  }

  // say we assigned our variants if there is at least one experiment.
  // otherwise, we should just reset the flag for next time.
  get hasAssignedVariants() {
    return this.variants && Object.keys(this.variants).length > 0;
  }

  addVariants(variants) {
    if (!variants) {
      return;
    }
    for (let i = 0; i < variants.length; i++) {
      const variant = variants[i];
      if (!this.variants[variant.name] && this.experimentFor(variant.name)) {
        this.variants[variant.name] = variant;
      }
    }
  }

  assignVariants(options) {
    const newVariants = [];
    for (let i = 0; i < this.experiments.length; i++) {
      const experiment = this.experiments[i];
      const variant = this.variants[experiment.name];
      if (!variant || options?.overwrite) {
        newVariants.push(experiment.name);
        this.variants[experiment.name] = new Variant(experiment.name, this.generateExperimentVariant(experiment));
      }
    }
    this._generateHash(this.experiments);
    return newVariants;
  }

  generateExperimentVariant(experiment) {
    const random = Math.random();
    let threshold = 0;
    for (let i = 0; i < experiment.split.length; i++) {
      threshold += experiment.split[i];
      if (random <= threshold) return i;
    }
    return 0;
  }

  _generateHash(experiments) {
    if (experiments.length === 0) {
      return undefined;
    }
    return _hashString(JSON.stringify(experiments));
  }
}

export class ABClientOptions {
  constructor(experiments, onInitialized) {
    this.experiments = experiments;
    this.onInitialized = onInitialized;
  }
}

export class ABClient {
  constructor(experiments, options) {
    this.options = options;
    if (experiments) {
      this.experiments = new ABExperimentList(experiments);
      console.log("[AB] Initializing ...");
      this.initializeVariants(options?.onInitialized);
    }
  }

  experimentFor(name) {
    return this.experiments.experimentFor(name);
  }

  variantFor(name) {
    return this.experiments?.variantFor(name);
  }

  initializeVariants(callback) {
    const savedHash = readCookie("ab:check");
    const hasHashChanged = !savedHash || this.experiments.keysHash !== parseInt(savedHash);

    // If the experiment list (hash) has not changed and we have already assigned variants
    // to all the experiments then we can just return the ones we have because nothing
    // has changed.
    if (!hasHashChanged && this.experiments.hasAssignedVariants) {
      if (callback) {
        callback({
          variant: this.experiments.variantMap,
          variantsList: this.experiments.variants,
          hasChanged: false,
          variantsChangeInfo: {
            added: [],
          },
          experiments: this.experiments,
          cached: true,
        });
      }
      return;
    }

    // At this point, we have no cached experiments with associated variants, or the list (hash)
    // has changed since we cached the variants last time, so we need to either generate some
    // or we need to reload what we have from the cookies.
    const cookieName = "ab:variants";
    let savedVariantMap = readCookie(cookieName, JSON.parse);
    // add the existing variants if they're missing in the list
    if (savedVariantMap) {
      const savedVariants = Object.values(savedVariantMap);
      this.experiments.addVariants(savedVariants);
    }
    // the list has changed since the last cache event, so let's update it
    let newVariants = [];
    if (hasHashChanged) {
      // the hash value has changed, so we need to assign variants to the experiments that
      // have missing variants
      newVariants = this.experiments.assignVariants();
    }
    if (this.experiments.hasAssignedVariants) {
      storeCookie(cookieName, JSON.stringify(this.experiments.variants));
      storeCookie("ab:check", this.experiments.keysHash);
    } else {
      deleteCookie(cookieName);
      deleteCookie("ab:check");
    }

    if (callback) {
      callback({
        variants: this.experiments.variantMap,
        variantsList: this.experiments.variants,
        hasChanged: hasHashChanged,
        variantsChangeInfo: {
          added: newVariants,
        },
        experiments: this.experiments,
        cached: false,
      });
    }
  }
}

function _hashString(strValue) {
  let hash = 0;
  for (let i = 0; i < strValue.length; i++) {
    const char = strValue.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
}
