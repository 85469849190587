export const DefaultMeta = {
	title: "Life Insurance That Guarantees Your Income | Dayforward",
	description:
		"Get life insurance that guarantees your income so your family has financial security after you're gone. Buy online and get covered in 10 minutes.",
	meta: [
		{
			name: "twitter:title",
			content: "Life Insurance That Guarantees Your Income | Dayforward",
		},
		{
			name: "twitter:description",
			content:
				"Get life insurance that guarantees your income so your family has financial security after you're gone. Buy online and get covered in 10 minutes.",
		},
		{
			name: "twitter:site",
			content: "@dayforward",
		},
		{
			name: "twitter:creator",
			content: "@dayforward",
		},
		{
			name: "twitter:image",
			content: "https://cdn.dayforward.com/df-og-logo.png",
		},
		{
			content: "website",
			property: "og:type",
		},
		{
			content: "en_US",
			property: "og:locale",
		},
		{
			content: "Life Insurance That Guarantees Your Income | Dayforward",
			property: "og:title",
		},
		{
			content: "Dayforward",
			property: "og:site_name",
		},
		{
			content: "https://www.dayforward.com",
			property: "og:url",
		},
		{
			content:
				"Get life insurance that guarantees your income so your family has financial security after you're gone. Buy online and get covered in 10 minutes.",
			property: "og:description",
		},
		{
			content: "https://cdn.dayforward.com/df-og-logo.png",
			property: "og:image",
		},
	],
};

export const ReviewsMeta = {
	title: "Life Insurance That Guarantees Your Income | Dayforward",
	description: "Read reviews of Dayforward Life Insurance from some of our happy customers",
	meta: [
		{
			name: "twitter:title",
			content: "Life Insurance That Guarantees Your Income | Dayforward",
		},
		{
			name: "twitter:description",
			content: "Read reviews of Dayforward Life Insurance from some of our happy customers",
		},
		{
			name: "twitter:site",
			content: "@dayforward",
		},
		{
			name: "twitter:creator",
			content: "@dayforward",
		},
		{
			name: "twitter:image",
			content: "https://cdn.dayforward.com/df-og-logo.png",
		},
		{
			content: "website",
			property: "og:type",
		},
		{
			content: "en_US",
			property: "og:locale",
		},
		{
			content: "Life Insurance That Guarantees Your Income | Dayforward",
			property: "og:title",
		},
		{
			content: "Dayforward",
			property: "og:site_name",
		},
		{
			content: "https://www.dayforward.com",
			property: "og:url",
		},
		{
			content: "Read reviews of Dayforward Life Insurance from some of our happy customers",
			property: "og:description",
		},
		{
			content: "https://cdn.dayforward.com/df-og-logo.png",
			property: "og:image",
		},
	],
};
