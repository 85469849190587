export class ConsoleReporter {
  constructor() {}
  captureMessage(message) {
    console.info("[app message]", message);
  }
  captureException(err) {
    console.error("[app error]", err);
  }
  captureTaggedException(tag, tagValue, err) {
    console.error(`[app error tagged] ${tag}:${tagValue}`);
    console.error(err);
  }
}
