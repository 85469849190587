import { routeNames, SLUGS } from "../constants";

// Set these to redirect to new quote estimate route
export const quickPriceRoutes = [
  {
    path: "/quick-price/estimate",
    redirect: { name: routeNames.Q_SLUG, params: { slug: SLUGS.dob } },
    name: "quick-price-estimate",
  },
  {
    path: "/quick-price",
    name: "quick-price",
    redirect: { name: routeNames.Q_SLUG, params: { slug: SLUGS.dob } },
  },
];
