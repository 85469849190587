/**
 * Returns a value based on the cases expression or default case as in example
 * const matched = match("one", { one: "My first choice.", two: "My second choice.", three: "My third choice.", default: "Sorry no result found" })
 * console.log(matched) // My first choice.
 * @param {string} value
 * @param {object} lookup
 * @returns value
 */
// export function match(expression, cases) {
//   return cases[expression] || cases?.default;
// }

export function match(value, lookup, ...args) {
  const $value = value in lookup ? value : "default";
  if ($value in lookup) {
    let returnValue = lookup[$value];
    return typeof returnValue === "function" ? returnValue(...args) : returnValue;
  }

  const error = new Error(`Tried to handle "${value}" but no handler definded or "default" in "lookup"`);

  throw error;
}
