function handleInput(el, binding) {
  const maskRes = binding.value.mask(el.value, binding.value.maskOptions);
  el.value = maskRes.masked;
}

export const MaskDirective = {
  mounted(el, binding) {
    el.addEventListener("input", handleInput.bind(this, el, binding));
  },
  unmount(el, binding) {
    el.removeEventListener("input", handleInput.bind(this, el, binding));
  },
};
