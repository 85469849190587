import { defineComponent } from "vue";

export const trackingHelpers = defineComponent({
  methods: {
    parseTrackingOptions(options) {
      let parsedOptions = {};
      Object.keys(options).forEach((k) => {
        const val = options[k];
        if (val instanceof Object) {
          const parsedSubOptions = this.parseTrackingOptions(options[k]);
          parsedOptions = {
            ...parsedOptions,
            ...parsedSubOptions,
          };
        } else if (typeof val === "string") {
          let saveVal = val;
          if (val.startsWith("{{") && val.endsWith("}}")) {
            saveVal = val.slice(2, val.length - 2);
            if (saveVal === "path") {
              saveVal = window.location.pathname;
            } else if (saveVal === "url") {
              saveVal = window.location.href;
            } else if (saveVal === "hostname") {
              saveVal = window.location.hostname;
            } else if (saveVal === "routeName") {
              saveVal = this.$route?.name;
            }
          }
          parsedOptions[k] = saveVal;
        } else {
          parsedOptions[k] = val;
        }
      });
      return parsedOptions;
    },
  },
});
