// Using Object.freeze to make constants immutable
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
export const STATUS = Object.freeze({
  NEW: "NEW",
  INCOMPLETE: "INCOMPLETE",
  INELIGIBLE: "INELIGIBLE",
  READY_TO_SIGN: "READY_TO_SIGN",
  AWAITING_SIGNATURE_COMPLETION: "AWAITING_SIGNATURE_COMPLETION",
  AWAITING_SIGNATURE_CONFIRMATION: "AWAITING_SIGNATURE_CONFIRMATION",
  READY_TO_SUBMIT: "READY_TO_SUBMIT",
  NEEDS_REVIEW: "NEEDS_REVIEW",
  SUBMITTED: "SUBMITTED",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  ERROR: "ERROR",
  EXPIRED: "EXPIRED",
  INACTIVE: "INACTIVE",
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  READY_FOR_CHECKOUT: "READY_FOR_CHECKOUT",
});

export const IMO_STATUS = Object.freeze({
  ACCEPTED: "Accepted",
  ERROR: "Error",
  EXPIRED: "Expired",
  INACTIVE: "Inactive",
  INELIGIBLE: "Ineligible",
  INFORMATION_REQUIRED: "Information Required",
  PENDING_FINAL_REVIEW: "Pending Final Review",
  READY_TO_BUY: "Ready to buy",
  REJECTED: "Rejected",
  UNDERWRITING_IN_PROGRESS: "Underwriting In Progress",
});
