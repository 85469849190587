<template>
  <dl :ref="rootRef">
    <slot />
  </dl>
</template>

<script setup>
import { provide } from "vue";

import { useAccordion } from "../composables";

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  activeIndex: {
    type: [String, Number],
  },
});

const { rootRef, triggerRef, panelRef, activeIndex, isActive, toggle } = useAccordion();

provide("api", {
  triggerRef,
  panelRef,
  activeIndex,
  isActive,
  toggle,
});
//if (props.activeIndex) activeIndex.value = props.activeIndex;
</script>

<style lang="scss" module></style>
