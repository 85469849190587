import { onBeforeUnmount, onMounted } from "vue";

export function useScroll(callback) {
  onMounted(() => {
    window.addEventListener("scroll", callback);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("scroll", callback);
  });

  return {};
}
