import { isNull, isUndefined } from "@common";
import { useReporter } from "@common/composables";
import { useInterviewData, useMarker, useUser } from "@composables";
import { toRaw } from "vue";
import { useRouter } from "vue-router";

import { routeNames, STATUS } from "../constants";
import { useInterview } from "./api";

export function useAccountRedirects() {
  const reporter = useReporter();
  const router = useRouter();
  const { flags, invalidPaymentStatus, interviewStatus } = useInterviewData();

  function handleInProgressInterview(store, funnel) {
    const { marker, setMarker } = useMarker();

    // @TODO might be better to move to the initilize marker to marker composable
    function _initMarker() {
      // @TODO Check if marker value and interview marker are synced
      setMarker("initial-step");
      store.setState("$marker", marker.value);
    }

    // Ensure there is a marker value;
    if (isNull(marker.value)) _initMarker();

    // Check if payment status is valid
    const paymentStatusInvalid = invalidPaymentStatus(store); // Returns boolean and sets marker to quote;
    if (paymentStatusInvalid) reporter.log("Payment status was invalid.");

    // @PATCH Ensure that interviews that have an invalid quote marker get reset
    if (marker.value === "quote" && interviewStatus.value === STATUS.INCOMPLETE) _initMarker();

    const steps = funnel.steps;
    const currentStep = steps.find((step) => step.name === marker.value);
    const routeParams = currentStep?.routeParams; // Don't believe this is needed anymore
    const name = isUndefined(currentStep) ? routeNames.INTERVIEW_INITIAL_STEP : marker.value;
    router.replace({
      name,
      ...(routeParams && {
        params: toRaw(routeParams),
      }),
    });
  }

  async function handleTerminalInterview(apollo) {
    const { interviewQuestions } = useInterviewData();
    const { user } = useUser();
    const userId = user.value.id;
    if (!userId) {
      reporter.error("ERROR: Missing user id.");
      return;
    }
    const { state } = user.value.address;
    const { createInterview } = useInterview(apollo);
    const { payload, success, message } = await createInterview(state, true, userId);
    if (!success) {
      reporter.error("Start over failed:", message);
      return { success };
    }
    interviewQuestions.value = payload.questions;
    return { success };
  }

  function handlePendingInterview() {
    if (flags.value?.includes("PAYMENT_FAILED")) {
      router.replace("/payment");
      return;
    }
  }

  function handleOffRamp() {
    router.replace("/or/sorry");
  }

  return {
    handleInProgressInterview,
    handleTerminalInterview,
    handleOffRamp,
    handlePendingInterview,
  };
}
