//import { useAuthStore } from "../../store/modules/auth.module";
import { PATHS, routeNames } from "../constants";
import DemoAgentLayout from "../layouts/demo/DemoAgentLayout.vue";
// import { useFlagsmithStore } from "../store/flagsmith.store";

export const demoRoutes = [
  {
    path: "/agent",
    name: "demo-agent-signin",
    redirect: { name: routeNames.DEMO_APPLICANT_SIGN_IN },
    meta: {
      layout: DemoAgentLayout,
    },
    component: () => import("../pages/demo/DemoAgentModule.vue"),
    children: [
      {
        path: PATHS.DEMO_APPLICANT_SIGN_IN,
        name: routeNames.DEMO_APPLICANT_SIGN_IN,
        component: () => import("../pages/demo/DemoApplicantSignIn.vue"),
      },
      {
        path: PATHS.DEMO_APPLICANT_CONSENT,
        name: routeNames.DEMO_APPLICANT_CONSENT,
        component: () => import("../pages/demo/DemoApplicantConsent.vue"),
      },
      {
        path: PATHS.DEMO_APPLICANT_REVIEW,
        name: routeNames.DEMO_APPLICANT_REVIEW,
        component: () => import("../pages/demo/DemoApplicantReview.vue"),
      },
      {
        path: PATHS.DEMO_APPLICANT_WAITING,
        name: routeNames.DEMO_APPLICANT_WAITING,
        component: () => import("../pages/demo/DemoApplicantWaiting.vue"),
      },
      {
        path: PATHS.DEMO_APPLICANT_QUOTE,
        name: routeNames.DEMO_APPLICANT_QUOTE,
        component: () => import("../pages/demo/DemoApplicantQuote.vue"),
      },
      {
        path: PATHS.DEMO_APPLICANT_CHECKOUT,
        name: routeNames.DEMO_APPLICANT_CHECKOUT,
        component: () => import("../pages/demo/DemoApplicantCheckout.vue"),
      },
      {
        path: PATHS.DEMO_APPLICANT_SIGN,
        name: routeNames.DEMO_APPLICANT_SIGN,
        component: () => import("../pages/demo/DemoApplicantSign.vue"),
      },
      {
        path: PATHS.DEMO_APPLICANT_THANKS,
        name: routeNames.DEMO_APPLICANT_THANKS,
        component: () => import("../pages/demo/DemoApplicantThanks.vue"),
      },
    ],
  },
  {
    path: "/a/:name",
    name: "agent-profile",
    component: () => import("../pages/demo/Profile.vue"),
  },
];
