import { shallowRef } from "vue";

import { STORAGE_KEYS, useLocalStorage } from "./storage";

const buckets = [];
const bucket = shallowRef();
const source = shallowRef(null)

export function useExperiment() {

  const { get, set } = useLocalStorage();
  const disabled = true;
  function setup() {
    if (disabled) {
      bucket.value = null
      return;
    }
    // Check storage
    bucket.value = get(STORAGE_KEYS.DF_EXPERIMENT);
    // If no bucket set bucket to bmi value
    if (!bucket.value || !buckets.includes(bucket.value)) {
      const value = Math.floor(Math.random() * 2);
      bucket.value = buckets[value];
      set(STORAGE_KEYS.DF_EXPERIMENT, bucket.value)
      return;
    }
  }

  function isVariant() {
    return false;
  }

  return {
    bucket,
    buckets,
    setup,
    isVariant,
    source
  }
}
