<template>
  <component :is="$route.meta.layout || DefaultLayout" />
</template>

<script setup>
import "./assets/styles/main.scss";

import { useFeatureFlags } from "@common";
import { STORAGE_KEYS, useExperiment, useFlagsmith, useSessionStorage } from "@composables";
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import DefaultLayout from "./layouts/DefaultLayout.vue";
import { useFlagsmithStore } from "./store/flagsmith.store";

// Setup legacy flagsmith features
const features = useFeatureFlags();
const flagsmithStore = useFlagsmithStore();
onMounted(async () => { flagsmithStore.setState("$features", features); });
//END

// Setup quote experiment
const { setup } = useExperiment()
onMounted(() => setup());
// END

// Add flagsmith identity for a/b experiments using new flagsmith composable
const { identify } = useFlagsmith();
onMounted(() => identify());
// END

// Add query params to session profile in session storage
const route = useRoute();
const { set } = useSessionStorage()
onMounted(() => {
  // @TODO replace timer nextTick(() => console.log("tick", route))
  const timer = setTimeout(() => {
    if (Object.keys(route.query).length) set(STORAGE_KEYS.DF_SESSION_PROFILE, { ...route.query })
    clearTimeout(timer);
  }, 10);
});
// END
</script>

<style lang="scss" module></style>
