import { gql } from "@apollo/client/core";

const trackPageMutation = gql`
  mutation Page($pageView: PageView!) {
    page(page: $pageView)
  }
`;

const trackEventMutation = gql`
  mutation Track($trackingEvent: TrackingEvent!) {
    track(event: $trackingEvent)
  }
`;

const identifytMutation = gql`
  mutation Identify($identity: Identity!) {
    identify(identity: $identity)
  }
`;

export { identifytMutation, trackEventMutation, trackPageMutation };
