import { Redirect } from "../composables";
import { routeNames, SLUGS } from "../constants";

export const redirectRoutes = [
  {
    path: "/continue",
    name: "app-continue",
    redirect: { name: routeNames.AUTH_SIGN_IN },
    meta: {
      doNotTrack: true,
    },
  },
  {
    path: "/get-started",
    name: "get-started",
    redirect: { name: routeNames.Q_SLUG, params: { slug: SLUGS.dob } },
    meta: {
      doNotTrack: true,
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: Redirect,
    meta: {
      doNotTrack: true,
    },
  },
  {
    path: "/admin/dashboard",
    name: "admin-dashboard",
    component: Redirect,
    meta: {
      doNotTrack: true,
    },
  },
  {
    path: "/incoming/a/:slug",
    name: "incoming-article",
    component: Redirect,
    meta: {
      doNotTrack: true,
    },
  },
  {
    path: "/incoming",
    name: "incoming",
    component: Redirect,
    meta: {
      doNotTrack: true,
    },
  },
];
