import { useAddress } from "./api";

function parseAddresses(collection) {
  return collection.map((obj) => ({
    value: `${obj.line1} ${obj.city} ${obj.state} ${obj.postalCode}`,
    text: `${obj.line1} ${obj.city} ${obj.state} ${obj.postalCode}`,
    values: {
      line1: obj.line1,
      city: obj.city,
      state: obj.state,
      postalCode: obj.postalCode,
    },
  }));
}

export function useSearch(apollo) {
  const { queryAddress } = useAddress(apollo);
  async function searchAddress(text, states, state) {
    if (!text) return;
    //data.isSearchingAddresses = true;
    const { success, payload } = await queryAddress(text, states, state);
    const addresses = payload.value.address;
    if (!success && !addresses) {
      return [];
      //return { success: false, message: "could not find a address" };
    }
    return parseAddresses(addresses);
    //data.isSearchingAddresses = false;
  }
  return {
    searchAddress,
  };
}
