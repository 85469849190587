export const SavedStateKeys = {
	INTERVIEW_QUESTIONS: "--df.interview.questions",
	INTERVIEW_ID: "--df.interview.id",
};

export const QuestionKeys = {
	DATE_OF_BIRTH: "DATE_OF_BIRTH",
	GENDER: "IDENTIFY_AS_GENDER",
	HAVE_CHILDREN: "HAVE_CHILDREN",
	YOUNGEST_CHILDS_BIRTHDAY: "YOUNGEST_CHILDS_BIRTHDAY",
	ANNUAL_INCOME: "ANNUAL_INCOME",
	ANNUAL_HOUSEHOLD_INCOME: "ANNUAL_HOUSEHOLD_INCOME",
	INCOME_INCREASE: "INCOME_INCREASE",
	ADDITIONAL_CHILDREN: "ADDITIONAL_CHILDREN",
	MONTHLY_SPEND: "MONTHLY_SPEND",
};
