import { cookie } from "@common";
import { createRouter, createWebHistory } from "vue-router";

import { useExperiment } from "../composables/experiment";
import { STORAGE_KEYS, useLocalStorage } from "../composables/storage";
import { useTraits } from "../composables/traits";
import { routeNames } from "../constants";
import { useAuthStore } from "../store/auth.store";
import { useUserStore } from "../store/user.store";
import { accountRoutes } from "./account.routes";
import { authRoutes } from "./auth.routes";
import { demoRoutes } from "./demo.routes";
import { healthylivingRoutes } from "./healthyliving.routes";
import { interviewRoutes } from "./interview.routes";
import { legacyRoutes } from "./legacy.routes";
import { marketingRoutes } from "./marketing.routes";
import { quickPriceRoutes } from "./quick-price.routes";
import { quoteRoutes } from "./quote.routes";
import { redirectRoutes } from "./redirect.routes";
import { webhooksRoutes } from "./webhooks.routes";

let routes = [
  ...marketingRoutes,
  ...authRoutes,
  ...interviewRoutes,
  ...quickPriceRoutes,
  ...redirectRoutes,
  ...demoRoutes,
  ...quoteRoutes,
    ...accountRoutes,
  ...healthylivingRoutes,
  ...webhooksRoutes,
  ...legacyRoutes,
];

const scrollPositions = {};

export function createAppRouter(options) {
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
      // Initial scroll disable on quick view for imo client
      if (to.meta.disableScroll) return;
      // if you're navigating to a route (or to the same route you're on) then this code below
      // will deal with jumping to the relevant html element. If you're going to the same
      // element as the current hash, then we don't offset as it seems to want to do it twice.
      if (to.hash) {
        return { el: to.hash, top: from.hash === to.hash ? 0 : 80 };
      }
      // this chunk of code will get the saved position from the push/pop history of the browser nav stack
      // and the will save it in the session so that we can retrieve it at a later date. we might want to
      // consider making it a plugin (or something) at some point.
      const scrollPos = savedPosition || { left: 0, top: 0 };
      if (savedPosition) {
        scrollPositions[to.path] = savedPosition;
      } else {
        delete scrollPositions[to.path];
      }
      window.sessionStorage.setItem("--df-router.scrollPositions", JSON.stringify(scrollPositions));
      return scrollPos;
    },
    routes,
  });
  // @TODO Remove at some point during refactor
  router.abClient = options?.abClient;
  router.currentApp = options?.currentApp;

  router.beforeResolve((to) => {
    if (to.query && to.query["ko"]) {
      localStorage.setItem("--df.flag.ko", "true");
    }
  });

  router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const { get } = useLocalStorage();
    const { hasProp, setState } = userStore;
    const isImo = to.path.includes(routeNames.IMO);
    const isSignin = to.name === routeNames.IMO_SIGNIN;
    const isConsent = to.name === routeNames.IMO_CONSENT || to.name === routeNames.IMO_CONSENT_CONFIRMED;
    if (isImo) {
      //@TODO move to redirects.js
      // const props = { to, from }
      // redirect(props)
      if (to.meta.requiresAuth && !authStore.authenticated && !isSignin) {
        // If consent page route to the sign in page
        if (isConsent) return next({ name: routeNames.AUTH_SIGN_IN });
        // Otherwise route to imo sign in
        return next({ name: routeNames.IMO_SIGNIN });
      }
      // PROTECT CONSENT PAGES FROM INVALID ACCESS
      // If isConsent and no query interviewId sent them to the home page
      if (isConsent && !to.query.interviewId) return next({ name: routeNames.HOME });

      if (!hasProp("$user")) {
        const user = get(STORAGE_KEYS.DF_USER);
        // User not in storage
        // If there's no user[AGENT, OWNER] and isConsent with query interviewId sent them to the consent page
        if (!user && isConsent) return next({ name: routeNames.INTERVIEW });
        if (!user && !isSignin) return next({ name: routeNames.IMO_SIGNIN });
        if (!user && isSignin) return next();
        // Has user from storage
        setState("$user", user);
        if (!userStore.isAgent && isConsent && to.query.interviewId) return next();
        if (userStore.isAgent && isConsent) return next({ name: routeNames.IMO_DASHBOARD_APPLICATIONS });
        if (!userStore.isAgent && !isSignin && !isConsent) return next({ name: routeNames.AUTH_SIGN_IN });
        // If there's a user and the user is an agent redirect to the dashboard
        if (userStore.isAgent && isSignin) {
          return next({ name: routeNames.IMO_DASHBOARD_APPLICATIONS });
        }
      }
    }

    // added tp remove screen lock if back button is used to navigate while modal is open
    if (from.name === routeNames.INTERVIEW_E_SIGN) {
      const body = document.querySelector("body");
      if (body.classList.contains("hidden")) {
        body.classList.remove("hidden");
      }
    }

    // if (Object.keys(from.query).length && !Object.keys(to.query).length && from.name !== routeNames.AUTH_CALLBACK) {
    //   next({ name: to.name, params: { ...to.params },  query: { ...from.query } })
    // }
    next();
  });

  const { bucket, source } = useExperiment();
  const { traits } = useTraits();
  router.afterEach((to, from) => {
    const context = cookie("analytics:utm");
    source.value = to.query.utm_source ? to.query?.utm_source : context ? JSON.parse(context).source : null;
    if (!to.meta?.doNotTrack) {
      const analytics = router.currentApp.config.globalProperties.$analytics;
      analytics?.page(
        {
          path: to.path,
          fullPath: to.fullPath,
        },
        {
          from: from.path,
          source: source.value,
          experiment: bucket.value,
          ...(traits.value && traits.value),
        },
      );
    }
  });

  return router;
}
