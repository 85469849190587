export * from "./data";
export * from "./risk";
export * from "./roles";
export * from "./routes";
export * from "./sliders";
export * from "./status";
export * from "./traits";
// session and local storage data keys
export const GlobalSessionIdKey = "--df.user.sessionId";

export const accountStatus = {
  HAS_EXISTING_ACCOUNT: "HAS_EXISTING_ACCOUNT",
  HAS_EXISTING_INTERVIEW: "HAS_EXISTING_INTERVIEW",
  HAS_EXISTING_POLICY: "HAS_EXISTING_POLICY",
  HAS_NO_EXISTING_INTERVIEW: "HAS_NO_EXISTING_INTERVIEW",
  UNKNOWN: "UNKNOWN",
};

export const questionKeys = {
  ACCEPTED_NOTICES: "ACCEPTED_NOTICES",
  EMAIL_ADDRESS: "EMAIL_ADDRESS",
  FIRST_NAME: "FIRST_NAME",
  LAST_NAME: "LAST_NAME",
  MIDDLE_INITIAL: "MIDDLE_INITIAL",
  MOBILE_PHONE_NUMBER: "MOBILE_PHONE_NUMBER",
  DATE_OF_BIRTH: "DATE_OF_BIRTH",
  HEIGHT_INCHES: "HEIGHT_INCHES",
  WEIGHT_POUNDS: "WEIGHT_POUNDS",
  WEIGHT_CHANGE: "WEIGHT_CHANGE",
  WEIGHT_CHANGE_REASON: "WEIGHT_CHANGE_REASON",
  WEIGHT_CHANGED_HOW: "WEIGHT_CHANGED_HOW",
  WEIGHT_CHANGED_POUNDS: "WEIGHT_CHANGED_POUNDS",
  GENDER: "IDENTIFY_AS_GENDER",
  ADDRESS_LINE_1: "HOME_ADDRESS_1",
  ADDRESS_LINE_2: "HOME_ADDRESS_2",
  ADDRESS_CITY: "HOME_CITY",
  ADDRESS_STATE: "HOME_STATE",
  ADDRESS_ZIP: "HOME_ZIP",
  IS_US_CITIZEN_OR_LEGAL_RESIDENT: "IS_US_CITIZEN_OR_LEGAL_RESIDENT",
  HAS_VISA: "CITIZENSHIP_HAS_US_VISA",
  COUNTRY: "CITIZENSHIP_COUNTRY",
  RESIDENCE: "CITIZENSHIP_RES",
  VISA_EXPIRY_DATE: "CITIZENSHIP_VISA_EXPIRY_DATE",
  VISA_ISSUE_DATE: "CITIZENSHIP_VISA_ISSUE_DATE",
  VISA_NUMBER: "CITIZENSHIP_VISA_NUMBER",
  WHICH_VISA: "CITIZENSHIP_WHICH_US_VISA",

  // income
  CURRENTLY_WORKING: "CURRENTLY_WORKING",
  OCCUPATION: "OCCUPATION",
  ANNUAL_INCOME: "ANNUAL_PRE_TAX_INCOME",
  ANNUAL_HOUSEHOLD_INCOME: "ANNUAL_HOUSEHOLD_PRE_TAX_INCOME",
  NOT_CURRENTLY_WORKING_REASON: "NOT_CURRENTLY_WORKING_REASON",
  PREVIOUS_OCCUPATION: "PREVIOUS_OCCUPATION",
  PREVIOUS_ANNUAL_INCOME: "PREVIOUS_ANNUAL_PRE_TAX_INCOME",
  HAS_BEEN_INDUCED: "HAS_BEEN_INDUCED",
  HAS_BEEN_DECLINED: "HAS_BEEN_DECLINED",
  SPOUSE_ANNUAL_INCOME: "SPOUSE_ANNUAL_INCOME",

  // income offramp
  ANNUAL_INCOME_CAP_ACCEPTED: "ANNUAL_INCOME_CAP_ACCEPTED",

  // family
  HAVE_SPOUSE: "HAVE_SPOUSE",
  HAVE_CHILDREN: "HAVE_CHILDREN",
  YOUNGEST_CHILDS_BIRTHDAY: "YOUNGEST_CHILDS_BIRTHDAY",

  // driving info
  HAS_DRIVERS_LICENSE: "HAS_DRIVERS_LICENSE",
  NO_DRIVERS_LICENSE_REASON: "NO_DRIVERS_LICENSE_REASON",
  TRAFFIC_TICKET_COUNT: "TRAFFIC_TICKET_COUNT",
  TRAFFIC_TICKET_REASONS: "TRAFFIC_TICKET_REASONS",
  HAD_DUI: "HAD_DUI",

  // smoking
  NICOTINE_FREQUENCY: "NICOTINE_FREQUENCY",
  MARIJUANA_FREQUENCY: "MARIJUANA_FREQUENCY",
  CONSUME_MARIJUANA: "CONSUME_MARIJUANA",
  MARIJUANA_WEEKLY: "MARIJUANA_WEEKLY",
  MEDICINAL_MARIJUANA: "MEDICINAL_MARIJUANA",
  MEDICINAL_MJ_MD_NAME: "MEDICINAL_MJ_MD_NAME",
  MJ_MD_CITY: "MJ_MD_CITY",
  MJ_MD_STATE: "MJ_MD_STATE",
  ALCOHOL_FREQUENCY: "ALCOHOL_FREQUENCY",
  ALCOHOL_HABITS: "ALCOHOL_HABITS",
  ALCOHOL_EXCESS: "ALCOHOL_EXCESS",
  HAS_FELONY_OR_USED_ILLEGAL_DRUGS: "HAS_FELONY_OR_USED_ILLEGAL_DRUGS",
  HAS_PARTICIPATED_IN_HAZARDOUS_ACTIVITY: "HAS_PARTICIPATED_IN_HAZARDOUS_ACTIVITY",
  EXPANDED_HAZ_ACTIVITIES: "EXPANDED_HAZ_ACTIVITIES",
  HAZ_ACTIVITIES_TYPE: "HAZ_ACTIVITIES_TYPE",

  // health
  HEALTH_CONDITIONS: "HEALTH_CONDITIONS",
  ANNUAL_CHECKUP: "ANNUAL_CHECKUP",
  LAST_WELLNESS_EXAM: "LAST_WELLNESS_EXAM",
  PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE: "PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE",
  PENDING_TEST_OR_PROCEDURE: "PENDING_TEST_OR_PROCEDURE",
  PENDING_TEST_SERIOUS_OR_ROUTINE: "PENDING_TEST_SERIOUS_OR_ROUTINE",
  WHEN_WILL_YOU_HAVE_THE_RESULTS: "WHEN_WILL_YOU_HAVE_THE_RESULTS",
  HEALTH_CONDITIONS_ACKNOWLEDGED: "HEALTH_CONDITIONS_ACKNOWLEDGED",
  HAS_PRIMARY_CARE_PHYSICIAN: "HAS_PRIMARY_CARE_PHYSICIAN",
  PCP_NAME: "PCP_NAME",
  PCP_CITY: "PCP_CITY",
  PCP_STATE: "PCP_STATE",
  HOSPITALIZED: "HOSPITALIZED",
  HOSPITALIZED_TIMES: "HOSPITALIZED_TIMES",
  HOSPITALIZED_ADMITTED_DATE: "HOSPITALIZED_ADMITTED_DATE",
  HOSPITALIZED_NIGHTS: "HOSPITALIZED_NIGHTS",
  HOSPITALIZED_REASON: "HOSPITALIZED_REASON",
  HOSPITALIZED_ONGOING_TREATMENT: "HOSPITALIZED_ONGOING_TREATMENT",
  HOSPITALIZED_ONGOING_TREATMENT_METHOD: "HOSPITALIZED_ONGOING_TREATMENT_METHOD",
  HOSPITALIZED_ONGOING_TREATMENT_METHOD_MEDICATIONS: "HOSPITALIZED_ONGOING_TREATMENT_METHOD_MEDICATIONS",
  HOSPITALIZED_ONGOING_TREATMENT_SURGERY_EXPLICATION: "HOSPITALIZED_ONGOING_TREATMENT_SURGERY_EXPLICATION",
  HOSPITALIZED_ONGOING_TREATMENT_METHOD_OTHER: "HOSPITALIZED_ONGOING_TREATMENT_METHOD_OTHER",
  FAMILY_HISTORY: "FAMILY_HISTORY",
  HAS_AIDS_ARC_HIV_DIAGNOSIS: "HAS_AIDS_ARC_HIV_DIAGNOSIS",
  // These are only used for retrieving options
  FAMILY_HISTORY_0_RELATIONSHIP: `FAMILY_HISTORY_0_RELATIONSHIP`,
  FAMILY_HISTORY_0_DIAGNOSIS: `FAMILY_HISTORY_0_DIAGNOSIS`,
  FAMILY_HISTORY_0_SEVERITY: `FAMILY_HISTORY_0_SEVERITY`,

  // confirm identity
  PLACE_BORN: "PLACE_BORN",
  MOBILE_NUMBER: "MOBILE_PHONE_NUMBER",

  // beneficiary
  BENEFICIARY_TYPE: "BENEFICIARY_TYPE",
  BENEFICIARY_RELATIONSHIP: "BENEFICIARY_RELATIONSHIP",
  BENEFICIARY_FIRST_NAME: "BENEFICIARY_FIRST_NAME",
  BENEFICIARY_LAST_NAME: "BENEFICIARY_LAST_NAME",
  BENEFICIARY_BIRTH_DATE: "BENEFICIARY_BIRTH_DATE",
  BENEFICIARY_EMAIL_ADDRESS: "BENEFICIARY_EMAIL_ADDRESS",
  BENEFICIARY_MOBILE_PHONE_NUMBER: "BENEFICIARY_MOBILE_PHONE_NUMBER",
  BENEFICIARY_HAS_FINANCIAL_IMPACT: "BENEFICIARY_HAS_FINANCIAL_IMPACT",
  BENEFICIARY_GRANT_POLICY_ACCESS: "BENEFICIARY_GRANT_POLICY_ACCESS",
  BENEFICIARY_TRUST_TYPE: "BENEFICIARY_TRUST_TYPE",
  BENEFICIARY_TRUST_FORMATION_DATE: "BENEFICIARY_TRUST_FORMATION_DATE",
  BENEFICIARY_TRUST_NAME: "BENEFICIARY_TRUST_NAME",

  // attestations
  HAS_EXISTING_COVERAGE: "HAS_EXISTING_COVERAGE",
  EXISTING_COVERAGE_AMOUNT: "EXISTING_COVERAGE_AMOUNT",
  IS_REPLACEMENT_POLICY: "IS_REPLACEMENT_POLICY",
  HAS_ACCEPTED_MILITARY_SALES_DISCLOSURE: "HAS_ACCEPTED_MILITARY_SALES_DISCLOSURE",
  DISABILITY_BENEFITS: "DISABILITY_BENEFITS",
  DISABILITY_BENEFITS_DIAGNOSIS: "DISABILITY_BENEFITS_DIAGNOSIS",
  DISABILITY_BENEFITS_TREATMENT: "DISABILITY_BENEFITS_TREATMENT",
  DISABILITY_BENEFITS_TREATMENT_METHOD: "DISABILITY_BENEFITS_TREATMENT_METHOD",
  DISABILITY_BENEFITS_TREATMENT_METHOD_MEDICATIONS: "DISABILITY_BENEFITS_TREATMENT_METHOD_MEDICATIONS",
  DISABILITY_BENEFITS_TREATMENT_SURGERY_EXPLICATION: "DISABILITY_BENEFITS_TREATMENT_SURGERY_EXPLICATION",
  DISABILITY_BENEFITS_TREATMENT_METHOD_OTHER: "DISABILITY_BENEFITS_TREATMENT_METHOD_OTHER",
  DISABILITY_BENEFITS_TREATMENT_WHEN_SURGERY: "DISABILITY_BENEFITS_TREATMENT_WHEN_SURGERY",
  DISABILITY_BENEFITS_ONGOING: "DISABILITY_BENEFITS_ONGOING",

  // payment
  SECONDARY_BILLING_NOTIF: "SECONDARY_BILLING_NOTIF",
  SECONDARY_BILLING_NOTIF_FIRST_NAME: "SECONDARY_BILLING_NOTIF_FIRST_NAME",
  SECONDARY_BILLING_NOTIF_LAST_NAME: "SECONDARY_BILLING_NOTIF_LAST_NAME",
  SECONDARY_BILLING_NOTIF_EMAIL: "SECONDARY_BILLING_NOTIF_EMAIL",
  SECONDARY_BILLING_NOTIF_PHONE: "SECONDARY_BILLING_NOTIF_PHONE",
  SECONDARY_BILLING_NOTIF_ADDRESS_1: "SECONDARY_BILLING_NOTIF_ADDRESS_1",
  SECONDARY_BILLING_NOTIF_ADDRESS_2: "SECONDARY_BILLING_NOTIF_ADDRESS_2",
  SECONDARY_BILLING_NOTIF_CITY: " SECONDARY_BILLING_NOTIF_CITY",
  SECONDARY_BILLING_NOTIF_STATE: "SECONDARY_BILLING_NOTIF_STATE",
  SECONDARY_BILLING_NOTIF_ZIP: "SECONDARY_BILLING_NOTIF_ZIP",
  SSN: "SSN",
  DRIVERS_LICENSE_NUMBER: "DRIVERS_LICENSE_NUMBER",
  DRIVERS_LICENSE_STATE: "DRIVERS_LICENSE_HOME_STATE",
};

export const heightFeetValues = [
  { searchText: "4", text: "4", value: "4" },
  { searchText: "5", text: "5", value: "5" },
  { searchText: "6", text: "6", value: "6" },
  { searchText: "7", text: "7", value: "7" },
];

export const heightInchesValues = [
  { searchText: "0", text: "0", value: "0" },
  { searchText: "1", text: "1", value: "1" },
  { searchText: "2", text: "2", value: "2" },
  { searchText: "3", text: "3", value: "3" },
  { searchText: "4", text: "4", value: "4" },
  { searchText: "5", text: "5", value: "5" },
  { searchText: "6", text: "6", value: "6" },
  { searchText: "7", text: "7", value: "7" },
  { searchText: "8", text: "8", value: "8" },
  { searchText: "9", text: "9", value: "9" },
  { searchText: "10", text: "10", value: "10" },
  { searchText: "11", text: "11", value: "11" },
];

export const genderValues = Object.freeze([
  { text: "Female", value: "FEMALE" },
  { text: "Male", value: "MALE" },
]);

export const trueFalseItems = [
  { text: "Yes", value: "true" },
  { text: "No", value: "false" },
];

export const benefitTermCode = {
  UNSPECIFIED: "UNSPECIFIED",
  DEFAULT: "DEFAULT",
  INSURED_AGE: "INSURED_AGE",
  CHILD_AGE: "CHILD_AGE",
  MINIMUM_POLICY: "MINIMUM_POLICY",
  CHILD_INDEPENDENT: "CHILD_INDEPENDENT",
};

export const benefitType = {
  UNSPECIFIED: "UNSPECIFIED",
  INCOME_REPLACEMENT: "INCOME_REPLACEMENT",
  EXPENSE_COVERAGE: "EXPENSE_COVERAGE",
};

export const categories = {
  HEALTH_DIAGNOSIS: "HEALTH_DIAGNOSIS",
  HEALTH_FOLLOWUP: "HEALTH_FOLLOWUP",
};

export const healthConditions = {
  SUBSTANCE_ABUSE: "SUBSTANCE_ABUSE",
};

export const questionConcerns = {
  DIAGNOSIS: "DIAGNOSIS",
  OTHER: "OTHER",
  DIAGNOSED_WHEN: "DIAGNOSED_WHEN",
  TREATMENT_END_DATE: "TREATMENT_END_DATE",
  CURRENTLY_TREATED: "CURRENTLY_TREATED",
  TREATMENT: "TREATMENT",
  SYMPTOM_WHEN: "SYMPTOM_WHEN",
  COMPLICATIONS: "COMPLICATIONS",
  MEDICATION: "MEDICATION",
  WHEN_SURGERY: "WHEN_SURGERY",
  A1C: "A1C",
  FAMILY_HISTORY: "FAMILY_HISTORY",
  TREATMENT_SURGERY_EXPLICATION: "TREATMENT_SURGERY_EXPLICATION",
  TREATMENT_OTHER_EXPLICATION: "TREATMENT_OTHER_EXPLICATION",
  RELAPSE: "RELAPSE",
};

export const diagnosisConcerns = {
  DIAGNOSIS: "DIAGNOSIS",
  OTHER: "OTHER",
};

export const diagnosisDetailsConcerns = {
  DIAGNOSED_WHEN: "DIAGNOSED_WHEN",
  TREATMENT: "TREATMENT",
  TREATMENT_END_DATE: "TREATMENT_END_DATE",
  CURRENTLY_TREATED: "CURRENTLY_TREATED",
  SYMPTOM_WHEN: "SYMPTOM_WHEN",
  COMPLICATIONS: "COMPLICATIONS",
  MEDICATION: "MEDICATION",
  WHEN_SURGERY: "WHEN_SURGERY",
  A1C: "A1C",
  FAMILY_HISTORY: "FAMILY_HISTORY",
  TREATMENT_SURGERY_EXPLICATION: "TREATMENT_SURGERY_EXPLICATION",
  TREATMENT_OTHER_EXPLICATION: "TREATMENT_OTHER_EXPLICATION",
  RELAPSE: "RELAPSE",
};

export const excludeConcerns = {
  DIAGNOSIS: [questionConcerns.OTHER],
  OTHER: [],
  DIAGNOSED_WHEN: [],
  TREATMENT_END_DATE: [],
  CURRENTLY_TREATED: [],
  TREATMENT: [
    questionConcerns.MEDICATION,
    questionConcerns.WHEN_SURGERY,
    questionConcerns.TREATMENT_SURGERY_EXPLICATION,
    questionConcerns.TREATMENT_OTHER_EXPLICATION,
  ],
  SYMPTOM_WHEN: [],
  COMPLICATIONS: [],
  MEDICATION: [],
  WHEN_SURGERY: [],
  A1C: [],
  FAMILY_HISTORY: [],
  TREATMENT_SURGERY_EXPLICATION: [],
  RELAPSE: [],
};

export const beneficiaryRelationshipReadable = {
  UNDEFINED: "Undefined",
  SPOUSE_OR_PARTNER: "Spouse or partner",
  EX_SPOUSE_OR_PARTNER: "Ex-spouse or partner",
  ONE_CHILD: "Child",
  ALL_CHILDREN: "All your children equally",
  PARENT: "Parent",
  SIBLING: "Sibling",
  NIECE_OR_NEPHEW: "Niece or nephew",
  AUNT_OR_UNCLE: "Aunt or uncle",
  GRANDPARENT: "Grandparent",
  GRANDCHILD: "Grandchild",
  FRIEND: "Friend",
  TRUST: "Trust",
  ESTATE: "Estate",
  OTHER: "Someone else",
  SOMEONE_ELSE: "Someone else",
};

export const interviewStatus = {
  NEW: "NEW",
  INCOMPLETE: "INCOMPLETE",
  INELIGIBLE: "INELIGIBLE",
  READY_TO_SIGN: "READY_TO_SIGN",
  AWAITING_SIGNATURE_COMPLETION: "AWAITING_SIGNATURE_COMPLETION",
  AWAITING_SIGNATURE_CONFIRMATION: "AWAITING_SIGNATURE_CONFIRMATION",
  READY_TO_SUBMIT: "READY_TO_SUBMIT",
  NEEDS_REVIEW: "NEEDS_REVIEW",
  SUBMITTED: "SUBMITTED",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  ERROR: "ERROR",
  EXPIRED: "EXPIRED",
  INACTIVE: "INACTIVE",
  READY_FOR_CHECKOUT: "READY_FOR_CHECKOUT",
};

export const interviewInteraction = {
  RECEIVED_FINALIZED_QUOTE: "RECEIVED_FINALIZED_QUOTE",
  PAYMENT_INFO_ENTERED: "PAYMENT_INFO_ENTERED",
  APPLICATION_SIGNED: "APPLICATION_SIGNED",
};

export const interviewStepType = {
  CHECKOUT: "Apply",
  HEALTH_REFLEXIVE: "Health Reflexive",
  INFORMATION: "Personalized Quote",
};

export const childExtensionEligibleMinTermLength = 180;

export const InputType = {
  TEXT: "text",
  DATE: "date",
  HEIGHT: "height",
  BUTTON_GROUP: "button_group",
};

export const ValueType = {
  UNSPECIFIED_VALUE_TYPE: "UNSPECIFIED_VALUE_TYPE",
  STRING: "STRING",
  INTEGER: "INTEGER",
  FLOAT: "FLOAT",
  BOOL: "BOOL",
  DATE: "DATE",
  OCCUPATION_NAME: "OCCUPATION_NAME",
  OCCUPATION_KEY: "OCCUPATION_KEY",
  MEDICATION_NAME: "MEDICATION_NAME",
  MEDICATION_KEY: "MEDICATION_KEY",
  STATE_OR_COUNTRY: "STATE_OR_COUNTRY",
  APPROXIMATE_DATE: "APPROXIMATE_DATE",
  PAST_DATE: "PAST_DATE",
  FUTURE_DATE: "FUTURE_DATE",
  ZIP: "ZIP",
  ZIP_PLUS_4: "ZIP_PLUS_4",
  STATE: "STATE",
  STATE_CODE: "STATE_CODE",
  COUNTRY: "COUNTRY",
  DIAGNOSIS_DESCRIPTION: "DIAGNOSIS_DESCRIPTION",
  DIAGNOSIS_CODE: "DIAGNOSIS_CODE",
  CURRENCY_US_DOLLAR: "CURRENCY_US_DOLLAR",
  PAST_APPROXIMATE_DATE: "PAST_APPROXIMATE_DATE",
  FUTURE_APPROXIMATE_DATE: "FUTURE_APPROXIMATE_DATE",
  FAMILY_HISTORY_ANSWER: "FAMILY_HISTORY_ANSWER",
};

export const TextInputSize = {
  SMALL: "small",
  MEDIUM: "medium",
  FULL: "full",
  NAME: "name",
  DEFAULT: "default",
};
