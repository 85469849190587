// import { ref } from "vue";

import { DATE_KEYS, KEYS } from "../utils";

export function useMask() {
  // Date utils
  const MONTH_DAY_YEAR = "date";
  const MONTH_YEAR = "month";

  // Masking
  // Date mask
  function maskLongDate(e, key, length) {
    // @TODO Improve changing specific char in input values
    // const inputValues = e.target.value.trim().split("/");
    // if (inputValues.length === 3 && key > 3 && !inputValues[1].length) e.target.value = `${inputValues[0]}/0${key}/${inputValues.at(-1)}`
    const value = e.target.value;
    // @BUG Safari seems to be having an issue with .at(index) for a string so used [index]
    if (length === 1 && value[0] === "1" && key > 2) e.preventDefault();
    // Don't allow DD to be great than 31
    if ((length === 2 || length === 3) && key > 3) {
      e.target.value += `0${key}`;
      e.preventDefault();
    }
    if (length === 4 && value.slice(-1) === "3" && key > 1) e.preventDefault();

    // If they don't add the slash, add it for them
    if (length === 5) e.target.value += "/";
    if ((length === 5 || length === 6) && key === 0) e.preventDefault();
  }

  function maskShortDate(e, key, length) {
    // @TODO Improve changing specific char in input values and simplify preventDefault
    if (length > 6) e.preventDefault();

    const value = e.target.value;
    // @BUG Safari seems to be having an issue with .at(index) for a string so used [index]
    if (length === 1 && value[0] === "1" && key > 2) e.preventDefault();

    //if (length === 1 &&  && (key > 2)) e.preventDefault();
    if ((length === 2 || length === 3) && (key > 2 || key === 0)) {
      e.preventDefault();
    }
  }

  // Mask dates as MM/DD/YYYY or MM/YYYY
  function maskDate(e, mode = MONTH_DAY_YEAR) {
    // Only allow keyCode
    if (e.key === KEYS.Enter) return;
    if (!(e.key in DATE_KEYS)) e.preventDefault();

    const key = Number(e.key); // Convert key to number
    const length = e.target.value.length;
    // If we're at a particular place, let the user type the slash
    if (length !== 1 || length !== 3) {
      if (e.key === DATE_KEYS["/"]) e.preventDefault();
    }
    // If they don't start single digit month with 0, add it for them.
    if (!length && key !== 1 && key !== 0) e.target.value = `0${e.target.value}`;

    // If they don't add the slash, add it for them
    if (length === 2) e.target.value += "/";

    // If mode is long
    if (mode === MONTH_DAY_YEAR) maskLongDate(e, key, length);

    // If mode is short
    if (mode === MONTH_YEAR) maskShortDate(e, key, length);
    // EMD
  }

  let rawValue = "";

  const maskInput = (value, mask = "•") => mask.repeat(value.length);

  // SSN Mask
  const formatSocial = (value) => {
    if (value.length > 3) value = `${value.substring(0, 3)}-${value.substring(3)}`;
    if (value.length > 5) value = `${value.substring(0, 6)}-${value.substring(6)}`;
    return value;
  };

  function maskSSN(value) {
    // if (!value) return { rawValue: "", formattedValue: "", maskedValue: "" };
    // let currentInput = value.replace(/\D/g, "");

    if (rawValue.length > 9) rawValue = rawValue.slice(0, 9);
    if (rawValue.length < 9) rawValue += /^\d$/.test(value) ? value : "";

    return {
      rawValue: rawValue,
      formattedValue: formatSocial(rawValue),
      maskedValue: formatSocial(maskInput(rawValue)),
    };
  }

  const displaySSN = (value) => {
    const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;
    if (ssnPattern.test(value)) return "•••-••-" + value.slice(-4);
    return value;
  };
  // END

  function maskDL(value) {
    rawValue += value;

    return {
      rawValue: rawValue,
      formattedValue: rawValue,
      maskedValue: maskInput(rawValue),
    };
  }

  return {
    maskDate,
    maskSSN,
    displaySSN,
    rawValue,
    formatSocial,
    maskInput,
    maskDL,
  };
}
