import { inject } from "vue";

import { FlagsmithFlagger, NoOpFlagger } from "../services/features";

export const FeatureFlagClientKey = "featureFlagClientKey";
export const FeatureFlagProviderNameKey = "featureFlagProviderNameKey";

export function useFeatureFlags() {
  return inject(FeatureFlagClientKey);
}

export const FeaturesPlugin = {
  install: (app, options) => {
    let client = new NoOpFlagger();
    let provider = "noop";
    if (options) {
      provider = options.provider || "noop";
      if (provider === "flagsmith" && options.settings) {
        client = new FlagsmithFlagger(options);
        client.init();
      }
    }
    if (!client) {
      throw new Error("Feature flagging was not configured correctly");
    }
    app.provide(FeatureFlagClientKey, client);
    app.provide(FeatureFlagProviderNameKey, provider);
    app.config.globalProperties.$features = client;
  },
};
