import { onBeforeUnmount, onMounted, ref } from "vue";

/**
 *
 *
 * @param {function} callback
 * @param {object} options
 * @returns {function:targetRef, function:entryRefs}
 */
export function useIntersectionObserver(callback, options = { rootMargin: "0px", threshold: 0.1 }) {
  const target = ref(null);
  const targetRef = (element) => (target.value = element);
  const entries = ref([]);
  const entryRefs = (element, index) => (entries.value[index] = element);
  let observer;
  const { rootMargin, threshold } = options;

  onMounted(() => {
    observer = new IntersectionObserver(callback, {
      root: target.value,
      rootMargin,
      threshold,
    });

    for (const element of entries.value) {
      observer.observe(element);
    }
  });

  onBeforeUnmount(() => {
    observer.disconnect();
  });

  return {
    targetRef,
    entryRefs,
  };
}
