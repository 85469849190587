import { useLog } from "@common/composables";
import { STORAGE_KEYS, useSessionStorage } from "@composables";

import { PATHS, routeNames, SLUGS } from "../constants";
import Funnel from "../data/funnel.json";
import { useEstimateStore } from "../store/estimate.store";

const { log } = useLog();

export const quoteRoutes = [
  {
    path: PATHS.QUOTE,
    redirect: { name: routeNames.Q_SLUG, params: { slug: SLUGS.dob } },
    name: routeNames.QUOTE,
    component: () => import(/* webpackChunkName: "q-module" */ "../pages/v3/QuoteModule.vue"),
    async beforeEnter(to) {
      const estimateStore = useEstimateStore();
      const { get } = useSessionStorage();
      // Check is there is store values for lead and estimate
      if (to.params.slug === "estimate" && (!estimateStore.hasProp("$lead") || !estimateStore.hasProp("$estimate"))) {
        // If not check session storage for lead and estimate values
        const lead = get(STORAGE_KEYS.DF_LEAD);
        const estimate = get(STORAGE_KEYS.DF_ESTIMATE);

        // If no lead or estimate route back to the start
        if (!lead || !estimate) {
          log("Estimate: No valid data in storage");
          return { name: routeNames.Q_SLUG, params: { slug: SLUGS.dob } };
        }
        // Otherwise set store to session values
        estimateStore.setState("$lead", lead);
        estimateStore.setState("$estimate", estimate);
      }
      // END
    },
    children: [
      {
        path: ":slug",
        name: routeNames.Q_SLUG,
        component: () => import(/* webpackChunkName: "q-slug" */ "../pages/v3/QuoteSlug.vue"),
        beforeEnter: (to) => {
          const { get } = useSessionStorage();
          const traits = get(STORAGE_KEYS.DF_TRAITS);

          // New quote experiment routing logic
          const [slugs] = Funnel;
          const { slug } = to.params;
          if (("slug" in to.params && !slugs.includes(slug)) || (slug !== SLUGS.dob && !traits)) {
            return { name: routeNames.Q_SLUG, params: { slug: SLUGS.dob } };
          }
          // END
        },
      },
    ],
  },
];
