import { useAnalytics } from "@common";
import { useErrors, useReporter, useValidation } from "@common/composables";
import FUNNEL from "@common/data/funnel.json";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { routeNames, SLUGS } from "../constants";
import { useApollo } from "../plugins/apollo";
import { useEstimateStore } from "../store/estimate.store";
import { useQuestions, useQuoteEstimate } from "./api";
import { useDecision } from "./desision";
import { useExperiment } from "./experiment";
import { useForm } from "./form";
import { useRequired } from "./required";
import { STORAGE_KEYS, useSessionStorage } from "./storage";
import { useTraversables } from "./traversables";

// @TODO Refactor after intial spike testing only
const names = FUNNEL.at(-1); // Route names for interview
const [, keys] = FUNNEL;
// Set up the funnel initial config
const transformFunnel = (collection) => {
  return collection.reduce(
    (a, k, i) => {
      const flatKeys = k.flatMap((v) => v);
      // Skip has children grouping for applicant frontend because we ask this in the quick quote
      if (flatKeys.includes("HAVE_CHILDREN")) return a;
      a.steps = [
        ...a.steps,
        { name: names[i], keys: flatKeys, ...(names[i] === "quote" && { routeParams: { type: "quote" } }) },
      ];
      return a;
    },
    { version: "1.0.0", steps: [] },
  );
};
const funnel = ref(transformFunnel(keys));

export function useFunnel() {
  // Mocked query call for quote estimate
  const { queryQuestion } = useQuestions();
  const question = async (slug, answers, direction = "to") => {
    const response = await queryQuestion(slug, answers, direction);
    const duration = direction === "to" ? 330 : 0;
    await new Promise((resove) => setTimeout(() => resove(true), duration));
    return response;
  };
  // END

  // Track analytics events
  const route = useRoute();
  const analytics = useAnalytics();
  const { bucket } = useExperiment();
  const analyticsEffect = () => {
    analytics.trackEvent("next_quote_multistep", {
      button_location: "quote",
      page: `${route.params.slug}`,
      region: "quote_multistep",
      url: route.path,
      experiment: bucket.value,
    });
  };

  // END

  // Handle progressing through the form
  const { push, replace } = useRouter();
  const { errors, hasErrors } = useErrors();
  const { formData } = useForm();

  //
  const reporter = useReporter();
  const apollo = useApollo();
  const estimateStore = useEstimateStore();
  const { queryQuoteEstimateOverride } = useQuoteEstimate(apollo);
  const { hasChildren, nicotineUser, personalIncome } = useDecision();
  const { remove, set } = useSessionStorage();
  const { incomeCeil, incomeMax, incomeOverCeil, incomeWithinThresholds, incomeWithinMaxThresholds } = useDecision();
  const { submitted } = useForm();
  const queryEstimate = async () => {
    // Validate income threshold
    const hasHouseholdIncome = !personalIncome(formData.value);
    const income = !hasHouseholdIncome
      ? formData.value[SLUGS.income].answers.ANNUAL_PRE_TAX_INCOME
      : formData.value[SLUGS.supplemental].answers.SPOUSE_ANNUAL_INCOME;
    const withinThresholds = !hasHouseholdIncome ? incomeWithinThresholds(income) : incomeWithinMaxThresholds(income);

    if (!withinThresholds) {
      const ceil = !hasHouseholdIncome ? incomeCeil : incomeMax;
      let reason = "below lower"; // Assumption is that the income is to low
      if (incomeOverCeil(income, ceil)) reason = "above upper";
      analytics.trackEvent(`prequote_income`, { reason: `Income was ${reason} threshold limits.` });
      replace({ name: routeNames.OFF_RAMP_INELIGIBLE });
      remove(STORAGE_KEYS.DF_TRAITS);
      submitted.value = false;
      return;
    }
    //

    // Validate date of birth
    const { valid: withInAgeBounds, max } = validateDOB(formData.value[SLUGS.dob].answers.DATE_OF_BIRTH); // validate dob is within thresholds
    if (!withInAgeBounds) {
      analytics.trackEvent(`prequote_age`, { reason: `Age was over threshold limit of ${max}.` });
      replace({ name: routeNames.OFF_RAMP_INELIGIBLE });
      remove(STORAGE_KEYS.DF_TRAITS);
    }

    const { payload, success, message } = await queryQuoteEstimateOverride({
      dob: formData.value[SLUGS.dob].answers.DATE_OF_BIRTH,
      childDob: hasChildren(formData.value[SLUGS.children].answers.HAVE_CHILDREN)
        ? formData.value[SLUGS.youngest].answers.YOUNGEST_CHILDS_BIRTHDAY
        : null,
      class: nicotineUser(formData.value) ? "FOUR" : "ONE",
      state: formData.value[SLUGS.location].answers.HOME_STATE,
      gender: formData.value[SLUGS.gender].answers.IDENTIFY_AS_GENDER,
      ...(!hasHouseholdIncome && { annualIncome: formData.value[SLUGS.income].answers.ANNUAL_PRE_TAX_INCOME }),
      ...(hasHouseholdIncome && {
        annualHouseholdIncome: formData.value[SLUGS.supplemental].answers.SPOUSE_ANNUAL_INCOME,
      }),
    });

    // Server error
    if (!success.value) {
      reporter.error(message.value);
      submitted.value = false;
      return;
    }

    const { quoteEstimateOverride: estimate } = payload.value;

    // Estimate quoter failed
    if (!estimate.success) {
      // if (estimate.reason.includes("Twice-a-month")) {
      //   //setError(questionKeys.ANNUAL_INCOME, "Estimated annual income is to high.");
      // } else if (estimate.reason.includes("Please enter an income greater than $0")) {
      //   //setError(questionKeys.ANNUAL_INCOME, "Your estimated annual income must be between $24,000 and $500,000.");
      // } else {
      //   message.value = estimate.reason;
      //   serverError.value = true;
      // }
      reporter.error(estimate.reason);
      submitted.value = false;
      return;
    }
    // END

    // Setup estimated quote data
    const quote = estimate.quote;
    const haveChild = formData.value[SLUGS.children].answers.HAVE_CHILDREN;

    // @TODO Set this up to be dynamic so any changes in formdata will populate correctly without update
    const lead = {
      DATE_OF_BIRTH: formData.value[SLUGS.dob].answers.DATE_OF_BIRTH,
      HOME_STATE: formData.value[SLUGS.location].answers.HOME_STATE,
      IDENTIFY_AS_GENDER: formData.value[SLUGS.gender].answers.IDENTIFY_AS_GENDER,
      NO_CHILDREN: hasChildren(haveChild),
      ANNUAL_PRE_TAX_INCOME: formData.value[SLUGS.income].answers.ANNUAL_PRE_TAX_INCOME,
      YOUNGEST_CHILDS_BIRTHDAY: hasChildren(haveChild)
        ? formData.value[SLUGS.youngest].answers.YOUNGEST_CHILDS_BIRTHDAY
        : null,
      ...(hasHouseholdIncome && {
        SPOUSE_ANNUAL_INCOME: formData.value[SLUGS.supplemental].answers.SPOUSE_ANNUAL_INCOME,
      }),
    };
    set(STORAGE_KEYS.DF_LEAD, lead); // Set Session lead
    set(STORAGE_KEYS.DF_ESTIMATE, quote); // Set Session quote estimate
    // Set estimate store to payload
    estimateStore.setState("$lead", lead);
    estimateStore.setState("$estimate", quote);
    // END
    push({ name: routeNames.Q_SLUG, params: { slug: SLUGS.estimate } });
    submitted.value = false;
  };

  // Validate answers on next
  const { validateFullDate, validateShortDate, validateDOB, validateChildDOB } = useValidation();
  const { setError } = useErrors();
  const validateAnswersEffect = (slug, answers) => {
    // Validate income for q/income
    // If income is within threshold or over delete supplemental income data
    if (slug === SLUGS.income) {
      const income = answers.ANNUAL_PRE_TAX_INCOME;
      if (incomeWithinThresholds(income) || incomeOverCeil(income)) delete formData.value[SLUGS.supplemental];
    }
    // END

    // Validate dobs
    if (answers.DATE_OF_BIRTH) {
      const { valid: satisfied } = validateFullDate("DATE_OF_BIRTH", answers.DATE_OF_BIRTH); // Validate full date format
      if (satisfied) {
        const { valid, min } = validateDOB(answers.DATE_OF_BIRTH, "lower"); // Validate dob is over lower threshold
        if (!valid) {
          setError("DATE_OF_BIRTH", `You must be older than ${min}.`);
          reporter.error(`You must be older than ${min}.`);
        }
      }
    }

    if (slug === SLUGS.youngest && answers.YOUNGEST_CHILDS_BIRTHDAY) {
      const { valid } = validateShortDate("YOUNGEST_CHILDS_BIRTHDAY", answers.YOUNGEST_CHILDS_BIRTHDAY);
      // If valid formate validate child dob is within threshold and allow expecting threshold
      if (valid) validateChildDOB("YOUNGEST_CHILDS_BIRTHDAY", answers.YOUNGEST_CHILDS_BIRTHDAY, true);
    }
    // END
  };
  // END

  const { validateRequired } = useRequired();
  const { traversable } = useTraversables();
  const next = async () => {
    // Validate Input
    if (submitted.value) return;
    const { questions, slug } = traversable.value;
    const { answers } = formData.value[slug];

    //
    validateAnswersEffect(slug, answers);
    validateRequired(questions, answers);

    if (hasErrors()) {
      reporter.error({ ...errors });
      return;
    }
    // END

    // If valid
    submitted.value = true;
    analyticsEffect();

    const { payload, success } = await question(slug, formData.value);
    if (!success) reporter.log("Unable to find question");

    formData.value[slug].status = "complete";
    set(STORAGE_KEYS.DF_TRAITS, formData.value);

    if (payload.slug === SLUGS.estimate) {
      await queryEstimate();
      return;
    }

    push({ name: routeNames.Q_SLUG, params: { slug: payload.slug } });
    submitted.value = false;
  };
  // END

  // Handle routing back in the form
  const { clearErrors } = useErrors();
  const previous = async () => {
    const { slug } = traversable.value;
    if (hasErrors()) {
      clearErrors();
      formData.value[slug].answers = {};
    }
    const { payload } = await question(slug, formData.value, "from");
    push({ name: routeNames.Q_SLUG, params: { slug: payload.slug } });
  };
  // END

  // Set form data from previous local storage
  const { get } = useSessionStorage();
  onMounted(() => {
    // Prevent form data being populated if with interview
    const healthNames = [
      routeNames.INTERVIEW_HEALTH_CONDITIONS_DIAGNOSIS,
      routeNames.INTERVIEW_HEALTH_CONDITIONS_DETAILS,
    ];
    if (!names.includes(route.name) && !healthNames.includes(route.name)) {
      const value = get(STORAGE_KEYS.DF_TRAITS);
      if (value) formData.value = value;
    }
  });
  // END

  return {
    funnel,
    next,
    previous,
  };
}
