import { inject } from "vue";

import { ContentMap } from "../services/data";

export const ContentMapKey = "ContentMapKey";

export function useData() {
  return inject(ContentMapKey);
}

export const DataPlugin = {
  install: (app, options) => {
    const contentMap = new ContentMap(options?.language, options?.definitions);
    app.config.globalProperties.$data = contentMap;
    app.provide(ContentMapKey, contentMap);
  },
};
