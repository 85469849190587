import { isArray, isNull, KEYS } from "@dayforward/common";
import { useElement } from "@dayforward/common/src/composables";
import { onBeforeUpdate, onMounted, ref } from "vue";

import { useKeydown } from "./keyboard";

// @TODO Needs optimized, expanded and move to an affordance
function bind(element, values) {
  if (isArray(element)) {
    for (const e of element) {
      for (const value in values) {
        e.setAttribute(value, values[value]);
      }
    }
    return;
  }

  for (const value in values) {
    element.setAttribute(value, values[value]);
  }
}

export function useAccordion() {
  const { findElement } = useElement();

  const root = ref(null);
  const rootRef = (element) => {
    if (!element) return;
    root.value = findElement(element);
  };

  const triggers = ref([]);
  const triggerRef = (index) => (element) => {
    if (!element) return;
    triggers.value[index] = findElement(element);
  };

  const panels = ref([]);
  const panelRef = (index) => (element) => {
    if (!element) return;
    panels.value[index] = findElement(element);
  };

  // Active state
  const isActive = (i) => {
    return activeIndexes.value.includes(i);
  };

  // Handle trigger effects
  const activeIndex = ref(null);
  const activeIndexes = ref([]);
  const toggle = (e) => {
    const button = e.target.closest("button");
    const index = triggers.value.indexOf(button);
    const panel = panels.value[index];
    if (activeIndex.value === index) activeIndex.value = null;
    else activeIndex.value = index;

    if (isActive(index)) activeIndexes.value = activeIndexes.value.filter((ai) => ai !== index);
    else activeIndexes.value.push(index);
    bind(button, { "aria-expanded": index === activeIndex.value });
    bind(panel, { "aria-hidden": isNull(activeIndex.value) });
  };

  // Handle keyboard effects
  const keydownEffect = (e) => {
    const trigger = triggers.value.find((t) => t.id === e.target.id);
    if (trigger) {
      const index = triggers.value.indexOf(trigger);
      const next = triggers.value[index + 1];
      if (next) next.focus();
    }
  };

  const keyupEffect = (e) => {
    const trigger = triggers.value.find((t) => t.id === e.target.id);
    if (trigger) {
      const index = triggers.value.indexOf(trigger);
      const prev = triggers.value[index - 1];
      if (prev) prev.focus();
    }
  };

  const homeEffect = (e) => {
    const trigger = triggers.value.find((t) => t.id === e.target.id);
    if (trigger) triggers.value[0].focus();
  };

  const endEffect = (e) => {
    const trigger = triggers.value.find((t) => t.id === e.target.id);
    if (trigger) triggers.value[triggers.value.length - 1].focus();
  };

  useKeydown([
    { key: KEYS.ArrowDown, default: true, callback: keydownEffect },
    { key: KEYS.ArrowUp, default: true, callback: keyupEffect },
    { key: KEYS.Home, callback: homeEffect },
    { key: KEYS.End, callback: endEffect },
  ]);
  // END

  onBeforeUpdate(() => {
    triggers.value = [];
    panels.value = [];
    activeIndexes.value = [];
  });

  // Bind attributes
  onMounted(() => {
    for (const [i, trigger] of triggers.value.entries()) {
      bind(trigger, {
        id: `js-accordion-${i}`,
        "aria-expanded": i === activeIndex.value,
        "aria-controls": `js-region-${i}`,
      });
    }

    for (const [i, panel] of panels.value.entries()) {
      bind(panel, {
        role: `region`,
        id: `js-region-${i}`,
        "aria-labelledby": `js-accordion-${i}`,
        "aria-hidden": i !== activeIndex.value,
      });
    }
  });

  return {
    rootRef,
    triggerRef,
    panelRef,
    toggle,
    activeIndex,
    activeIndexes,
    isActive,
  };
}
