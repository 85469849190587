import { useReporter } from "@common/composables";
import { useAccount } from "@composables";
import { computed, ref } from "vue";

const user = ref(null);

export function useUser() {
  const reporter = useReporter();

  function normalizeUser(account) {
    if (account.interviews.length) return account.interviews.at(0).applicant;
    return account.interviewSummaries.at(0).applicant;
  }

  function setUser(account) {
    console.log(account)
    const applicant = normalizeUser(account);

    user.value = {
      ...account,
      ...applicant,
    };
  }

  async function getUser(apollo, token) {
    const { queryAccount } = useAccount(apollo);
    const { payload, success } = await queryAccount(token);
    if (!success.value) {
      reporter.warn("WARNING: Unable to query account, route to sign in");
      return { success: success.value };
    }
    setUser(payload.value.account);
    return { payload: payload.value.account, success: success.value };
  }

  // Temporary method - remove after conversion from stores to composables
  function setUserStore(store) {
    store.setState("$user", user.value);
    if (!user.value.interviews.length && !user.value.interviewSummaries.length)
      reporter.warn(`No interviews present on account ${user.value.id}`);
    if (!user.value.interviews.length && user.value.interviewSummaries.length)
      store.setState("$interviews", user.value.interviewSummaries);
    if (user.value.interviews.length) store.setState("$interviews", user.value.interviews);
  }
  // END

  return {
    getUser,
    setUser,
    setUserStore,
    user: computed(() => user.value),
  };
}
