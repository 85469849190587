import utm from "@segment/utm-params";

import { readCookie, storeCookie } from "../cookies";

export class NoOpAnalyticsReporter {
  fb() {
    const fbp = readCookie("_fbp");
    const fbc = readCookie("_fbc");
    return { fbp: fbp ? fbp : null, fbc: fbc ? fbc : null };
  }
  page({ path }, properties) {
    const fb = this.fb();
    console.log(`analytics page: ${path}`, { ...{ ...properties, ...fb }, ...this.getContext() });
    // no op
  }
  trackEvent(event, data) {
    const fb = this.fb();
    console.log(`analytics trackEvent: ${event}`, { ...data, ...fb });
    // no op
  }
  trackEventOnce(event) {
    console.log(`analytics trackEventOnce: ${event}`);
    // no op
  }

  identify({ accountId, traits }) {
    const fb = this.fb();
    console.log(`analytics identify: ${accountId}`, { ...traits, ...fb });
    // noop
  }

  getContext() {
    const cookieName = "analytics:utm";
    let context = utm(window.location.search) || {};
    if (context.source) {
      storeCookie(cookieName, JSON.stringify(context));
    } else {
      const fb = this.fb();
      context = readCookie(cookieName, JSON.parse);
      context = { ...context, ...fb };
    }
    return {
      campaign: context,
    };
  }

  isReady() {
    return true;
  }
}
