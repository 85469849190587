// ****DO NOT**** USE still unstable
import { computed, onMounted, ref, watchEffect } from "vue";

import { KEYS } from "../utils";
import { useElement } from "./element";

const body = document.body;
const focusableElements = `button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`;

const STATUS = {
  open: "open",
  closed: "closed",
};

// @TODO Extend and move to utils
function is(...args) {
  const [e, key] = args;
  if (key === "shift+tab") return e.shiftKey && KEYS.Tab;
  return e.key === key;
}

export function useModal() {
  const { findElement } = useElement();
  const main = ref(document.querySelector("main")); // @TODO Possibley move to composable to query main element
  const root = ref(null);
  const rootRef = (element) => {
    if (!element) return;
    root.value = findElement(element);
  };

  // Status
  const status = ref(STATUS.closed);
  function open() {
    status.value = STATUS.open;
  }

  function close() {
    status.value = STATUS.closed;
  }

  watchEffect(
    () => {
      switch (status.value) {
        case STATUS.open:
          status.value = STATUS.open;
          body.style.overflow = "hidden";
          break;
        case STATUS.closed:
          status.value = STATUS.closed;
          body.style.overflow = null;
          break;
      }
    },
    { flush: "post" },
  );
  // END

  // Focusable effect
  const focusableContent = ref([]);
  const firstFocusable = ref(null);
  const lastFocusable = ref(null);
  const trigger = ref(null);

  function elementsEffect() {
    if (status.value === STATUS.open) {
      focusableContent.value = [...root.value.querySelectorAll(focusableElements)];
      firstFocusable.value = focusableContent.value[0];
      lastFocusable.value = focusableContent.value[focusableContent.value.length - 1];
    }
  }

  function escapeEffect(e) {
    if (is(e, KEYS.Escape) && status.value === STATUS.open) {
      e.preventDefault();
      close();
    }
  }

  function tabEffect(e) {
    if (is(e, KEYS.Tab)) {
      // If shift key and active element is on first focusable element
      if (e.shiftKey && document.activeElement === firstFocusable.value) {
        e.preventDefault();
        lastFocusable.value.focus();
      }
      // If not shift key and active element is on last focusable element
      if (!e.shiftKey && document.activeElement === lastFocusable.value) {
        e.preventDefault();
        firstFocusable.value.focus();
      }
    }
  }

  function keydownEffect(e) {
    escapeEffect(e);
    tabEffect(e);
  }

  watchEffect(
    () => {
      switch (status.value) {
        case STATUS.open:
          elementsEffect();
          root.value.focus();
          main.value.setAttribute("aria-hidden", "true");
          document.addEventListener("keydown", keydownEffect);
          break;
        case STATUS.closed:
          if (trigger.value) trigger.value.focus();
          main.value.removeAttribute("aria-hidden");
          document.removeEventListener("keydown", keydownEffect);
          break;
      }
    },
    { flush: "post" },
  );

  onMounted(() => {
    trigger.value = document.activeElement;
  });
  // End

  return {
    dialog: {
      ref: rootRef,
      root,
      status: computed(() => status.value),
      open,
      close,
      is: {
        open: () => status.value === STATUS.open,
        closed: () => status.value === STATUS.closed,
      },
    },
  };
}
