import { onMounted, ref } from "vue";

import { useLocalStorage } from "./useStorage";

const theme = ref("light");

export function useTheme(defaultTheme = "light") {
  const { set } = useLocalStorage();
  const key = "current_mode";
  function themeEffect() {
    theme.value = theme.value === "light" ? "dark" : "light";
    set(key, theme.value);
    document.documentElement.setAttribute("data-theme", theme.value);
  }

  const { get } = useLocalStorage();
  onMounted(() => {
    const value = get(key);
    if (value) theme.value = value;
    else theme.value = defaultTheme;
    document.documentElement.setAttribute("data-theme", theme.value);
  });

  return {
    theme,
    themeEffect,
  };
}
