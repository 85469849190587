import { inject } from "vue";

import { ABClient } from "../services/ab";

export const ABClientKey = "abClient";

export function useAB() {
  return inject(ABClientKey);
}

export const ABPlugin = {
  install: (app, options) => {
    let client = options?.client;
    if (!client) {
      client = new ABClient(options.experiments, options);
    }
    app.provide(ABClientKey, client);
    app.config.globalProperties.$ab = client;
    window.ab = client; // don't use this unless you absolutely need to
  },
};
