import { datadogRum } from "@datadog/browser-rum";

export class DatadogConfig {
  constructor(options) {
    this.appicationId = options?.applicationId;
    this.clientToken = options?.clientToken;
    this.env = options?.env;
    this.service = options?.service;
    this.version = options?.version ?? "3.0.0";
  }
}

export const DatadogPlugin = {
  init: (options) => {
    const config = new DatadogConfig(options);
    datadogRum.init({
      applicationId: config.appicationId,
      clientToken: config.clientToken,
      site: "datadoghq.com",
      service: config.service,
      env: config.env,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: "mask-user-input",
      enableExperimentalFeatures: ["feature_flags", "clickmap"],
    });
    datadogRum.startSessionReplayRecording();
  },
};
