import { RiskClasses } from "../constants.js";

export class Quote {}

export class QuoteInputs {
	constructor(inputs) {
		this.riskScoreClass = inputs?.riskScoreClass || RiskClasses[0];
		this.dob = inputs?.dob;
		this.gender = inputs?.gender;
		this.annualIncome = inputs?.annualIncome?.replace(/,/g, "");
		this.childDob = inputs?.childDob;
		this.noKidsOrExpecting = inputs?.noKidsOrExpecting;
		this.children = inputs?.children;
		this.state = inputs?.state || "TX";
		this.annualHouseholdIncome = inputs?.annualHouseholdIncome?.replace(/,/g, "");
		this.incomeIncrease = inputs?.incomeIncrease;
		this.additionalChildren = inputs?.additionalChildren;
		this.monthlySpend = inputs?.monthlySpend;
		this.aware = inputs?.aware;
	}
}
