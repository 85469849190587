import { routeNames } from "../constants";

export const authRoutes = [
  {
    path: "/signin",
    name: routeNames.AUTH_SIGN_IN,
    component: () => import("../pages/Signin.vue"),
  },
  {
    path: "/signout",
    name: routeNames.AUTH_SIGN_OUT,
    component: () => import("../pages/v3/AuthSignOut.vue"),
    meta: {
      doNotTrack: true,
    },
  },
  {
    path: "/signin/magic-link",
    name: routeNames.AUTH_MAGIC_LINK,
    component: () => import("../pages/v3/AuthSignInMagicLink.vue"),
  },
  {
    path: "/signin/callback",
    name: routeNames.AUTH_CALLBACK,
    component: () => import("../pages/v3/AuthSignInCallback.vue"),
  },
];
