import { onBeforeUnmount, onBeforeUpdate, onMounted, ref } from "vue";

export function useScrollCarousel(threshold = 1) {
  const container = ref(null);
  const items = ref([]);
  const activeIndex = ref(0);
  let observer;

  function rootRef(element) {
    container.value = element;
  }

  function getRef(element, index) {
    items.value[index] = element;
  }

  onBeforeUpdate(() => {
    items.value = [];
  });

  onMounted(() => {
    observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0].target;
        const index = items.value.findIndex((element) => element.isSameNode(target)) || 0;
        activeIndex.value = index;
      },
      {
        root: container.value,
        threshold: threshold,
      },
    );

    for (const element of items.value) {
      observer.observe(element);
    }
  });

  onBeforeUnmount(() => {
    observer.disconnect();
  });
  return {
    rootRef,
    getRef,
    activeIndex,
  };
}
