export * from "./date.masks.js";
export * from "./defaults.js";
export * from "./numeric.masks.js";
export * from "./text.masks.js";

export const makeMask = (name, transformer, options = undefined) => {
  return { name, transformer, options };
};

export const evaluateMask = (mask, enteredValue) => {
  if (!mask || !mask.transformer || !(typeof mask.transformer === "function")) {
    return { clean: enteredValue, masked: enteredValue, prioritized: enteredValue };
  }
  return mask.transformer(enteredValue, mask?.options);
};
