<template>
  <AppGrid :class="$style.container" data-analytics-region="footer">
    <BaseLink data-analytics="logo" :class="$style.logo" :to="{ name: routeNames.HOME }" @click="trackEvent">
      <BaseLogo :class="$style['logo--footer']" />
    </BaseLink>
    <AppGridItem as="nav" role="navigation" aria-label="Footer" :class="$style.nav">
      <BaseLink light :to="{ name: routeNames.WHY_DAYFORWARD }" data-analytics="why_dayforward" @click="trackEvent">
        Why Dayforward
      </BaseLink>
      <BaseLink light :to="{ name: routeNames.ABOUT }" data-analytics="about" @click="trackEvent">About</BaseLink>
      <BaseLink light :to="{ name: routeNames.FAQ }" data-analytics="faqs" @click="trackEvent">FAQs</BaseLink>
      <BaseLink light :to="{ name: routeNames.REVIEWS }" data-analytics="reviews" @click="trackEvent">
        Reviews
      </BaseLink>
      <BaseLink light :to="{ name: routeNames.CAREERS }" data-analytics="careers" @click="trackEvent">Careers</BaseLink>
      <BaseLink light href="//dayforward.io" data-analytics="solutions" @click="trackEvent">
        Business Solutions
      </BaseLink>
      <BaseLink light :to="{ name: routeNames.CONTACT }" data-analytics="contact" @click="trackEvent">
        Contact us
      </BaseLink>
    </AppGridItem>
    <AppGridItem :class="$style.actions">
      <BaseButton :data-analytics="!auth ? 'signin' : 'signout'" variant="reverse" outline @click="handleAuth">
        {{ !auth ? "Sign in" : "Log out" }}
      </BaseButton>
    </AppGridItem>
    <AppGridItem :class="$style.contact">
      <BaseLink :href="`tel:${Contact.phone}`" light>{{ Contact.phone }}</BaseLink>
      <br />
      <BaseText as="span" size="sm">M-F: {{ Contact.hours.weekdays }}</BaseText>
      <br />
      <BaseText as="span" size="sm">Weekends: {{ Contact.hours.weekends }}</BaseText>
    </AppGridItem>
    <AppGridItem :class="$style.links">
      <AppGridItem as="nav" role="navigation" aria-label="Social" :class="$style.social">
        <BaseLink
          light
          href="https://www.instagram.com/dayfwrd/"
          data-analytics="instagram"
          target="_blank"
          aria-label="Instagram"
          rel="noopener noreferrer"
          @click="trackEvent"
        >
          <!-- eslint-disable-next-line prettier/prettier -->
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><rect width="24" height="24" rx="12" fill="#f2ede0"/><path d="M12 9.404a2.471 2.471 0 0 0-2.47 2.471 2.457 2.457 0 0 0 2.47 2.47 2.471 2.471 0 0 0 2.47-2.47A2.485 2.485 0 0 0 12 9.405Zm0 4.082a1.61 1.61 0 0 1-1.611-1.611c0-.88.709-1.59 1.611-1.59.88 0 1.59.71 1.59 1.59 0 .902-.71 1.611-1.59 1.611Zm3.137-4.168a.578.578 0 0 0-.58-.58.578.578 0 0 0-.58.58c0 .323.257.58.58.58.322 0 .58-.257.58-.58Zm1.633.58c-.043-.773-.215-1.46-.774-2.02-.559-.558-1.246-.73-2.02-.773-.794-.043-3.18-.043-3.974 0-.773.043-1.44.215-2.02.774-.558.559-.73 1.246-.773 2.02-.043.794-.043 3.18 0 3.974.043.774.215 1.44.773 2.02.58.558 1.247.73 2.02.773.795.043 3.18.043 3.975 0 .773-.043 1.46-.215 2.02-.773.558-.58.73-1.247.773-2.02.043-.795.043-3.18 0-3.975Zm-1.032 4.813c-.15.43-.494.752-.902.924-.645.258-2.149.193-2.836.193-.709 0-2.213.065-2.836-.193a1.63 1.63 0 0 1-.924-.924c-.258-.623-.193-2.127-.193-2.836 0-.688-.065-2.191.193-2.836.172-.408.494-.73.924-.902.623-.258 2.127-.194 2.836-.194.688 0 2.191-.064 2.836.194.408.15.73.494.902.902.258.645.194 2.149.194 2.836 0 .709.064 2.213-.194 2.836Z" fill="#1A113C"/></svg>
        </BaseLink>
        <BaseLink
          light
          href="https://www.facebook.com/dayfwrd"
          data-analytics="facebook"
          target="_blank"
          aria-label="Facebook"
          rel="noopener noreferrer"
          @click="trackEvent"
        >
          <!-- eslint-disable-next-line prettier/prettier -->
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><rect width="24" height="24" rx="12" fill="#f2ede0"/><path d="m14.557 12.563.3-1.977h-1.912v-1.29c0-.558.258-1.073 1.117-1.073h.881V6.525s-.795-.15-1.546-.15c-1.569 0-2.6.967-2.6 2.686v1.525H9.035v1.976h1.762v4.813h2.148v-4.813h1.612Z" fill="#1A113C"/></svg>
        </BaseLink>
        <BaseLink
          light
          href="https://twitter.com/dayfwrd"
          data-analytics="twitter"
          target="_blank"
          aria-label="Twitter"
          rel="noopener noreferrer"
          @click="trackEvent"
        >
          <!-- eslint-disable-next-line prettier/prettier -->
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><rect width="24" height="24" rx="12" fill="#f2ede0"/><path d="M16.361 9.64c.43-.322.817-.708 1.117-1.16-.386.172-.837.301-1.289.344.473-.28.817-.709.989-1.246-.43.258-.924.451-1.418.559a2.256 2.256 0 0 0-1.655-.71 2.256 2.256 0 0 0-2.255 2.257c0 .171.021.343.064.515a6.545 6.545 0 0 1-4.662-2.363c-.193.322-.3.709-.3 1.139 0 .773.386 1.46 1.009 1.869-.365-.022-.73-.108-1.031-.28v.022c0 1.096.773 1.998 1.804 2.213a2.517 2.517 0 0 1-.58.086c-.15 0-.279-.022-.43-.043A2.245 2.245 0 0 0 9.83 14.41a4.536 4.536 0 0 1-2.793.967c-.193 0-.365-.022-.537-.043a6.286 6.286 0 0 0 3.459 1.01c4.146 0 6.402-3.416 6.402-6.403v-.3Z" fill="#1A113C"/></svg>
        </BaseLink>
        <BaseLink
          light
          href="https://www.youtube.com/channel/UCqE-G0NYGPHPaHYEhNm9vbg/featured?view_as=subscriber"
          data-analytics="youtube"
          aria-label="Youtube"
          target="_blank"
          rel="noopener noreferrer"
          @click="trackEvent"
        >
          <!-- eslint-disable-next-line prettier/prettier -->
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><rect width="24" height="24" rx="12" fill="#f2ede0"/><path d="M17.607 9.06a1.461 1.461 0 0 0-1.03-1.052C15.651 7.75 12 7.75 12 7.75s-3.674 0-4.598.258a1.461 1.461 0 0 0-1.03 1.053c-.259.902-.259 2.835-.259 2.835s0 1.913.258 2.836c.129.516.537.903 1.031 1.032C8.326 16 12 16 12 16s3.652 0 4.576-.236a1.433 1.433 0 0 0 1.031-1.032c.258-.923.258-2.835.258-2.835s0-1.934-.258-2.836Zm-6.81 4.577v-3.48l3.05 1.74-3.05 1.74Z" fill="#1A113C"/></svg>
        </BaseLink>
        <BaseLink
          light
          href="https://linkedin.com/company/dayfwrd"
          data-analytics="linkedin"
          target="_blank"
          aria-label="LinkedIn"
          rel="noopener noreferrer"
          @click="trackEvent"
        >
          <!-- eslint-disable-next-line prettier/prettier -->
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><rect width="24" height="24" rx="12" fill="#f2ede0"/><path d="M9.336 16V9.576H7.338V16h1.998Zm-1.01-7.283c.645 0 1.16-.537 1.16-1.182a1.15 1.15 0 0 0-1.16-1.139c-.623 0-1.139.516-1.139 1.14 0 .644.516 1.18 1.14 1.18ZM16.791 16h.021v-3.523c0-1.72-.386-3.051-2.406-3.051-.966 0-1.611.537-1.89 1.031h-.022v-.88h-1.912V16h1.998v-3.18c0-.838.15-1.633 1.182-1.633 1.031 0 1.053.946 1.053 1.698V16h1.976Z" fill="#1A113C"/></svg>
        </BaseLink>
      </AppGridItem>
      <AppGridItem>
        <!-- eslint-disable-next-line prettier/prettier -->
        <a href="https://www.bbb.org/us/ny/new-york/profile/life-insurance/dayforward-0121-87169675/#sealclick" target="_blank" rel="nofollow" :class="$style.bbb"><img src="https://seal-newyork.bbb.org/seals/darkgray-seal-200-42-whitetxt-bbb-87169675.png" style="border: 0;" alt="Dayforward BBB Business Review" /></a>
      </AppGridItem>
    </AppGridItem>
    <AppGridItem as="nav" role="navigation" aria-label="Legal" :class="$style['nav--legal']">
      <BaseLink light size="sm" data-analytics="terms" :to="{ name: routeNames.TERMS }" @click="trackEvent">
        Terms of use
      </BaseLink>
      <BaseLink light size="sm" data-analytics="privacy" :to="{ name: routeNames.PRIVACY }" @click="trackEvent">
        Privacy policy
      </BaseLink>
    </AppGridItem>
    <AppGridItem :class="$style.legal">
      <BaseText size="sm">
        {{ legal }}
        <BaseLink light underline :to="{ name: routeNames.LICENSES }">
          {{ legal === Footnotes.legal.dlic ? "following states" : "See all state licenses" }}.
        </BaseLink>
      </BaseText>
    </AppGridItem>
    <BaseText size="sm" :class="$style.trademark">
      &copy; {{ year }} Dayforward Inc. All Rights Reserved. Dayforward is a trademark of Dayforward Inc.
    </BaseText>
  </AppGrid>
</template>

<script setup>
import { useAnalytics } from "@common";
import BaseButton from "@common/components/BaseButton.vue";
import BaseLink from "@common/components/BaseLink.vue";
import BaseLogo from "@common/components/BaseLogo.vue";
import BaseText from "@common/components/BaseText.vue";
import AppGrid from "@components/AppGrid.vue";
import AppGridItem from "@components/AppGridItem.vue";
import { useContent, useExperiment } from "@composables";
import { computed, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";

import { routeNames } from "../constants";
import Contact from "../data/contact.json";
import Footnotes from "../data/footnotes.json";
import { useAuthStore } from "../store/auth.store";
import { useUserStore } from "../store/user.store";

// Authenticated
const authStore = useAuthStore();
const auth = computed(() => authStore.authenticated);
// END

// Footer dynamic text
const userStore = useUserStore();
const { setFooter, legal, updateFooter } = useContent();
// hasFeature returns undefined before timout
watchEffect(
  () => {
    if (!userStore.interview) setFooter(Footnotes.legal.default);
    if (userStore.interview) updateFooter(null, userStore.interview);
  },
  {
    flush: "post",
  },
);
// END

// Analytics
const analytics = useAnalytics();
const route = useRoute();
const router = useRouter();
const { bucket } = useExperiment();
function trackEvent(e) {
  // Track footer navigation clicks
  const target = e.target.closest("a") || e.target.closest("button");
  const event = target.getAttribute("data-analytics");
  const current = router.options.history.state.current;
  const page = current === "/" ? "homepage" : current.substring(1);

  if (event) {
    const key = `app_click_${event}`;
    analytics.trackEvent(key, {
      button_location: "footer",
      page,
      region: "footer",
      url: route.path,
      experiment: bucket.value,
    });
  }
}

const handleAuth = (e) => {
  trackEvent(e);
  if (auth.value) return router.push({ name: routeNames.AUTH_SIGN_OUT });
  return router.push({ name: routeNames.AUTH_SIGN_IN });
};

// Footer trademark
const year = new Date().getFullYear();
</script>

<style lang="scss" module>
// Again with the a tag override with !important because of _text.scss line 3
.logo {
  border-bottom: 0 !important;
  &:hover {
    opacity: 0.8;
    border-bottom: 0 !important;
  }
}

.logo--footer {
  fill: var(--oat);
}

.container {
  align-items: flex-start;
  width: 90%;
  max-width: 1254px;
  padding: 5.5rem 0 7.5rem;
  margin-right: auto;
  margin-left: auto;
}

.nav,
.actions,
.contact,
.links,
.nav--legal,
.legal,
.trademark {
  grid-column-start: 1;
  grid-column-end: 3;
}

.nav {
  display: grid;
  gap: 0.75rem;
  grid-row: 2;
  align-items: flex-start;
}

.actions {
  grid-row: 3;
}

.contact {
  grid-row: 4;
}

.links {
  grid-row: 5;
}

.social {
  display: flex;
  gap: 0.75rem;
}

.bbb {
  display: inline-block;
  margin-top: 12px;
}

.nav--legal {
  display: flex;
  gap: 1.5rem;
  grid-row: 6;
  align-items: flex-start;
}

.legal {
  grid-row: 7;
}

.trademark {
  grid-row: 8;
}

@media (min-width: 600px) {
  // .nav--legal {
  //   grid-column: span 1 / -1;
  // }
  .nav,
  .actions,
  .links,
  .nav--legal,
  .legal,
  .trademark {
    grid-column-end: 5;
  }

  .contact {
    grid-column-end: 3;
  }
}

@media (min-width: 768px) {
  .nav,
  .nav--legal,
  .legal,
  .trademark {
    grid-column-end: 7;
  }
  .nav {
    grid-template-columns: 1fr 1fr;
    grid-row: 1;
    grid-column-start: 3;
  }

  .actions {
    grid-row: 2;
    grid-column-start: 3;
    grid-column-end: -1;
  }

  .contact {
    grid-row: 2;
  }

  .links {
    grid-row: 4;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .nav--legal {
    grid-row: 5;
    grid-column-start: 1;
  }

  .legal {
    grid-row: 6;
  }

  .trademark {
    grid-row: 7;
  }
}

@media (min-width: 992px) {
  .nav {
    grid-template-columns: 1fr 1fr;
    grid-column-start: 3;
    grid-column-end: 7;
    gap: 0.75rem 0;
  }

  .actions {
    grid-row: 1;
    grid-column-start: 7;
    grid-column-end: 9;
  }

  .nav--legal {
    grid-row: 4;
    grid-column-start: 3;
    align-items: flex-start;
  }

  .links {
    grid-row: 4;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .social {
    display: flex;
    gap: 0.75rem;
  }

  .legal {
    grid-row: 4;
    grid-column-start: 3;
    grid-column-end: 9;
    margin-top: 32px;
  }
  .trademark {
    grid-row: 5;
    grid-column-start: 3;
    grid-column-end: 9;
  }
}

@media (min-width: 1124px) {
  .nav {
    grid-column-start: 4;
    grid-column-end: 7;
  }

  // .actions {
  //   grid-row: 1;
  //   grid-column-start: 7;
  //   grid-column-end: 9;
  // }

  .nav--legal {
    grid-column-start: 4;
  }

  .legal {
    grid-column-start: 4;
  }
  .trademark {
    grid-column-start: 4;
  }
}

@media (min-width: 1200px) {
  .nav {
    grid-column-end: 8;
  }

  .actions,
  .legal,
  .trademark {
    grid-column-end: -1;
  }
  .actions {
    grid-column-start: 11;
  }
}
</style>
