import flagsmith from "flagsmith";
import { computed, ref } from "vue";

import Contact from "../data/contact.json";
import FOOTNOTES from "../data/footnotes.json";
import FOOTNOTES_LEGACY from "../data/footnotes.legacy.json";

const legal = ref("");
const phone = ref(Contact.phone);

export function useContent() {
  /**
   * Set footer to appropriated legal content
   * @param {string} content;
   * @returns string
   */
  function setFooter(content) {
    legal.value = content;
    return legal.value;
  }

  /**
   * Updates footer text to appropriated legal text
   * @param {string} state;
   * @param {string} released; // If ctl release
   * @returns string
   */
  function updateFooter(state, interview = null) {
    const footnotes = flagsmith.hasFeature("florida_release") ? FOOTNOTES : FOOTNOTES_LEGACY;
    // Set footer copy base on interview vendor vs state if interview
    if (interview) {
      const { product } = interview;
      return product.vendor === "CTL" ? setFooter(footnotes.legal.ctl) : setFooter(footnotes.legal.dlic);
    }
    return setFooter(footnotes.legal.ctl);
  }

  /**
   * Specify which consent notice information to display specific to CTL-ICC & GA or WI
   * @param {string} state;
   * @returns boolean
   */
  function specificNotice(state) {
    const specifiedStates = ["GA", "WI"];
    // With release of migrate dlic to ctl return specifiedState
    return specifiedStates.includes(state);
  }

  function sourceContent(sources) {
    let defaultTitle = "Term life insurance that protects your family’s way of life.";
    let defaultSubcontent = "If the worst were to happen, know that your family will continue to receive your income.";

    if (sources.includes("tiktok")) {
      defaultTitle = "Life insurance that protects your income.";
      defaultSubcontent =
        "So, if you pass away, your family will keep getting your income twice a month, just like a paycheck, until your kids are grown up. That way, their way of life can stay the same.";
    }

    return {
      defaultTitle,
      defaultSubcontent,
    };
  }

  function updatePhone(state, interview = null) {
    if (interview) {
      const { product } = interview;
      if (product.vendor === "CTL") phone.value = Contact.ctl.phone;
    } else phone.value = Contact.ctl.phone;
    return phone.value;
  }
  return {
    legal: computed(() => legal.value),
    phone,
    setFooter,
    updateFooter,
    updatePhone,
    specificNotice,
    sourceContent,
  };
}
