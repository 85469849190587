export const STORAGE_KEYS = Object.freeze({
  DF_SESSION_ID: "--df.user.sessionId",
  DF_SESSION: "df:session",
  DF_USER: "df:user",
  DF_WAITLIST: "df:waistlist",
  DF_IMO_REDIRECT: "df:imo:redirect",
  DF_DISMISS_NOTICE: "df:imo:notice",
  DF_ESTIMATE: "df:estimate",
  DF_LEAD: "df:lead",
  DF_LEVEL: "df:level",
  DF_HEALTH: "df:health",
  DF_MARKER: "df:marker",
  DF_AGENT_ID: "imo:agentId",
  DF_EMAIL: "auth.email",
  DF_EXPERIMENT: "df:experiment",
  DF_SESSION_PROFILE: "df:session:profile",
  DF_TRAITS: "traits",
  DF_USER_PROFILE: "df:user:profile",
  FS_UUID: "fs:uuid",
  DF_OAUTH_STATE: "df:oauth:state",
});

// Needs refactor to consolidate repeat code.
export function useLocalStorage() {
  function set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  function remove(key) {
    localStorage.removeItem(key);
  }

  function get(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  return {
    set,
    remove,
    get,
  };
}

export function useSessionStorage() {
  function set(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  function remove(key) {
    sessionStorage.removeItem(key);
  }

  function get(key) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  return {
    set,
    remove,
    get,
  };
}
