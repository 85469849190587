<template>
  <component :is="as" :class="[...classes]">
    <slot />
  </component>
</template>

<script setup>
import { isObject, isString, isUndefined } from "@dayforward/common";
import { ref, useCssModule, watchEffect } from "vue";

const props = defineProps({
  as: {
    type: String,
    default: "h1",
    validator: (prop) => ["h1", "h2", "h3", "h4", "h5", "h6"].includes(prop),
  },
  size: {
    type: String,
    validator: (prop) => ["h1", "h2", "h3", "h4", "h5", "h6"].includes(prop),
  },
  margin: {
    type: [Object, String],
  },
  primary: Boolean,
  light: Boolean,
  center: Boolean,
});

const styles = useCssModule();

const classes = ref([]);

const defaultClasses = [styles.title];

classes.value = [...defaultClasses];

if (isUndefined(props.size) || isObject(props.size)) classes.value.push(styles[props.as]);
if (props.size) {
  if (isObject(props.size)) {
    for (const s in props.size) {
      classes.value.push(styles[`${s}:${props.size[s]}`]);
    }
  }
  if (isString(props.size)) classes.value.push(styles[props.size]);
}

// Accepts only top and bottom margins for now
if (props.margin) {
  if (isObject(props.margin)) {
    for (const m in props.margin) {
      if (props.margin[m] === 0) {
        classes.value.push(styles[`m-${m}-none`]);
        continue;
      }
      if (props.margin[m] === "default") {
        classes.value.push(styles[`m-${m}`]);
        continue;
      }
      classes.value.push(styles[`m-${m}-${props.margin[m]}`]);
    }
  }
  if (isString(props.margin)) classes.value.push(styles[`m-${props.margin}`]);
}

if (props.primary) classes.value.push(styles.primary);
if (props.light) classes.value.push(styles.light);
if (props.center) classes.value.push(styles.center);
watchEffect(
  () => {
    if (props.center) classes.value.push(styles.center);
    if (!props.center && classes.value.includes(styles.center)) {
      classes.value = classes.value.filter((s) => s === styles.center);
    }
  },
  { flush: "post" },
);
</script>

<style lang="scss" module>
.title {
  margin-bottom: var(--space-xs);
  color: currentColor;
}

.h1 {
  font-size: var(--base-text-xxl, 1.625rem);
  line-height: 1;
}

.h2,
.h3 {
  line-height: 1.2;
}

.h2 {
  font-size: var(--base-text-xl, 1.125rem);
}

.h4 {
  font-size: var(--base-text-default);
}

.center {
  text-align: center;
}

.primary {
  color: var(--base-color-primary);
}

.light {
  color: var(--oat);
}

.m-top {
  margin-top: var(--space-default);
}

.m-bottom {
  margin-bottom: var(--space-default);
}

.m-none,
.m-top-none {
  margin-top: 0;
}

.m-none,
.m-bottom-none {
  margin-bottom: 0;
}

.m-xs,
.m-top-xs {
  margin-top: var(--space-xs);
}

.m-xs,
.m-bottom-xs {
  margin-bottom: var(--space-xs);
}

.m-sm,
.m-top-sm {
  margin-top: var(--space-sm);
}

.m-sm,
.m-bottom-sm {
  margin-bottom: var(--space-sm);
}

.m-md,
.m-top-md {
  margin-top: var(--space-md);
}

.m-md,
.m-bottom-md {
  margin-bottom: var(--space-md);
}

.m-lg,
.m-top-lg {
  margin-top: var(--space-lg);
}

.m-lg,
.m-bottom-lg {
  margin-bottom: var(--space-lg);
}

.m-xl,
.m-top-xl {
  margin-top: var(--space-xl);
}

.m-xl,
.m-bottom-xl {
  margin-bottom: var(--space-xl);
}

@media (max-width: 563px) {
  .xs\:h1 {
    font-size: var(--base-text-md);
  }

  .xs\:h2 {
    font-size: var(--base-text-md);
  }

  .xs\:h3 {
    font-size: var(--base-text-md);
  }

  .xs\:h4 {
    font-size: var(--base-text-default);
  }

  .xs\:h5 {
    font-size: var(--base-text-md);
  }
}

@media (min-width: 564px) {
  .h2 {
    font-size: var(--base-text-xxl);
  }
}

@media (min-width: 564px) and (max-width: 755px) {
  .sm\:h1 {
    font-size: var(--base-text-md);
  }

  .sm\:h2 {
    font-size: var(--base-text-md);
  }

  .sm\:h3 {
    font-size: var(--base-text-md);
  }

  .sm\:h4 {
    font-size: var(--base-text-md);
  }

  .sm\:h5 {
    font-size: var(--base-text-md);
  }
}

@media (min-width: 756px) {
  .h1 {
    font-size: var(--base-text-mega);
    line-height: 1.06;
  }

  .h4 {
    font-size: var(--base-text-xl);
  }
}

@media (min-width: 756px) and (max-width: 991px) {
  .md\:h1 {
    font-size: var(--base-text-md);
  }

  .md\:h2 {
    font-size: var(--base-text-md);
  }

  .md\:h3 {
    font-size: var(--base-text-md);
  }

  .md\:h4 {
    font-size: var(--base-text-md);
  }

  .md\:h5 {
    font-size: var(--base-text-md);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .lg\:h1 {
    font-size: var(--base-text-md);
  }

  .lg\:h2 {
    font-size: var(--base-text-md);
  }

  .lg\:h3 {
    font-size: var(--base-text-md);
  }

  .lg\:h4 {
    font-size: var(--base-text-md);
  }

  .lg\:h5 {
    font-size: var(--base-text-md);
  }
}

@media (min-width: 1024px) {
  .h3 {
    font-size: var(--base-text-xxl);
  }
}

@media (min-width: 1200px) {
  .h1 {
    font-size: var(--base-text-headline);
  }

  .h2 {
    font-size: var(--base-text-mega);
    line-height: 1.06;
  }

  .xl\:h1 {
    font-size: var(--base-text-headline);
  }

  .xl\:h2 {
    font-size: var(--base-text-mega);
  }

  .xl\:h3 {
    font-size: var(--base-text-md);
  }

  .xl\:h4 {
    font-size: var(--base-text-md);
  }

  .xl\:h5 {
    font-size: var(--base-text-md);
  }
}
</style>
