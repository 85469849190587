import { ref } from "vue";

import { camelize } from "../../../common/src/utils";

const DEFAULT_SCHEMA = {
  firstName: {
    key: "FIRST_NAME",
    text: "First name",
    required: true,
    allowsNone: false,
    active: true,
    defaultCategory: "NAME_AND_ADDRESS",
    valueType: "STRING",
    multipleChoice: false,
    concerns: ["ADDRESS"],
    options: [],
  },
  middleInitial: {
    key: "MIDDLE_INITIAL",
    text: "Middle initial",
    required: false,
    allowsNone: true,
    active: true,
    defaultCategory: "NAME_AND_ADDRESS",
    valueType: "STRING",
    multipleChoice: false,
    concerns: ["ADDRESS"],
    options: [],
  },
  lastName: {
    key: "LAST_NAME",
    text: "Last name",
    required: true,
    allowsNone: false,
    active: true,
    defaultCategory: "NAME_AND_ADDRESS",
    valueType: "STRING",
    multipleChoice: false,
    concerns: ["ADDRESS"],
    options: [],
  },
  emailAddress: {
    key: "EMAIL_ADDRESS",
    text: "Email address",
    required: true,
    allowsNone: false,
    active: true,
    defaultCategory: "NAME_AND_ADDRESS",
    valueType: "STRING",
    multipleChoice: false,
    concerns: ["ADDRESS"],
    options: [],
  },
  emailAddressConfirm: {},
  mobilePhoneNumber: {
    key: "MOBILE_PHONE_NUMBER",
    text: "Mobile number",
    required: true,
    allowsNone: false,
    active: true,
    defaultCategory: "NAME_AND_ADDRESS",
    valueType: "STRING",
    multipleChoice: false,
    concerns: ["ADDRESS"],
    options: [],
  },
  homeState: {
    key: "HOME_STATE",
    text: "State",
    required: true,
    allowsNone: false,
    active: true,
    defaultCategory: "LOCATION",
    defaultAnswers: null,
    valueType: "STATE_CODE",
    multipleChoice: false,
    concerns: ["LOCATION"],
  },
};

const SCHEMA = ref({
  ...DEFAULT_SCHEMA,
});

export function useSchema() {
  function generateSchema(collection) {
    SCHEMA.value = collection.reduce((a, i) => {
      a[camelize(i.key)] = i;
      return a;
    }, SCHEMA.value);
  }

  function questions() {
    return Object.entries(SCHEMA.value).map(([_, question]) => question)
  }

  return {
    generateSchema,
    questions,
    SCHEMA,
  };
}
