import { useFunnel } from "@composables";
import { insert } from "@dayforward/common/src/utils";

import { categories, healthConditions, interviewStepType, routeNames } from "../constants";
import { useHealthConditions } from "./healthConditions";
import { useHealthDiagnosis } from "./healthDiagnosis";

const { condition } = useHealthConditions();
const { diagnosisItems, otherDiagnosisItems } = useHealthDiagnosis();

export const DIAGNOSIS = {
  name: routeNames.INTERVIEW_HEALTH_CONDITIONS_DIAGNOSIS,
  queryBeforeStep: true,
  categories: [categories.HEALTH_FOLLOWUP],
  excludeMarker: true,
  type: interviewStepType.HEALTH_REFLEXIVE,
};

export const DIAGNOSIS_DETAIL = {
  name: routeNames.INTERVIEW_HEALTH_CONDITIONS_DETAILS,
  queryBeforeStep: true,
  categories: [categories.HEALTH_DIAGNOSIS],
  excludeMarker: true,
  routeParams: true,
  type: interviewStepType.HEALTH_REFLEXIVE,
};

export function useGenerator() {
  const { funnel } = useFunnel();

  function resetCollection() {
    let steps = [...funnel.value.steps];
    return steps.some((step) => step.name === routeNames.INTERVIEW_HEALTH_CONDITIONS_DIAGNOSIS); // If steps already has diagnosis step reset steps
  }

  // Remove all health conditions and detail steps
  function filterCollection(collection) {
    return collection.filter(
      (item) =>
        item.name !== routeNames.INTERVIEW_HEALTH_CONDITIONS_DIAGNOSIS &&
        item.name !== routeNames.INTERVIEW_HEALTH_CONDITIONS_DETAILS,
    );
  }

  function generateDiagnosis(value) {
    return {
      ...DIAGNOSIS,
      concerns: [value.value],
      condition: value.value,
      title: value.text,
      index: 0,
    };
  }

  function generateDetails(title, value, i) {
    return {
      ...DIAGNOSIS_DETAIL,
      title,
      routeParams: { diagnosisIndex: i },
      concerns: [value.value, condition.value],
      condition: condition.value,
    };
  }

  function generateDiagnosisStep(steps, values, options) {
    const insertionIndex = steps.findIndex((step) => step.name === routeNames.INTERVIEW_HEALTH_CONDITIONS) + 1;
    const insertionItems = [];
    for (const value of values) {
      // Find step by concern
      const step = steps.find((step) => {
        if (step.concerns) return step.concerns.includes(value);
      });
      // If step doesn't exist add step to insertionItems.
      if (!step) {
        // If value is substance abuse skip creating diagnosis step and create details step
        if (value === healthConditions.SUBSTANCE_ABUSE) {
          const results = generateDetailsStep(steps, values).find(
            (item) => item.condition === healthConditions.SUBSTANCE_ABUSE,
          );
          insertionItems.push(results);
          continue;
        }
        const condition = options.find((option) => option.value === value);
        insertionItems.push(generateDiagnosis(condition));
      }
    }
    // Add new steps to the current steps
    return insert(steps, insertionIndex, ...insertionItems);
  }

  function generateDetailsStep(steps, values) {
    let insertionIndex = steps.findIndex((step) => step.condition === condition.value) + 1;
    const insertionItems = [];
    for (const [i, value] of values.entries()) {
      if (value !== "OTHER") {
        // Find step by concern
        const step = steps.find((step) => {
          if (step.concerns) return step.concerns.includes(value);
        });
        // If step doesn't exist add step to insertionItems.
        if (!step) {
          let item = diagnosisItems.value.find((item) => item.value === value);
          // If item for SUBSTANCE_ABUSE is undefined and conditions.value is null, manually configure details object
          if (!item && value === healthConditions.SUBSTANCE_ABUSE) {
            insertionItems.push({
              ...DIAGNOSIS_DETAIL,
              title: "drug or alcohol",
              routeParams: { diagnosisIndex: i },
              concerns: [healthConditions.SUBSTANCE_ABUSE],
              condition: healthConditions.SUBSTANCE_ABUSE,
              keys: [
                "SUBSTANCE_ABUSE_RELAPSED",
                "SUBSTANCE_ABUSE_TREATMENT",
                "SUBSTANCE_ABUSE_DIAGNOSED_WHEN",
                "SUBSTANCE_ABUSE_TREATMENT_END_DATE",
                "SUBSTANCE_ABUSE_CURRENTLY_TREATED",
              ],
            });
          } else if (item) insertionItems.push(generateDetails(item.text, item, i));
        }
      } else {
        // Find other step associated with condition
        const step = steps.find((step) => {
          if (step.concerns) return step.concerns.includes(value) && step.concerns.includes(condition.value);
        });
        // If other step doesn't exist, add step to insertionItems.
        if (!step) {
          const item = otherDiagnosisItems.value[0];
          insertionItems.push(generateDetails(item.abbreviatedDescription, { value }, i));
        }
      }
    }
    // Add new steps to the current steps
    if (!insertionIndex) insertionIndex = 9;
    return insert(steps, insertionIndex, ...insertionItems);
  }

  function generateConfig(values, options = null) {
    const config = { ...funnel.value };
    let steps = [...config.steps];

    if (options) {
      const reset = resetCollection(); // Returns a boolean
      // Filter out health condition steps if reset
      if (reset) steps = filterCollection(steps);
      const results = generateDiagnosisStep(steps, values, options);
      return { ...config, steps: results };
    }

    if (!options) {
      // Reset steps to remove any current details within this condition
      const _steps = steps.filter((s) => {
        // Return any step that is not a health detail step
        if (!s.condition || s.name !== routeNames.INTERVIEW_HEALTH_CONDITIONS_DETAILS) {
          return s;
          // Else if it's a details step check that it is not assoicated to this condition
        } else if (s.name === routeNames.INTERVIEW_HEALTH_CONDITIONS_DETAILS) {
          if (s.condition !== condition.value) return s;
        }
      });
      const results = generateDetailsStep(_steps, values);
      return { ...config, steps: results };
    }
  }

  return {
    generateConfig,
    filterCollection,
    resetCollection,
  };
}
