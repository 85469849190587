import { capitalize } from "./capitalize";
import { replace } from "./replace";
import { toString } from "./toString";

export function readableList() {
  if (!arguments.length) return "";
  const [collection, pattern, separator] = arguments;
  const defaultSeparator = ", ";

  if (!pattern) return collection.map((item) => capitalize(toString(item).toLowerCase())).join(defaultSeparator);
  return collection
    .map((item) => capitalize(replace(toString(item), pattern, " ").toLowerCase()))
    .join(separator ? separator : defaultSeparator);
}
