export function storeCookie(name, value, options) {
  let domain = "dayforward.com";
  let isSecure = true;
  if (process.env.VUE_APP_RELEASE_STAGE === "local") {
    domain = "localhost";
    isSecure = false;
  }
  let cookieOptions = {
    expires: "Fri, 31 Dec 9999 23:59:59 GMT",
    path: "/",
    samesite: "strict",
  };
  if (options) {
    cookieOptions = { ...options };
  }
  cookieOptions.domain = domain;
  if (isSecure) {
    cookieOptions.secure = "";
  }

  const valString = `${name}=${value}`;
  document.cookie = `${valString};${cookieOptionsToString(cookieOptions)}`;
}

export function readCookie(name, parser) {
  if (!document.cookie) {
    return null;
  }
  const cookieSplit = document.cookie.split(`;`);
  const found = cookieSplit.filter((u) => u.trim().startsWith(name));
  if (!found || found.length === 0) {
    return null;
  }
  const foundSplit = found[0]?.trim().split("=");
  if (foundSplit && foundSplit.length === 2) {
    let val = foundSplit[1];
    if (parser) {
      val = parser(val);
    }
    return val;
  }
  return null;
}

export function deleteCookie(name) {
  if (!document.cookie) {
    return null;
  }
  storeCookie(name, "", {
    expires: "Thu, 01 Jan 1970 00:00:01 GMT",
  });
}

export function cookieOptionsToString(options) {
  const keys = Object.keys(options);
  const joinedVals = [];
  for (let i = 0; i < keys.length; i++) {
    const valKey = keys[i];
    const val = options[valKey];
    if (val === "") {
      joinedVals.push(valKey);
    } else {
      joinedVals.push(`${valKey}=${val}`);
    }
  }
  return joinedVals.join(";");
}
