import { ref } from "vue";

import { isArray, isFalse, isUndefined, union } from "../utils";

const requiredKeys = ref([]);

export function useRequired() {
  function required({ key, required: require }) {
    const requiredOverrideKeys = ["ANNUAL_PRE_TAX_INCOME", "PREVIOUS_ANNUAL_PRE_TAX_INCOME", "SPOUSE_ANNUAL_INCOME"];
    const parialKeys = ["_TREATMENT", "_COMPLICATIONS"];
    const requiredKey = requiredOverrideKeys.includes(key) || parialKeys.some((k) => key.endsWith(k));
    if (require || requiredKey) return true;
    return false;
  }

  function requiredQuestions(questions) {
    return questions.filter(required);
  }

  function validateRequired(questions, answers) {
    const message = "This field is required";
    // Find conditionanly required questions
    const conditionals = questions.filter(({ key }) => {
      // _TREATMENT_END_DATE doesn't pull from the BE as required, so we need to set it as required
      if (key.endsWith("_TREATMENT_END_DATE")) return true;
      return false;
    });
    // Required questions and merge conditional required questions
    const rq = [...requiredQuestions(questions), ...conditionals];
    if (!rq.length) console.log("No keys found");
    // Validate health conditions
    const acknowledgment = rq.find(({ key }) => key === "HEALTH_CONDITIONS_ACKNOWLEDGED");
    if (acknowledgment) {
      const answerKey = acknowledgment.key;
      const answer = answers[answerKey];
      if (!answer || isFalse(answer)) return { invalid: true, results: { [answerKey]: message } };
      else return { invalid: false, results: {} };
    }
    // END
    // Valid all other answers
    const results = rq.reduce((c, { allowsNone, key }) => {
      // SSN is a required question but allows none so answer must be ssn or empty string
      if (key === "SSN" && isUndefined(answers[key])) c[key] = message;
      else if (!answers[key] && key !== "SSN") c[key] = message;
      else if (isArray(answers[key]) && !answers[key].length && !allowsNone) c[key] = message;
      // @TODO Ensure this logic will work across strings or arrays
      else if (allowsNone && !answers[key].length && key !== "SSN") c[key] = message; // Ensure allowsNone at least has empty string
      return c;
    }, {});
    return { invalid: Object.keys(results).length !== 0, results };
  }

  function validateRequiredKeys(keys, answers) {
    // eslint-disable-next-line no-unused-vars
    const $keys = isArray(keys) ? keys : Object.entries(keys).map(([_, value]) => value);
    const errors = $keys.reduce((a, k) => {
      if (!(k in answers)) a[k] = "This field is required";
      if (k in answers && (!answers[k] || !answers[k]?.length)) a[k] = "This field is required";
      return a;
    }, {});
    return { errors, valid: !Object.keys(errors).length };
  }

  function validateRequiredFields(form) {
    const inputs = form.querySelectorAll("input[required]");
    const errors = [...inputs].reduce(
      (a, input) => (!input.value.length ? [...a, { key: input.id, message: "This field is required" }] : a),
      [],
    );
    return { valid: !errors.length, errors };
  }

  function setRequiredKeys(/** Array */ collection) {
    requiredKeys.value = collection;
  }

  function addRequiredKeys(...collections) {
    requiredKeys.value = union(...requiredKeys.value, ...collections);
  }

  function removeRequiredKeys(...collections) {
    const collection = collections.length === 1 ? collections[0] : union(...collections);
    const set = new Set(collection);
    requiredKeys.value = requiredKeys.value.filter((key) => !set.has(key));
  }

  return {
    validateRequired,
    validateRequiredKeys,
    validateRequiredFields,
    required,
    requiredKeys,
    setRequiredKeys,
    addRequiredKeys,
    removeRequiredKeys,
  };
}
