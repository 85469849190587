import { AnalyticsApi } from "@dayforward/api/src/analytics.api.js";
import utm from "@segment/utm-params";

import { readCookie, storeCookie } from "../cookies";

export class SegmentReporter {
  constructor(options) {
    this.settings = options.settings;
    this.apolloClient = options.apolloClient;
  }

  init() {
    if (this.settings.key) {
      let analyticsScript = document.createElement("script");
      analyticsScript.innerHTML = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://prod-segment-cdn.dayforward.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${this.settings.key}";analytics.SNIPPET_VERSION="4.13.2";
			analytics.load("${this.settings.key}");
			}}();`;
      document.head.appendChild(analyticsScript);
    }
    return this;
  }

  fb() {
    const fbp = readCookie("_fbp");
    const fbc = readCookie("_fbc");
    return { fbp: fbp ? fbp : null, fbc: fbc ? fbc : null };
  }

  // Returns only BE accepted identify and track data.
  sanatizeInternalData(data) {
    const { firstName, lastName, email, phone, accountID, interviewIDs, source, lead_source } = data;
    return {
      firstName,
      lastName,
      email,
      phone,
      ...(accountID && { accountID }),
      ...(interviewIDs && { interviewIDs }),
      ...(source && { source }),
      ...(lead_source && { lead_source }),
    };
  }

  page({ path, fullPath }, properties = {}) {
    //console.log(`Segment page: ${path}`);
    const url = `${window.location.origin}${fullPath}`;
    const context = { ...this.getContext() };
    const fb = this.fb();
    this.safeSegment((a) => a.page(path, { path, url, ...properties, ...fb }, { context }));
    const data = {
      path,
      url,
      title: document.title,
      referrer: document.referrer,
      search: location.search,
      experimentID: properties.experiment,
    };
    const api = new AnalyticsApi(this.apolloClient);
    api.page(data);
  }

  identify(accountId, traits) {
    //console.log(`Segment identify: ${accountId}`);
    const source = this.getContext()?.campaign?.source;
    if (source) traits.source = this.getContext()?.campaign?.source;
    const fb = this.fb();

    this.safeSegment((a) => {
      a.identify(accountId, { ...traits, ...fb });
    });
    const api = new AnalyticsApi(this.apolloClient);

    const data = this.sanatizeInternalData(traits);
    api.identify({ ...data });
  }

  trackEvent(event, data = {}) {
    //console.log(`Segment trackEvent: ${event}`);
    const fb = this.fb();
    this.safeSegment((a) => {
      let sUsrAg = navigator.userAgent;
      if (sUsrAg.indexOf("HeadlessChrome") === -1) {
        a.track(
          event,
          { ...data, ...fb },
          {
            ...this.getContext(),
            page: {
              path: window.location.pathname,
              url: window.location.href,
            },
          },
        );
      }
    });
    const api = new AnalyticsApi(this.apolloClient);
    api.track(event, this.sanatizeInternalData(data));
  }

  trackEventOnce(event, data) {
    //console.log(`Segment trackEventOnce: ${event}`);
    this._doOnce(`_df.evo.${event}`, () => {
      this.trackEvent(event, data);
    });
  }

  isReady() {
    return window.analytics;
  }

  safeSegment(callback) {
    if (window.analytics) {
      callback(window.analytics);
    }
  }

  safeTaboola(callback) {
    if (window._tfa) {
      callback(window._tfa);
    }
  }

  getContext() {
    const cookieName = "analytics:utm";
    let context = utm(window.location.search) || {};
    if (context.source) {
      storeCookie(cookieName, JSON.stringify(context));
    } else {
      context = readCookie(cookieName, JSON.parse);
      const fbp = readCookie("_fbp");
      const fbc = readCookie("_fbc");
      if (fbp) context = { ...context, fbp };
      if (fbc) context = { ...context, fbc };
    }
    return {
      campaign: context,
    };
  }

  _doOnce(key, operation) {
    // if no operation then we will not try and run it and no key will be
    // tracked
    if (!operation) {
      return;
    }
    // try to read the run once cookie to determine if an operation has
    // been executed against the provided run key before. if it has, we're
    // done.
    const hasRun = readCookie(key);
    if (hasRun) {
      return;
    }
    // execute the operation and then store a cookie to track that the event
    // has executed
    operation();
    storeCookie(key, true);
  }
}
