export const FORMATS = {
  Email: "email",
  Phone: "phone",
};

export function isString(value) {
  return typeof value === "string" || value instanceof String;
}

/**
 * Remove removes the first character space in a string without modifing the original string
 * @param {string} value
 * @returns {string} // !myString! = myString!
 */
export function sliceLeft(value) {
  return value.slice(1);
}

/**
 * Remove removes the last character space in a string without modifing the original string
 * @param {string} value
 * @returns {string} // !myString! = !myString
 */
export function sliceRight(value) {
  return value.slice(0, -1);
}

/**
 * Remove removes the first and last character space in a string without modifing the original string
 * @param {string} value
 * @returns {string} // !myString! = myString
 */
export function sliceEnds(value) {
  return value.slice(1, -1);
}

export function formatPhone(value) {
  if (!value) return "";
  const sanitized = value.replace(/\D/g, "");

  if (sanitized.length === 10) return sanitized.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  if (sanitized.length === 11 && sanitized[0] === "1")
    return sanitized.slice(1).replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  if (sanitized.startsWith("+")) return sanitized.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  return value;
}

export function formatSSN(value) {
  const digits = value.replace(/\D/g, ""); // Remove any non-digit characters
  if (digits.length !== 9) return "Invalid SSN";
  return digits.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
}

export function formatString(value, type) {
  if (type === FORMATS.Phone) return formatPhone(value);
}

export function pluralize(singular, plural, number) {
  return number > 1 ? plural : singular;
}

export function camelToSentence(/** String */ input) {
  return input
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space before capital letters
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Add a space before groups of capital letters
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/\b\w/g, (match) => match.toUpperCase()) // Capitalize the first letter
    .trim(); // Remove leading and trailing spaces
}

// Expects input to be camelCase, AN_ENUM, or uppercase
export function toSentenceCase(/** String */ input) {
  if (!input) return;
  const hasSpacesOrUnderscores = input.includes(" ") || input.includes("_");

  const normalizedString = hasSpacesOrUnderscores
    ? input
        .replace(/_/g, " ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .toLowerCase()
    : input.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();

  return normalizedString.charAt(0).toUpperCase() + normalizedString.slice(1);
}

export function normalize(value, input, output = " ") {
  if (!value) return value;
  return value.replace(input, output).toLowerCase();
}
