export function isObject(value) {
  return typeof value === "object" && !Array.isArray(value);
}

export function toArray(value) {
  return Object.keys(value).map((key) => value[key]);
}

export function length(obj) {
  return Object.keys(obj).length;
}
