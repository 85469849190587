import { inject, reactive } from "vue";

import { ModalManager } from "../services/modals";

export const ModalManagerKey = "ModalManagerKey";

export function useModals() {
  return inject(ModalManagerKey);
}

export const ModalsPlugin = {
  install: (app) => {
    const modalManager = reactive(new ModalManager());
    app.provide(ModalManagerKey, modalManager);
    app.config.globalProperties.$modals = modalManager;
  },
};
