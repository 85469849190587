export const COMPARISONS = Object.freeze({
  "EQUAL": (a, b) => a === b,
  "NOT_EQUAL": (a, b) => a !== b,
  "GREATER_THAN": (a, b) => a > b,
  "GREATER_THAN_EQUAL": (a, b) => a >= b,
  "LESS_THAN": (a, b) => a < b,
  "LESS_THAN_EQUAL": (a, b) => a <= b,
  "EMPTY": (a) => {
    if (Array.isArray(a)) return !a.length
    return a === ""
  },
  "NOT_EMPTY": (a) => {
    if (Array.isArray(a)) return !!a.length
    return a !== ""
  }
})