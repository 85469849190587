import { computed, ref } from "vue";

import FUNNEL from "../data/funnel.json";
import { isArray, isNull } from "../utils";
import { useRequired } from "./useRequired";

const questions = ref([]);
const question = ref(null);
export function useQuestions() {
  const { required } = useRequired();
  const countAnsweredQuestions = computed(() => {
    const count = questions.value.filter(
      ({ active, required, answers }) => active === true && required === true && !answers.length,
    );
    return count.length;
  });

  function transitionQuestion(answers) {
    // Use questions.value to find the current set of questions with their most recent answers because using question.value.questions is not updated.
    const previousQuestions = questions.value.filter(({ key }) => question.value.keys.includes(key));
    const valid = previousQuestions.every(
      ({ answer: previousAnswer, answers: previousAnswers, key, multipleChoice }) => {
        const $previousAnswer = !multipleChoice ? previousAnswers.at(0) : previousAnswers;
        //
        if (key === "FAMILY_HISTORY") {
          let matching = true;
          if (previousAnswer.familyHistory) {
            // if (previousAnswer.familyHistory.length !== answers[key].length) return false;
            previousAnswer.familyHistory.forEach((_, i) => {
              delete _["__typename"];
              const o2 = answers[key][i];
              const _keys = Object.keys(_);
              _keys.forEach((k) => {
                if (_[k] !== o2[k]) matching = false;
              });
            });
          }
          return matching;
        }
        //
        if (isArray($previousAnswer)) {
          // If answers but length is different
          if ($previousAnswer.length !== answers[key].length) return false;
          // Ensure answers are the same
          let arrayValid = true;
          const _previousAnswer = [...$previousAnswer].sort();
          const _currentAnswer = [...answers[key]].sort();
          // If answers are not the same set to false;
          _previousAnswer.forEach((_, i) => {
            if (_previousAnswer[i] !== _currentAnswer[i]) arrayValid = false;
          });
          return arrayValid;
        }
        const prevAnswer =
          isNaN($previousAnswer) || isNull($previousAnswer) ? $previousAnswer : $previousAnswer.toString();
        const currentAnswer = isNaN(answers[key]) || isNull(answers[key]) ? answers[key] : answers[key].toString();
        return prevAnswer === currentAnswer;
      },
    );
    return valid;
  }

  // eslint-disable-next-line no-unused-vars
  const [_, $keys] = FUNNEL;
  const KEYS = Object.freeze([...$keys].flatMap((s) => s.flat()).reduce((a, key) => ({ ...a, [key]: key }), {}));

  // question is complete
  function complete({ answers }) {
    return !!answers.length;
  }

  // All questions are completed
  function completed(collection) {
    return collection.filter(required).every(complete);
  }

  return {
    countAnsweredQuestions,
    question,
    questions,
    KEYS,
    transitionQuestion,
    complete,
    completed,
  };
}
