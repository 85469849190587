import { readonly, ref } from "vue";

const defaultContext = {
  add: (key, value) => (context.value[key] = value),
  flush: () => (context.value = defaultContext),
};

const context = ref(defaultContext);

export function useContext() {
  return {
    context: readonly(context),
  };
}
