import { createStore } from "vuex";

import { leadModule } from "./modules/lead.module.js";
import { quickPriceModule } from "./modules/quick-price.module.js";

export const store = createStore({
	modules: {
		quickPrice: quickPriceModule,
		lead: leadModule,
	},
});
