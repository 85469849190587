export function isArray(value) {
  return Array.isArray(value);
}

export function findByKey(key, value, collection) {
  if (!collection.length) return null;
  const item = collection.find((item) => item[key] === value);
  return item ? item : null;
}

export function removeDuplicates(key, collection) {
  return [...new Map(collection.map((item) => [item[key], item])).values()];
}
