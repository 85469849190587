import AccountLayout from "../layouts/AccountLayout";

export const healthylivingRoutes = [
  {
    path: "/healthyliving/:slug",
    name: "healthliving-slug",
    meta: {
      requiresAuth: true,
      layout: AccountLayout,
    },
    component: () => import(/* webpackChunkName: "acc-module" */ "../pages/v3/HealthyLivingCallback.vue"),
    beforeEnter: () => {},
  },
];
